import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { saveAs } from 'file-saver';
import {BestService} from '../../services/best/best.service';
import {getTop5NotesByScale, getTuningCount} from '../../services/best/best.service.utils';
import {ScaleFamily, scaleFamily} from '../../gd/src/music-module';
import {allScales} from '../../data/2048 Scales Binary';
import * as mod from '../../gd/src/mod-module';
import * as tuning from '../../gd/src/tuning-module';
import {tuningInfos} from '../../gd/src/tuning-module';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {

  public count = 0;
  public total;
  public tunings = tuning.tuningInfos;
  public tuningIndex = -1;
  public looseMatch = false;

  @ViewChild('tuningSelect') tuningSelect;
  @ViewChild('bestSelect') bestSelect;

  constructor(
      private bestService: BestService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.tuningSelect.valueChange.subscribe(value => {
      this.tuningIndex = value;
    });
    this.bestSelect.valueChange.subscribe(value => {
      this.looseMatch = value;
    });
  }

  buildReverseIndex(): void {
    const count = 0;
    const tuningInfo = tuningInfos.find(info => info.index === this.tuningIndex);
    const table = tuningInfo.tableName;
    this.total = getTuningCount(tuningInfo);
    this.bestService.buildTuningsReverseIndex( this.tuningIndex, table, this.looseMatch,(info) => {
      this.count += info.tunings.length;
    });
  }

  getTop5Notes(): void {
    getTop5NotesByScale(scaleFamily[0]);
  }

  /*
  downloadReverseIndex(): void {
    const blob = new Blob([JSON.stringify(this.reverseIndexData)], {type: 'text/json;charset=utf-8'});
    saveAs(blob, 'bcc.json');
  }
  */

  downloadScaleTable(): void {
    const blob = new Blob([JSON.stringify(scaleFamily)], {type: 'text/json;charset=utf-8'});
    saveAs(blob, 'scales.json');
  }
}
