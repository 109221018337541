import {IdxMatBottomSheetComponent} from './idx-mat-bottom-sheet.component';
import {MatBottomSheetConfig} from '@angular/material/bottom-sheet';

export class BottomsheetService {

    private thisBottomsheetTemplate: string;

    get bottomsheetTemplate(): string {
        return this.thisBottomsheetTemplate;
    }
    set bottomsheetTemplate(nv: string) {
        this.thisBottomsheetTemplate = nv;
    }

    openBottomSheet(template: string, bottomsheet): void {
        const $this = this;
        $this.bottomsheetTemplate = template;
        setTimeout(() => {
            bottomsheet.open(IdxMatBottomSheetComponent, {
                data: {
                    onClose: () => {}
                }
            } as MatBottomSheetConfig);
        }, void 0);
    }
}
