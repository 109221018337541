import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import { FirestoreService } from '../firestore';

export enum ParentType {
  None = 'none',
  Collection = 'collection',
  Chart = 'chart',
  Chord = 'chord',
  Scale = 'scale',
  Instrument = 'instrument'
}

export enum LogType {
  Play,
  Page,
  Dialog,
  Create,
  Auth
}

export enum LogContext {
  TopMenu,
  Login,
  Logout,
  Raw,
  Annotations,
  Charts,
  Instruments,
  Scales,
  Chord,
  Fret,
  Zoom,
  Collection
}

export interface LogItem {
  type: LogType;
  context: LogContext;
  contents: any;
}

@Injectable({
  providedIn: 'root'
})

export class LogService extends FirestoreService {

  constructor(
      public authService: AuthService
  ) {
    super(authService);
    this.collectionName = 'logs';
  }

  write(logType: LogType, logContext: LogContext, info: any): Promise<string | null> {
    const uid = this.authService.getUserInfo().uid;
    if (uid && this.authService.isLoggedIn()) {
      return this.addDocument(uid, {type: logType, context: LogContext.Raw, contents: info});
    }
  }

}
