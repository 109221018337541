export const scales282 = [
 {
   'ID': 661,
   'NoteCount': 5,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Pentatonic',
   'ModeName1': 'Pentatonic Major',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 595,
   'NoteCount': 5,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Pentatonic',
   'ModeName1': 'Pentatonic sus4 b7',
   'ModeName2': 'Pentatonic Major 2 Suspended',
   'ModeName3': 'Egyptian',
   'ModeName4': 'Suspended Pentatonic',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 331,
   'NoteCount': 5,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major Pentatonic',
   'ModeName1': 'Pentatonic Minor b6',
   'ModeName2': 'Minor Blues ',
   'ModeName3': 'Slendro',
   'ModeName4': 'Raga Malkauns',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 597,
   'NoteCount': 5,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major Pentatonic',
   'ModeName1': 'Pentatonic sus4',
   'ModeName2': 'Major Blues',
   'ModeName3': 'Ritusen Japan',
   'ModeName4': 'Scottish Pentaonic',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 339,
   'NoteCount': 5,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major Pentatonic',
   'ModeName1': 'Pentatonic Minor ',
   'ModeName2': 'Minor Pentatonic',
   'ModeName3': 'Yo',
   'ModeName4': 'Blues Pentatonic Minor',
   'ModeName5': 'Hard Japan',
   'ModeName6': ''
 },
 {
   'ID': 178,
   'NoteCount': 5,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hirajoshi',
   'ModeName1': 'Hirajoshi 5 ',
   'ModeName2': 'In',
   'ModeName3': 'Chinese Pentatonic',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 793,
   'NoteCount': 5,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hirajoshi',
   'ModeName1': 'Hirajoshi 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1123,
   'NoteCount': 5,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hirajoshi',
   'ModeName1': 'Hirajoshi 2',
   'ModeName2': 'Iwato Japan',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 198,
   'NoteCount': 5,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hirajoshi',
   'ModeName1': 'Hirajoshi 3 ',
   'ModeName2': 'Hindolita',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1113,
   'NoteCount': 5,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hirajoshi',
   'ModeName1': 'Hirajoshi 4 ',
   'ModeName2': 'Japanese',
   'ModeName3': 'Sakura Pentatonic',
   'ModeName4': 'Soft Descend Japan',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 721,
   'NoteCount': 5,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ionian Pentachord',
   'ModeName1': 'Major Pentachord',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 835,
   'NoteCount': 5,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Ionian Pentachord',
   'ModeName1': 'Raga Bagesri Omit 6',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1291,
   'NoteCount': 5,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ionian Pentachord',
   'ModeName1': 'Ritsu Omit 4',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 534,
   'NoteCount': 5,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ionian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 87,
   'NoteCount': 5,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Ionian Pentachord',
   'ModeName1': '7sus4 add13',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 565,
   'NoteCount': 5,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Shri Kaylan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 211,
   'NoteCount': 5,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Shri Kaylan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1321,
   'NoteCount': 5,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Shri Kaylan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 594,
   'NoteCount': 5,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Shri Kaylan',
   'ModeName1': 'Pentatonic Major 5, Natural Minor ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 327,
   'NoteCount': 5,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Shri Kaylan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 625,
   'NoteCount': 5,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) II Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 451,
   'NoteCount': 5,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) II Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1557,
   'NoteCount': 5,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) II Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1066,
   'NoteCount': 5,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) II Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 84,
   'NoteCount': 5,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) II Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1617,
   'NoteCount': 5,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Dorian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1186,
   'NoteCount': 5,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Dorian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 324,
   'NoteCount': 5,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Dorian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 541,
   'NoteCount': 5,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Dorian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 115,
   'NoteCount': 5,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Dorian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 789,
   'NoteCount': 5,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dorian Pentatonic',
   'ModeName1': 'Japanese (in sen) 5, Kumoi ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1107,
   'NoteCount': 5,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dorian Pentatonic',
   'ModeName1': 'Japanese (in sen) 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 166,
   'NoteCount': 5,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Dorian Pentatonic',
   'ModeName1': 'Japanese (in sen) 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 601,
   'NoteCount': 5,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Dorian Pentatonic',
   'ModeName1': 'Japanese (in sen) 3, Kumoi 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 355,
   'NoteCount': 5,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dorian Pentatonic',
   'ModeName1': 'Japanese (in sen) 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 849,
   'NoteCount': 5,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1347,
   'NoteCount': 5,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 646,
   'NoteCount': 5,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 535,
   'NoteCount': 5,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 91,
   'NoteCount': 5,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 583,
   'NoteCount': 5,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Oriental Tetratonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 283,
   'NoteCount': 5,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Oriental Tetratonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 213,
   'NoteCount': 5,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Oriental Tetratonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1353,
   'NoteCount': 5,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Oriental Tetratonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 658,
   'NoteCount': 5,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Oriental Tetratonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 689,
   'NoteCount': 5,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Lydian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 707,
   'NoteCount': 5,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Lydian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 779,
   'NoteCount': 5,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Lydian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1067,
   'NoteCount': 5,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Lydian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 86,
   'NoteCount': 5,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Lydian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1681,
   'NoteCount': 5,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Pentatonic add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1314,
   'NoteCount': 5,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Major Pentatonic add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 580,
   'NoteCount': 5,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major Pentatonic add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 271,
   'NoteCount': 5,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major Pentatonic add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 117,
   'NoteCount': 5,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major Pentatonic add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 665,
   'NoteCount': 5,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Pentatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 611,
   'NoteCount': 5,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Pentatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 395,
   'NoteCount': 5,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major Pentatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1109,
   'NoteCount': 5,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major Pentatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 170,
   'NoteCount': 5,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Pentatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 913,
   'NoteCount': 5,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Sabach Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1603,
   'NoteCount': 5,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Sabach Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1158,
   'NoteCount': 5,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Sabach Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 268,
   'NoteCount': 5,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Sabach Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 93,
   'NoteCount': 5,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Sabach Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 659,
   'NoteCount': 5,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dominant Pentatonic',
   'ModeName1': 'Dominant Pentatonic 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 587,
   'NoteCount': 5,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dominant Pentatonic',
   'ModeName1': 'Dominant Pentatonic 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 299,
   'NoteCount': 5,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Dominant Pentatonic',
   'ModeName1': 'Dominant Pentatonic 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 341,
   'NoteCount': 5,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Dominant Pentatonic',
   'ModeName1': 'Dominant Pentatonic 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 677,
   'NoteCount': 5,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dominant Pentatonic',
   'ModeName1': 'Dominant Pentatonic 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 562,
   'NoteCount': 5,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vaijayanti',
   'ModeName1': 'Balinese 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 199,
   'NoteCount': 5,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vaijayanti',
   'ModeName1': 'Balinese 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1129,
   'NoteCount': 5,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vaijayanti',
   'ModeName1': 'Balinese 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 210,
   'NoteCount': 5,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vaijayanti',
   'ModeName1': 'Balinese 5',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1305,
   'NoteCount': 5,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vaijayanti',
   'ModeName1': 'Balinese 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1554,
   'NoteCount': 5,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vijayasri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1060,
   'NoteCount': 5,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vijayasri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 72,
   'NoteCount': 5,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vijayasri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 241,
   'NoteCount': 5,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vijayasri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1801,
   'NoteCount': 5,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vijayasri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1233,
   'NoteCount': 5,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Double Harmonic Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 418,
   'NoteCount': 5,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Double Harmonic Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1293,
   'NoteCount': 5,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Double Harmonic Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 538,
   'NoteCount': 5,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Double Harmonic Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 103,
   'NoteCount': 5,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Double Harmonic Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 786,
   'NoteCount': 5,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Minor Maj7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1095,
   'NoteCount': 5,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Minor Maj7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 142,
   'NoteCount': 5,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Minor Maj7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 217,
   'NoteCount': 5,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Minor Maj7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1417,
   'NoteCount': 5,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Minor Maj7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 532,
   'NoteCount': 5,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Megh Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 79,
   'NoteCount': 5,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Megh Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 465,
   'NoteCount': 5,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Megh Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1669,
   'NoteCount': 5,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Megh Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1290,
   'NoteCount': 5,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Megh Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1585,
   'NoteCount': 5,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messaien V Truncated add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1122,
   'NoteCount': 5,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messaien V Truncated add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 196,
   'NoteCount': 5,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messaien V Truncated add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1081,
   'NoteCount': 5,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messaien V Truncated add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 114,
   'NoteCount': 5,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messaien V Truncated add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 569,
   'NoteCount': 5,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 227,
   'NoteCount': 5,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1577,
   'NoteCount': 5,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1106,
   'NoteCount': 5,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen V Omit b2',
   'ModeName1': 'Insen',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 164,
   'NoteCount': 5,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen V Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 817,
   'NoteCount': 5,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1219,
   'NoteCount': 5,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 390,
   'NoteCount': 5,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1069,
   'NoteCount': 5,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 90,
   'NoteCount': 5,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 563,
   'NoteCount': 5,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vaijayanti b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 203,
   'NoteCount': 5,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vaijayanti b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1193,
   'NoteCount': 5,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vaijayanti b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 338,
   'NoteCount': 5,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vaijayanti b7',
   'ModeName1': 'Pentatonic Major 5, Hamonic Minor ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 653,
   'NoteCount': 5,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vaijayanti b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1561,
   'NoteCount': 5,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1074,
   'NoteCount': 5,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 100,
   'NoteCount': 5,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1137,
   'NoteCount': 5,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen V Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 226,
   'NoteCount': 5,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen V Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1809,
   'NoteCount': 5,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hirajoshi (Kostka) add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1570,
   'NoteCount': 5,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Hirajoshi (Kostka) add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1092,
   'NoteCount': 5,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hirajoshi (Kostka) add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 136,
   'NoteCount': 5,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hirajoshi (Kostka) add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 121,
   'NoteCount': 5,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hirajoshi (Kostka) add b9 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 369,
   'NoteCount': 5,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 901,
   'NoteCount': 5,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1555,
   'NoteCount': 5,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1062,
   'NoteCount': 5,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 76,
   'NoteCount': 5,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1361,
   'NoteCount': 5,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 674,
   'NoteCount': 5,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 647,
   'NoteCount': 5,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 539,
   'NoteCount': 5,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 107,
   'NoteCount': 5,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 787,
   'NoteCount': 5,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Min7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1099,
   'NoteCount': 5,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Min7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 150,
   'NoteCount': 5,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Min7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 345,
   'NoteCount': 5,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Min7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 709,
   'NoteCount': 5,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Min7 add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 181,
   'NoteCount': 5,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major 6 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 841,
   'NoteCount': 5,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major 6 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1315,
   'NoteCount': 5,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major 6 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 582,
   'NoteCount': 5,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major 6 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 279,
   'NoteCount': 5,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major 6 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1173,
   'NoteCount': 5,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Scriabin',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 298,
   'NoteCount': 5,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Scriabin',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 333,
   'NoteCount': 5,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Scriabin',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 613,
   'NoteCount': 5,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Scriabin',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 403,
   'NoteCount': 5,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Scriabin',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 457,
   'NoteCount': 5,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1605,
   'NoteCount': 5,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1162,
   'NoteCount': 5,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 276,
   'NoteCount': 5,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 157,
   'NoteCount': 5,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 405,
   'NoteCount': 5,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major 6 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1189,
   'NoteCount': 5,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major 6 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 330,
   'NoteCount': 5,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major 6 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 589,
   'NoteCount': 5,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major 6 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 307,
   'NoteCount': 5,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major 6 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 151,
   'NoteCount': 5,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Valaji',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 361,
   'NoteCount': 5,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Valaji',
   'ModeName1': 'Pelog 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 837,
   'NoteCount': 5,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Valaji',
   'ModeName1': 'Pelog 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1299,
   'NoteCount': 5,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Valaji',
   'ModeName1': 'Pelog 1, Prelog 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 550,
   'NoteCount': 5,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Valaji',
   'ModeName1': 'Pelog 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1377,
   'NoteCount': 5,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Locrian Tetrachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 706,
   'NoteCount': 5,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Locrian Tetrachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 775,
   'NoteCount': 5,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Locrian Tetrachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1051,
   'NoteCount': 5,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Locrian Tetrachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 54,
   'NoteCount': 5,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Locrian Tetrachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 737,
   'NoteCount': 5,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ionian ♭5 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 899,
   'NoteCount': 5,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Ionian ♭5 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1547,
   'NoteCount': 5,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ionian ♭5 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1046,
   'NoteCount': 5,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ionian ♭5 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 44,
   'NoteCount': 5,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Ionian ♭5 Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1729,
   'NoteCount': 5,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1410,
   'NoteCount': 5,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 772,
   'NoteCount': 5,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1039,
   'NoteCount': 5,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 30,
   'NoteCount': 5,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 713,
   'NoteCount': 5,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Mixolydian Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 803,
   'NoteCount': 5,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Mixolydian Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1163,
   'NoteCount': 5,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Mixolydian Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 278,
   'NoteCount': 5,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Mixolydian Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 173,
   'NoteCount': 5,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Mixolydian Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 961,
   'NoteCount': 5,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1795,
   'NoteCount': 5,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1542,
   'NoteCount': 5,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1036,
   'NoteCount': 5,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 24,
   'NoteCount': 5,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Augmented Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1329,
   'NoteCount': 5,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Japanese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 610,
   'NoteCount': 5,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Japanese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 391,
   'NoteCount': 5,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Japanese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1077,
   'NoteCount': 5,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Japanese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 106,
   'NoteCount': 5,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Japanese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1825,
   'NoteCount': 5,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1602,
   'NoteCount': 5,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1156,
   'NoteCount': 5,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 264,
   'NoteCount': 5,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 61,
   'NoteCount': 5,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 295,
   'NoteCount': 5,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Harikauns n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 309,
   'NoteCount': 5,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Harikauns n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 421,
   'NoteCount': 5,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Harikauns n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1317,
   'NoteCount': 5,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Harikauns n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 586,
   'NoteCount': 5,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Harikauns n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1441,
   'NoteCount': 5,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Altered Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 834,
   'NoteCount': 5,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Altered Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1287,
   'NoteCount': 5,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Altered Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 526,
   'NoteCount': 5,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Altered Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 55,
   'NoteCount': 5,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Altered Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1633,
   'NoteCount': 5,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV (2) Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1218,
   'NoteCount': 5,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV (2) Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 388,
   'NoteCount': 5,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV (2) Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1053,
   'NoteCount': 5,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV (2) Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 58,
   'NoteCount': 5,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV (2) Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 617,
   'NoteCount': 5,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Nohkan Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 419,
   'NoteCount': 5,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Nohkan Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1301,
   'NoteCount': 5,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Nohkan Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 554,
   'NoteCount': 5,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Nohkan Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 167,
   'NoteCount': 5,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Nohkan Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 865,
   'NoteCount': 5,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Diminished Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1411,
   'NoteCount': 5,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Diminished Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 774,
   'NoteCount': 5,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Diminished Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1047,
   'NoteCount': 5,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Diminished Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 46,
   'NoteCount': 5,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Diminished Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 285,
   'NoteCount': 5,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Min6 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 229,
   'NoteCount': 5,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Min6 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1609,
   'NoteCount': 5,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Min6 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1170,
   'NoteCount': 5,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Min6 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 292,
   'NoteCount': 5,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Min6 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1857,
   'NoteCount': 5,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1666,
   'NoteCount': 5,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1284,
   'NoteCount': 5,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 520,
   'NoteCount': 5,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 31,
   'NoteCount': 5,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1221,
   'NoteCount': 5,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vasanta Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 394,
   'NoteCount': 5,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vasanta Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1101,
   'NoteCount': 5,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vasanta Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 154,
   'NoteCount': 5,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vasanta Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 409,
   'NoteCount': 5,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vasanta Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 402,
   'NoteCount': 5,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Maj7 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1165,
   'NoteCount': 5,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Maj7 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 282,
   'NoteCount': 5,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Maj7 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 205,
   'NoteCount': 5,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Maj7 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1225,
   'NoteCount': 5,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Maj7 add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 148,
   'NoteCount': 5,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Maj7 add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 313,
   'NoteCount': 5,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Maj7 add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 453,
   'NoteCount': 5,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Maj7 add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1573,
   'NoteCount': 5,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Maj7 add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1098,
   'NoteCount': 5,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Maj7 add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1201,
   'NoteCount': 5,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Balinese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 354,
   'NoteCount': 5,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Balinese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 781,
   'NoteCount': 5,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Balinese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1075,
   'NoteCount': 5,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Balinese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 102,
   'NoteCount': 5,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Balinese Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 185,
   'NoteCount': 5,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Jyoti Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 905,
   'NoteCount': 5,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Jyoti Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1571,
   'NoteCount': 5,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Jyoti Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1094,
   'NoteCount': 5,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Jyoti Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 140,
   'NoteCount': 5,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Jyoti Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 433,
   'NoteCount': 5,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1413,
   'NoteCount': 5,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 778,
   'NoteCount': 5,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1063,
   'NoteCount': 5,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 78,
   'NoteCount': 5,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Major Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 179,
   'NoteCount': 5,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dom7 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 809,
   'NoteCount': 5,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dom7 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1187,
   'NoteCount': 5,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Dom7 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 326,
   'NoteCount': 5,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Dom7 add #11',
   'ModeName1': 'Pentatonic Major 5, Melodic Minor ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 557,
   'NoteCount': 5,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dom7 add #11',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1177,
   'NoteCount': 5,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Balinese n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 306,
   'NoteCount': 5,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Balinese n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 397,
   'NoteCount': 5,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Balinese n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1125,
   'NoteCount': 5,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Balinese n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 202,
   'NoteCount': 5,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Balinese n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1425,
   'NoteCount': 5,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ultraphrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 802,
   'NoteCount': 5,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Ultraphrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1159,
   'NoteCount': 5,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ultraphrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 270,
   'NoteCount': 5,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ultraphrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 109,
   'NoteCount': 5,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Ultraphrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1171,
   'NoteCount': 5,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dom7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 294,
   'NoteCount': 5,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Dom7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 301,
   'NoteCount': 5,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Dom7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 357,
   'NoteCount': 5,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Dom7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 805,
   'NoteCount': 5,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dom7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 155,
   'NoteCount': 5,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dom7 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 425,
   'NoteCount': 5,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dom7 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1349,
   'NoteCount': 5,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Dom7 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 650,
   'NoteCount': 5,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Dom7 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 551,
   'NoteCount': 5,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dom7 add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1249,
   'NoteCount': 5,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Locrian Dominant Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 450,
   'NoteCount': 5,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Locrian Dominant Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1549,
   'NoteCount': 5,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Locrian Dominant Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1050,
   'NoteCount': 5,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Locrian Dominant Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 52,
   'NoteCount': 5,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Locrian Dominant Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1298,
   'NoteCount': 5,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Minor Maj7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 548,
   'NoteCount': 5,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Minor Maj7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 143,
   'NoteCount': 5,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Minor Maj7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 233,
   'NoteCount': 5,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Minor Maj7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1673,
   'NoteCount': 5,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Minor Maj7 add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 481,
   'NoteCount': 5,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Blues Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1797,
   'NoteCount': 5,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Blues Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1546,
   'NoteCount': 5,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major Blues Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1044,
   'NoteCount': 5,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major Blues Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 40,
   'NoteCount': 5,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Blues Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1473,
   'NoteCount': 5,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 898,
   'NoteCount': 5,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1543,
   'NoteCount': 5,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1038,
   'NoteCount': 5,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 28,
   'NoteCount': 5,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1697,
   'NoteCount': 5,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VI Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1346,
   'NoteCount': 5,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VI Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 644,
   'NoteCount': 5,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VI Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 527,
   'NoteCount': 5,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VI Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 59,
   'NoteCount': 5,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VI Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 681,
   'NoteCount': 5,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Kung b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 675,
   'NoteCount': 5,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Kung b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 651,
   'NoteCount': 5,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Kung b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 555,
   'NoteCount': 5,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Kung b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 171,
   'NoteCount': 5,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Kung b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 929,
   'NoteCount': 5,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen III Inv. Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1667,
   'NoteCount': 5,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen III Inv. Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1286,
   'NoteCount': 5,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen III Inv. Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 524,
   'NoteCount': 5,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen III Inv. Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 47,
   'NoteCount': 5,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen III Inv. Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1921,
   'NoteCount': 5,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1794,
   'NoteCount': 5,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1540,
   'NoteCount': 5,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1032,
   'NoteCount': 5,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 16,
   'NoteCount': 5,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Pentachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 683,
   'NoteCount': 6,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Whole Tone',
   'ModeName1': 'Anhemitonic Hexatonic',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1229,
   'NoteCount': 6,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen Inverse III Truncated (2)',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 410,
   'NoteCount': 6,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen Inverse III Truncated (2)',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1593,
   'NoteCount': 6,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V',
   'ModeName1': 'Two-semitone Tritone',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1138,
   'NoteCount': 6,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 228,
   'NoteCount': 6,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 423,
   'NoteCount': 6,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen II Truncated (3)',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1333,
   'NoteCount': 6,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen II Truncated (3)',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 618,
   'NoteCount': 6,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen II Truncated (3)',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1203,
   'NoteCount': 6,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Indupriya India',
   'ModeName1': 'Tritone',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 358,
   'NoteCount': 6,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Indupriya India',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 813,
   'NoteCount': 6,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Indupriya India',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 371,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues',
   'ModeName1': 'Blues Minor',
   'ModeName2': 'Blues Minor',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 917,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues',
   'ModeName1': 'Pentatonic add #9',
   'ModeName2': 'Major Blues',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1619,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues',
   'ModeName1': 'Insen add9',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1190,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues',
   'ModeName1': 'Raga Hamsanandi',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 332,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues',
   'ModeName1': 'Chromatic Phrygian Omit 3',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 605,
   'NoteCount': 6,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Blues',
   'ModeName1': 'Chromatic Dorian Omit b2',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1355,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 662,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 599,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 347,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 725,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 851,
   'NoteCount': 6,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Ritsu',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 693,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 723,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 843,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1323,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 598,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 343,
   'NoteCount': 6,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Yamuna Kalyan\'s',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 596,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 335,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 629,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 467,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1685,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1322,
   'NoteCount': 6,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Megh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 627,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 459,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1621,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1194,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 340,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 669,
   'NoteCount': 6,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Mixolydian Inverse Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 839,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1307,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 566,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 215,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1385,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 722,
   'NoteCount': 6,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Bagesri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 753,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 963,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1803,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1558,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1068,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 88,
   'NoteCount': 6,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Ionian add #4 Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1745,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1442,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 836,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1295,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 542,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 119,
   'NoteCount': 6,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 729,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 867,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1419,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 790,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1111,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 174,
   'NoteCount': 6,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic I:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1589,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1130,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 212,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1337,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 626,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 455,
   'NoteCount': 6,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen V n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1833,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1618,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1188,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 328,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 573,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 243,
   'NoteCount': 6,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) VI Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 821,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1235,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 422,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1325,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 602,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 359,
   'NoteCount': 6,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Viyayanagari',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1649,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1250,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 452,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1565,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1082,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 116,
   'NoteCount': 6,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 633,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 483,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1813,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1578,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1108,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 168,
   'NoteCount': 6,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) II Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 797,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1139,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 230,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1625,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1202,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 356,
   'NoteCount': 6,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hirajoshi (Kostka) add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1379,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 710,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 791,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1115,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 182,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 857,
   'NoteCount': 6,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Honchoshi Plagal',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 690,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 711,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 795,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1131,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 214,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1369,
   'NoteCount': 6,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chinese',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1865,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1682,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1316,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 584,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 287,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 245,
   'NoteCount': 6,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 426,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Aeolian b1',
   'ModeName1': 'Augmented 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1357,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Aeolian b1',
   'ModeName1': 'Augmented 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 666,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Aeolian b1',
   'ModeName1': 'Augmented 3, Augmented 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 615,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Aeolian b1',
   'ModeName1': 'Augmented 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 411,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Aeolian b1',
   'ModeName1': 'Augmented 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1237,
   'NoteCount': 6,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Aeolian b1',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 221,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1481,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 914,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1607,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1166,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 284,
   'NoteCount': 6,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Major Blues Heptatonic Omit 2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1713,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1378,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 708,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 783,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1083,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 118,
   'NoteCount': 6,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Mixolydian Omit b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 697,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 739,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 907,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1579,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1110,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 172,
   'NoteCount': 6,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Pelog Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1071,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 94,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 945,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1731,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1414,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 780,
   'NoteCount': 6,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Kalakanti Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1689,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1330,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 612,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 399,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1141,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 234,
   'NoteCount': 6,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Phrygian Inverse Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1937,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1826,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1604,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1160,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 272,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 125,
   'NoteCount': 6,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) I Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1222,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 396,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1117,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 186,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 921,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1635,
   'NoteCount': 6,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 915,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1611,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1174,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 300,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 349,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 741,
   'NoteCount': 6,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Dominant Pentatonic add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1586,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1124,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 200,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1145,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 242,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1817,
   'NoteCount': 6,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vijayasri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 570,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 231,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1641,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1234,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 420,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1309,
   'NoteCount': 6,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vaijayanti add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 218,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1433,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 818,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1223,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 398,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1133,
   'NoteCount': 6,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Paraju',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1265,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 482,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1805,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1562,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1076,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 104,
   'NoteCount': 6,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV (3) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1929,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1810,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1572,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1096,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 144,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 249,
   'NoteCount': 6,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic PentaMirror add #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1421,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 794,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1127,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 206,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1241,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 434,
   'NoteCount': 6,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic bVI:VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1103,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 158,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 473,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1733,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1418,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 788,
   'NoteCount': 6,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Kalakanti Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1841,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1634,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1220,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 392,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1085,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 122,
   'NoteCount': 6,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 180,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 825,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1251,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 454,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1581,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1114,
   'NoteCount': 6,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vijayavasanta',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1227,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 406,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1197,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 346,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 717,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 819,
   'NoteCount': 6,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vasantabhairavi',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 377,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 965,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1811,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1574,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1100,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 152,
   'NoteCount': 6,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues add b13 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 362,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 845,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1331,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 614,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 407,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1205,
   'NoteCount': 6,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic VII:IVm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 969,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1827,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1606,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1164,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 280,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 189,
   'NoteCount': 6,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Augmented Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 311,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 437,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1445,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 842,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1319,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 590,
   'NoteCount': 6,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic bIII:#Ivo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1181,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 314,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 461,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1637,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1226,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 404,
   'NoteCount': 6,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Scriabin add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 334,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 621,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 435,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1429,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 810,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1191,
   'NoteCount': 6,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic IV:bVIm',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 413,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1253,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 458,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1613,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1178,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 308,
   'NoteCount': 6,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Ultraphrygian Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1393,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 738,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 903,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1563,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1078,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 108,
   'NoteCount': 6,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Phrygian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1889,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1730,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1412,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 776,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1055,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 62,
   'NoteCount': 6,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (2) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 310,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 429,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1381,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 714,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 807,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1179,
   'NoteCount': 6,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic V+:#IVo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1761,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1474,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 900,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1551,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1054,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 60,
   'NoteCount': 6,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Youlan Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1302,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 556,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 175,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 745,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 931,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1675,
   'NoteCount': 6,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Neapolitan Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1737,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1426,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 804,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1167,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 286,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 237,
   'NoteCount': 6,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Mixolydian Augmented add b9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 485,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1829,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1610,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1172,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 296,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 317,
   'NoteCount': 6,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 873,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1443,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 838,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1303,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 558,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 183,
   'NoteCount': 6,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic Io:Iio',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 370,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 909,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1587,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1126,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 204,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1209,
   'NoteCount': 6,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Blues Minor maj7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 782,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1079,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 110,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1457,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 866,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1415,
   'NoteCount': 6,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic VIo:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1477,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 906,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1575,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1102,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 156,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 441,
   'NoteCount': 6,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vasanta add #9 Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1427,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 806,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1175,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 302,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 365,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 869,
   'NoteCount': 6,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic Im:bVIIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1306,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 564,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 207,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1257,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 466,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1677,
   'NoteCount': 6,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Balinese add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 489,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1861,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1674,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1300,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 552,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 159,
   'NoteCount': 6,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Spanish Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1354,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 660,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 591,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 315,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 469,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1701,
   'NoteCount': 6,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Viyogavarali',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1363,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 678,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 663,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 603,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 363,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 853,
   'NoteCount': 6,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Gandharavam',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 977,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1859,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1670,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1292,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 536,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 95,
   'NoteCount': 6,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Houseini Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 654,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 567,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 219,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1449,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 850,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1351,
   'NoteCount': 6,
   'ScaleNumber': 67,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic VIm:bVIo',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 881,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1475,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 902,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1559,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1070,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 92,
   'NoteCount': 6,
   'ScaleNumber': 68,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Harmonic Minor Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 685,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 691,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 715,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 811,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1195,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 342,
   'NoteCount': 6,
   'ScaleNumber': 69,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Kung add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 373,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 933,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1683,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1318,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 588,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 303,
   'NoteCount': 6,
   'ScaleNumber': 70,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Blues Heptatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1925,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1802,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1556,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1064,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 80,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 497,
   'NoteCount': 6,
   'ScaleNumber': 71,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) I Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1505,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 962,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1799,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1550,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1052,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 56,
   'NoteCount': 6,
   'ScaleNumber': 72,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (4) Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 993,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1923,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1798,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1548,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1048,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 48,
   'NoteCount': 6,
   'ScaleNumber': 73,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Enneatonic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1873,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1698,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1348,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 648,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 543,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 123,
   'NoteCount': 6,
   'ScaleNumber': 74,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Diatonic Dorian Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 679,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 667,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 619,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 427,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1365,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 682,
   'NoteCount': 6,
   'ScaleNumber': 75,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Prometheus',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 111,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1489,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 930,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1671,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1294,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 540,
   'NoteCount': 6,
   'ScaleNumber': 76,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Kalakanti Omit b2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1985,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1922,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1796,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1544,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1040,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 32,
   'NoteCount': 6,
   'ScaleNumber': 77,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1705,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1362,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 676,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 655,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 571,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 235,
   'NoteCount': 6,
   'ScaleNumber': 78,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Altered Locrian Omit 6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1953,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1858,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1668,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1288,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 528,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 63,
   'NoteCount': 6,
   'ScaleNumber': 79,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII Hexachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 187,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 937,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1699,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1350,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 652,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 559,
   'NoteCount': 6,
   'ScaleNumber': 80,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Jyoti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 726,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Major',
   'ModeName1': 'Ionian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 855,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major',
   'ModeName1': 'Dorian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1371,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major',
   'ModeName1': 'Phrygian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 694,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major',
   'ModeName1': 'Lydian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 727,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major',
   'ModeName1': 'Mixolydian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 859,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Major',
   'ModeName1': 'Natural Minor',
   'ModeName2': 'Aeolian',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1387,
   'NoteCount': 7,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Major',
   'ModeName1': 'Locrian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 854,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Melodic Minor',
   'ModeName2': 'Half Diminished #2 3',
   'ModeName3': 'Acoustic',
   'ModeName4': 'Hindu 4',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1367,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Dorian b2',
   'ModeName2': 'Half Diminished #2 4',
   'ModeName3': 'Javanese ',
   'ModeName4': 'Hindu 5',
   'ModeName5': 'Jazz Minor Inverse',
   'ModeName6': ''
 },
 {
   'ID': 686,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Lydian #5',
   'ModeName2': 'Half Diminished #2 5',
   'ModeName3': 'Lydian Augmented',
   'ModeName4': 'Hindu 6',
   'ModeName5': 'Hindi #IV & #V',
   'ModeName6': ''
 },
 {
   'ID': 695,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Lydian b7',
   'ModeName2': 'Half Diminished #2 6',
   'ModeName3': 'Overtone Dominant',
   'ModeName4': 'Hindu 7',
   'ModeName5': 'Lydian Dominant',
   'ModeName6': ''
 },
 {
   'ID': 731,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Mixolydian b13',
   'ModeName2': 'Half Diminished #2 7',
   'ModeName3': 'Major Minor',
   'ModeName4': 'Hindu 1',
   'ModeName5': 'Hindu bVI & bVII',
   'ModeName6': ''
 },
 {
   'ID': 875,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Locrian n9',
   'ModeName2': 'Half Diminished #2 1',
   'ModeName3': 'Minor Locrian',
   'ModeName4': 'Hindu 2',
   'ModeName5': 'Hindi 3 flats & bV',
   'ModeName6': ''
 },
 {
   'ID': 1451,
   'NoteCount': 7,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Melodic Minor',
   'ModeName1': 'Altered',
   'ModeName2': 'Half Diminished #2 2',
   'ModeName3': 'Super Locrian ',
   'ModeName4': 'Hindu 3',
   'ModeName5': 'Hindi 5 flats & bIV',
   'ModeName6': 'Diminished Whole Tone'
 },
 {
   'ID': 858,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Harmonic Minor',
   'ModeName2': 'Mohammedan',
   'ModeName3': 'Dorian #4 5 ',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1383,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Locrian n13',
   'ModeName2': 'Locrian Natural Maj 6',
   'ModeName3': 'Dorian #4 6',
   'ModeName4': 'Pseudo Turkish',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 718,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Ionian #5',
   'ModeName2': 'Harmonic Major 2',
   'ModeName3': 'Dorian #4 7',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 823,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Dorian #4',
   'ModeName2': 'Ukrainian Dorian',
   'ModeName3': 'Dorian #4 1 ',
   'ModeName4': 'Romanian',
   'ModeName5': 'Gnossiennes',
   'ModeName6': ''
 },
 {
   'ID': 1243,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Mixolydian b9 b13',
   'ModeName2': 'Phrygian Dominant',
   'ModeName3': 'Dorian #4 2 ',
   'ModeName4': 'Dorico Flamenca',
   'ModeName5': 'Avaha',
   'ModeName6': ''
 },
 {
   'ID': 438,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Lydian #9',
   'ModeName2': 'Lydian #2',
   'ModeName3': 'Dorian #4 3',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1453,
   'NoteCount': 7,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Harmonic Minor',
   'ModeName1': 'Altered Dominant dim7',
   'ModeName2': 'Ultra Locrian',
   'ModeName3': 'Dorian #4 4',
   'ModeName4': 'Locrian bb7',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 499,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 1',
   'ModeName2': 'Spetatonic (Blues) VI',
   'ModeName3': 'Spetatonic (Blues) III',
   'ModeName4': 'Spetatonic (Blues) VII',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1941,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 2',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1834,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 3',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1620,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 4',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1192,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 5',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 336,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 6',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 637,
   'NoteCount': 7,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Major Blues',
   'ModeName1': 'Mixo-Blues 7',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 730,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Harmonic Major, Lydian Diminished 5 ',
   'ModeName2': 'Phrygian b4',
   'ModeName3': 'Lydian Diminished',
   'ModeName4': 'Mixolydian b9',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 871,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1435,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 822,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1239,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 430,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1389,
   'NoteCount': 7,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Harmonic Major',
   'ModeName1': 'Lydian Diminished 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1366,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 7',
   'ModeName2': 'Neapolitan Major',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 684,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 1 ',
   'ModeName2': 'Lydian Augmented #6',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 687,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 2 ',
   'ModeName2': 'Lydian Augmented Dominant',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 699,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 3',
   'ModeName2': 'Mixolydian #11 b13',
   'ModeName3': 'Lydian Minor',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 747,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 4',
   'ModeName2': 'Major Locrian',
   'ModeName3': 'Mixolydian ♭5 b13',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 939,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 5 ',
   'ModeName2': 'Locrian (2) b4',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1707,
   'NoteCount': 7,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Neapolitan',
   'ModeName1': 'Leading Whole Tone 6 ',
   'ModeName2': 'Superlocrian bb3',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1370,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Lydian #6',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 692,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Lydian #6',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 719,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Mixolydian Augmented',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 827,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Aeolian #4',
   'ModeName2': 'Gypsy',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1259,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Oriental (a)',
   'ModeName2': 'Locrian Dominant',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 470,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Ionian #2',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1709,
   'NoteCount': 7,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Neapolitan Minor',
   'ModeName1': 'Altered Locrian',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 826,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 4',
   'ModeName2': 'Algerian',
   'ModeName3': 'Hungarian Gypsy',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1255,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 5',
   'ModeName2': 'Oriental (b)',
   'ModeName3': 'Flamenco',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 462,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1645,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1242,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 436,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1437,
   'NoteCount': 7,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Hungarian Minor',
   'ModeName1': 'Double Harmonic 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1196,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 344,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 701,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 755,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 971,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1835,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1622,
   'NoteCount': 7,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic',
   'ModeName1': 'Enigmatic 7',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 743,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 923,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1643,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1238,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 428,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1373,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 698,
   'NoteCount': 7,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Mixolydian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1332,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 616,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 415,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1269,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 490,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1869,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1690,
   'NoteCount': 7,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 670,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 631,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 475,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1749,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1450,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 852,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1359,
   'NoteCount': 7,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Major Bebop Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1386,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 724,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 847,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1339,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 630,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 471,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1717,
   'NoteCount': 7,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Locrian Natural 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1258,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 468,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1693,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1338,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 628,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 463,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1653,
   'NoteCount': 7,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Persian',
   'ModeName1': 'Persian 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1210,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 372,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 925,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1651,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1254,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 460,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1629,
   'NoteCount': 7,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Composite II',
   'ModeName1': 'Blues Variation 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 216,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1401,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 754,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 967,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1819,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1590,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1132,
   'NoteCount': 7,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Madhuri',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 247,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1897,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1746,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1444,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 840,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1311,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 574,
   'NoteCount': 7,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Heptatonic n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1777,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1506,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 964,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1807,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1566,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1084,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 120,
   'NoteCount': 7,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Youlan Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 798,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1143,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 238,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1753,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1458,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 868,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1423,
   'NoteCount': 7,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Zirafkend Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1597,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1146,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 244,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1849,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1650,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1252,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 456,
   'NoteCount': 7,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen V add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 829,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1267,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 486,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1837,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1626,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1204,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 360,
   'NoteCount': 7,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Neelangi India add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1821,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1594,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1140,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 232,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1657,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1266,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 484,
   'NoteCount': 7,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen IV Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1881,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1714,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1380,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 712,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 799,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1147,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 246,
   'NoteCount': 7,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Harmonic Neapolitan Minor Omit 7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 922,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1639,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1230,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 412,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1245,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 442,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1485,
   'NoteCount': 7,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Symmetrical Augmented add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1721,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1394,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 740,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 911,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1595,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1142,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 236,
   'NoteCount': 7,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen V add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 953,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1763,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1478,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 908,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1583,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1118,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 188,
   'NoteCount': 7,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen III Inverse Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1945,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1842,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1636,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1224,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 400,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1149,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 250,
   'NoteCount': 7,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Septatonic (Blues) I b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1818,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1588,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1128,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 208,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1273,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 498,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1933,
   'NoteCount': 7,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Septatonic (Blues) VI n5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1135,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 222,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1497,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 946,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1735,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1422,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 796,
   'NoteCount': 7,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Kalakanti',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 742,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 919,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1627,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1206,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 364,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 861,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1395,
   'NoteCount': 7,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Ionian ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1734,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1420,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 792,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1119,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 190,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 985,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1891,
   'NoteCount': 7,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Raga Vasanta add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1765,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1482,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 916,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1615,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1182,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 316,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 477,
   'NoteCount': 7,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Lydian add9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 414,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1261,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 474,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1741,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1434,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 820,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1231,
   'NoteCount': 7,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Symmetrical Augmented add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1638,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1228,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 408,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1213,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 378,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 973,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1843,
   'NoteCount': 7,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Altered Lydian Omit #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1614,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1180,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 312,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 445,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1509,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 970,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1831,
   'NoteCount': 7,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Altered Lydian Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1905,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1762,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1476,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 904,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1567,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1086,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 124,
   'NoteCount': 7,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen VII (2) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 493,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1893,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1738,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1428,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 808,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1183,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 318,
   'NoteCount': 7,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Spanish Heptatonic bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1769,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1490,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 932,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1679,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1310,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 572,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 239,
   'NoteCount': 7,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen III Inverse (3) Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1993,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1938,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1828,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1608,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1168,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 288,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 253,
   'NoteCount': 7,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Minor add3 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1341,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 634,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 487,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1845,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1642,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1236,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 424,
   'NoteCount': 7,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Malini #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1866,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1684,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1320,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 592,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 319,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 501,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1957,
   'NoteCount': 7,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Raga Viyogavarali add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1134,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 220,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1465,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 882,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1479,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 910,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1591,
   'NoteCount': 7,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Nohkan b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 443,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1493,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 938,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1703,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1358,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 668,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 623,
   'NoteCount': 7,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Raga Jyoti add #9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1308,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 568,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 223,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1513,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 978,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1863,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1678,
   'NoteCount': 7,
   'ScaleNumber': 44,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Neveseri Omit #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1483,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 918,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1623,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1198,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 348,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 733,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 883,
   'NoteCount': 7,
   'ScaleNumber': 45,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Augmented Dominant b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 379,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 981,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1875,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1702,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1356,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 664,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 607,
   'NoteCount': 7,
   'ScaleNumber': 46,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Blues add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1715,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1382,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 716,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 815,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1211,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 374,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 941,
   'NoteCount': 7,
   'ScaleNumber': 47,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Mixolydian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1582,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1116,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 184,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 889,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1507,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 966,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1815,
   'NoteCount': 7,
   'ScaleNumber': 48,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Altered Lydian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1207,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 366,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 877,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1459,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 870,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1431,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 814,
   'NoteCount': 7,
   'ScaleNumber': 49,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Romanian Major',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1806,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1564,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1080,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 112,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1521,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 994,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1927,
   'NoteCount': 7,
   'ScaleNumber': 50,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Hexatonic bVIo:Vio add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 784,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1087,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 126,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1969,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1890,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1732,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1416,
   'NoteCount': 7,
   'ScaleNumber': 51,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Hexatonic bVIo:Vio add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1706,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1364,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 680,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 671,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 635,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 491,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1877,
   'NoteCount': 7,
   'ScaleNumber': 52,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Septatonic (Blues) VI n3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 505,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1989,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1930,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1812,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1576,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1104,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 160,
   'NoteCount': 7,
   'ScaleNumber': 53,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Major Blues add b13 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1874,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1700,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1352,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 656,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 575,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 251,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1961,
   'NoteCount': 7,
   'ScaleNumber': 54,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Harmonic Neapolitan Minor Omit b6',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 995,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1931,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1814,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1580,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1112,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 176,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 761,
   'NoteCount': 7,
   'ScaleNumber': 55,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Augmented Dominant ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 812,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1199,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 350,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 749,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 947,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1739,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1430,
   'NoteCount': 7,
   'ScaleNumber': 56,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Minor n2',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1009,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1987,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1926,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1804,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1560,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1072,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 96,
   'NoteCount': 7,
   'ScaleNumber': 57,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Enneatonic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 375,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 949,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1747,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1446,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 844,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1327,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 606,
   'NoteCount': 7,
   'ScaleNumber': 58,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Heptatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 431,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1397,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 746,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 935,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1691,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1334,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 620,
   'NoteCount': 7,
   'ScaleNumber': 59,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Hungarian Major #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1326,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 604,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 367,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 885,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1491,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 934,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1687,
   'NoteCount': 7,
   'ScaleNumber': 60,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Minor bb7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 304,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 381,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 997,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1939,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1830,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1612,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1176,
   'NoteCount': 7,
   'ScaleNumber': 61,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Septatonic (Blues) V #4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2001,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1954,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1860,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1672,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1296,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 544,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 127,
   'NoteCount': 7,
   'ScaleNumber': 62,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Houseini add b9 Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2017,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1986,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1924,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1800,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1552,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1056,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 64,
   'NoteCount': 7,
   'ScaleNumber': 63,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Heptachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 439,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1461,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 874,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1447,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 846,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1335,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 622,
   'NoteCount': 7,
   'ScaleNumber': 64,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Hungarian Major',
   'ModeName1': 'Hungarian Major 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 191,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1001,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1955,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1862,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1676,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1304,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 560,
   'NoteCount': 7,
   'ScaleNumber': 65,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Jyoti add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1324,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '((Enigmatic b3))',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 600,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 351,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 757,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 979,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1867,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1686,
   'NoteCount': 7,
   'ScaleNumber': 66,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic b3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1463,
   'NoteCount': 8,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Octatonic',
   'ModeName1': 'Diminished 2, Diminished 4, Diminished 6, Diminished 8 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 878,
   'NoteCount': 8,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Octatonic',
   'ModeName1': 'Diminished 1, Diminished 3, Diminished 5, Diminished 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1723,
   'NoteCount': 8,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1398,
   'NoteCount': 8,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 748,
   'NoteCount': 8,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 943,
   'NoteCount': 8,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VI',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1853,
   'NoteCount': 8,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1658,
   'NoteCount': 8,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1268,
   'NoteCount': 8,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 488,
   'NoteCount': 8,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 728,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': 'Dominant Bebop',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 863,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1403,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 758,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 983,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1883,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1718,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1388,
   'NoteCount': 8,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Bebop Dominant',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 734,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 887,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1499,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 950,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1751,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1454,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 860,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1391,
   'NoteCount': 8,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Major Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1244,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 440,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1469,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 890,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1511,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 974,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1847,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1646,
   'NoteCount': 8,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Bebop Mixo b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1781,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1514,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 980,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1871,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1694,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1340,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 632,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 479,
   'NoteCount': 8,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Youlan Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1725,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1402,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 756,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 975,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1851,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1654,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1260,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 472,
   'NoteCount': 8,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Altered Locrian add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1949,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1850,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1652,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1256,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 464,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1661,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1274,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 500,
   'NoteCount': 8,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Ultraphrygian add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1913,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1778,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1508,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 968,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1823,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1598,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1148,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 248,
   'NoteCount': 8,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen VII (2) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1785,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1522,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 996,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1935,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1822,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1596,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1144,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 240,
   'NoteCount': 8,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen VII (3) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1722,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1396,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 744,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 927,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1659,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1270,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 492,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1885,
   'NoteCount': 8,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Pelog add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1997,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1946,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1844,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1640,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1232,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 416,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1277,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 506,
   'NoteCount': 8,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Bebop Augmented add b9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1977,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1906,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1764,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1480,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 912,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1599,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1150,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 252,
   'NoteCount': 8,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen VII Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1947,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1846,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1644,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1240,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 432,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1405,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 762,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 999,
   'NoteCount': 8,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Sabach add b9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1529,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1010,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1991,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1934,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1820,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1592,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1136,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 224,
   'NoteCount': 8,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Moorish Phrygian add #11 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1214,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 380,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 989,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1907,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1766,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1484,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 920,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1631,
   'NoteCount': 8,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Hypolydian add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1438,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 828,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1263,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 478,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1773,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1498,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 948,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1743,
   'NoteCount': 8,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Ultraphrygian add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1439,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 830,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1271,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 494,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1901,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1754,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1460,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 872,
   'NoteCount': 8,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Ultraphrygian add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1342,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 636,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 495,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1909,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1770,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1492,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 936,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1695,
   'NoteCount': 8,
   'ScaleNumber': 20,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Half Diminished Bebop add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2025,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2002,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1956,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1864,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1680,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1312,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 576,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 255,
   'NoteCount': 8,
   'ScaleNumber': 21,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Omit 5 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1343,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 638,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 503,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1973,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1898,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1748,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1448,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 848,
   'NoteCount': 8,
   'ScaleNumber': 22,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Neveseri add13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 856,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 2 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1375,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 3 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 702,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 4 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 759,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 5 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 987,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 6 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1899,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 7 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1750,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 8 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1452,
   'NoteCount': 8,
   'ScaleNumber': 23,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Raga Mian Ki Malhar',
   'ModeName1': 'Jewish (Magen Abot) 1 ',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1466,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 884,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1487,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 926,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1655,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1262,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 476,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1757,
   'NoteCount': 8,
   'ScaleNumber': 24,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Half Diminished Bebop add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1436,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 824,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1247,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 446,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1517,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 986,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1895,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1742,
   'NoteCount': 8,
   'ScaleNumber': 25,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Moorish Phrygian Omit 4',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 732,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 879,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1467,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 886,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1495,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 942,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1719,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1390,
   'NoteCount': 8,
   'ScaleNumber': 26,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Dominant Bebop b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1879,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1710,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1372,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 696,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 735,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 891,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1515,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 982,
   'NoteCount': 8,
   'ScaleNumber': 27,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Adonai Malakh',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1882,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1716,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1384,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 720,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 831,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1275,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 502,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1965,
   'NoteCount': 8,
   'ScaleNumber': 28,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Harmonic Neapolitan Minor',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1208,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 368,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 893,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1523,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 998,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1943,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1838,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1628,
   'NoteCount': 8,
   'ScaleNumber': 29,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Marva add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2033,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2018,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1988,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1928,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1808,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1568,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1088,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 128,
   'NoteCount': 8,
   'ScaleNumber': 30,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1212,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 376,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 957,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1779,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1510,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 972,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1839,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1630,
   'NoteCount': 8,
   'ScaleNumber': 31,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Enigmatic add5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2005,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1962,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1876,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1704,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1360,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 672,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 639,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 507,
   'NoteCount': 8,
   'ScaleNumber': 32,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Bebop add #9 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1017,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2019,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1990,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1932,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1816,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1584,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1120,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 192,
   'NoteCount': 8,
   'ScaleNumber': 33,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Blues Enneatonic b13 Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1963,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1878,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1708,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1368,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 688,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 703,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 763,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1003,
   'NoteCount': 8,
   'ScaleNumber': 34,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Altered add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1995,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1942,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1836,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1624,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1200,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 352,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 765,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1011,
   'NoteCount': 8,
   'ScaleNumber': 35,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Enigmatic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1013,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2003,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1958,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1868,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1688,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1328,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 608,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 383,
   'NoteCount': 8,
   'ScaleNumber': 36,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Blues Enneatonic Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1736,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1424,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 800,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1151,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 254,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2009,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1970,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1892,
   'NoteCount': 8,
   'ScaleNumber': 37,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Bebop Omit 5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1462,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 876,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1455,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 862,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1399,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 750,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 951,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1755,
   'NoteCount': 8,
   'ScaleNumber': 38,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Shostakovich',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 954,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1767,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1486,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 924,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1647,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1246,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 444,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1501,
   'NoteCount': 8,
   'ScaleNumber': 39,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Hungarian Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2021,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1994,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1940,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1832,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1616,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1184,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 320,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 509,
   'NoteCount': 8,
   'ScaleNumber': 40,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Heptachord add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1525,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1002,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1959,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1870,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1692,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1336,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 624,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 447,
   'NoteCount': 8,
   'ScaleNumber': 41,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen VII (4) Octachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 700,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 751,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 955,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1771,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1494,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 940,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1711,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1374,
   'NoteCount': 8,
   'ScaleNumber': 42,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Pelog add b7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1215,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 382,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1005,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1971,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1894,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1740,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1432,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 816,
   'NoteCount': 8,
   'ScaleNumber': 43,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Romanian Major add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 956,
   'NoteCount': 9,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen III Inverse',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1775,
   'NoteCount': 9,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen III Inverse',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1502,
   'NoteCount': 9,
   'ScaleNumber': 1,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen III Inverse',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 991,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1915,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1782,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1516,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 984,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1887,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1726,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1404,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 760,
   'NoteCount': 9,
   'ScaleNumber': 2,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Houseini',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1981,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1914,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1780,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1512,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 976,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1855,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1662,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1276,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 504,
   'NoteCount': 9,
   'ScaleNumber': 3,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Messiaen IV add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1854,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1660,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1272,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 496,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1917,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1786,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1524,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1000,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1951,
   'NoteCount': 9,
   'ScaleNumber': 4,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 9,
   'ScaleName': 'Messiaen IV add7',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1768,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1488,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 928,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1663,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1278,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 508,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2013,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1978,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1908,
   'NoteCount': 9,
   'ScaleNumber': 5,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Chromatic Bebop ♭5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1496,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 944,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1727,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1406,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 764,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1007,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1979,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1910,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1772,
   'NoteCount': 9,
   'ScaleNumber': 6,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Moorish Phrygian n13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1852,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1656,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1264,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 480,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1789,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1530,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1012,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1999,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1950,
   'NoteCount': 9,
   'ScaleNumber': 7,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Neveseri add9',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1500,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 952,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1759,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1470,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 892,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1519,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 990,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1911,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1774,
   'NoteCount': 9,
   'ScaleNumber': 8,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Moorish Phrygian',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1471,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 894,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1527,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1006,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1975,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1902,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1756,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1464,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 880,
   'NoteCount': 9,
   'ScaleNumber': 9,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Half Diminished add b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1344,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 640,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 511,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2037,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2026,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2004,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1960,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1872,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1696,
   'NoteCount': 9,
   'ScaleNumber': 10,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Neveseri add13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1752,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1456,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 864,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1407,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 766,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1015,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2011,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1974,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1900,
   'NoteCount': 9,
   'ScaleNumber': 11,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Chromatic Bebop',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1783,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1518,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 988,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1903,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1758,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1468,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 888,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1503,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 958,
   'NoteCount': 9,
   'ScaleNumber': 12,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Youlan',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1531,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1014,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2007,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 3,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1966,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1884,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1720,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1392,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 736,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 895,
   'NoteCount': 9,
   'ScaleNumber': 13,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 9,
   'ScaleName': 'Moorish Phrygian add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1724,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1400,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 752,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 959,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1787,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 5,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1526,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1004,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1967,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 8,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1886,
   'NoteCount': 9,
   'ScaleNumber': 14,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Symmetrical Nonatonic',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1021,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2035,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2022,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1996,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1944,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1840,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1632,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1216,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 384,
   'NoteCount': 9,
   'ScaleNumber': 15,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Houseini add #11 Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1019,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2027,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2006,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1964,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1880,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1712,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1376,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 704,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 767,
   'NoteCount': 9,
   'ScaleNumber': 16,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 9,
   'ScaleName': 'Blues Enneatonic b13',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2041,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2034,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2020,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1992,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1936,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1824,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1600,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1152,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 256,
   'NoteCount': 9,
   'ScaleNumber': 17,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Chromatic Nonachord',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1018,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2023,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 2,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1998,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1948,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 4,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1848,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1648,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 6,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1248,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 448,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1533,
   'NoteCount': 9,
   'ScaleNumber': 18,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 9,
   'ScaleName': 'Bebop Harmonic Minor add3',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1744,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 1,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1440,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 2,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 832,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 3,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1279,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 0,
   'm3': 0,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 1,
   'M7': 0,
   'ModeNumber': 4,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 510,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 0,
   'M2': 0,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 1,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 5,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2029,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 1,
   'M6': 1,
   'm7': 0,
   'M7': 0,
   'ModeNumber': 6,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 2010,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 1,
   'd5': 0,
   'P5': 1,
   'm6': 1,
   'M6': 0,
   'm7': 0,
   'M7': 1,
   'ModeNumber': 7,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1972,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 1,
   'P4': 0,
   'd5': 1,
   'P5': 1,
   'm6': 0,
   'M6': 0,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 8,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': 1896,
   'NoteCount': 9,
   'ScaleNumber': 19,
   'P1': 1,
   'm2': 1,
   'M2': 1,
   'm3': 1,
   'M3': 0,
   'P4': 1,
   'd5': 1,
   'P5': 0,
   'm6': 0,
   'M6': 1,
   'm7': 1,
   'M7': 1,
   'ModeNumber': 9,
   'ScaleName': 'Chromatic Bebop #5',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 },
 {
   'ID': null,
   'NoteCount': null,
   'ScaleNumber': null,
   'P1': null,
   'm2': null,
   'M2': null,
   'm3': null,
   'M3': null,
   'P4': null,
   'd5': null,
   'P5': null,
   'm6': null,
   'M6': null,
   'm7': null,
   'M7': null,
   'ModeNumber': null,
   'ScaleName': '',
   'ModeName1': '',
   'ModeName2': '',
   'ModeName3': '',
   'ModeName4': '',
   'ModeName5': '',
   'ModeName6': ''
 }
]
