import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {
  convertNoteNumberToString,
  convertNoteStringByOffset,
  convertNoteStringToNoteNumber,
  modulo,
  removeOctave
} from '../../services/best/best.service.utils';
import {keySignatures, SEMITONES_IN_OCTAVE} from '../../data/charts';
import {scaleFamily} from '../../gd/src/music-module';
import {Router} from '@angular/router';
import {SoundService} from '../../services/sound/sound.service';
import {tuningInfos} from '../../gd/src/tuning-module';
import {BestApiService} from '../../services/best/best.service.api';
import {MatSort} from '@angular/material/sort';
import {CryptoService} from '../../services/crypto/crypto.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UtilsService} from '../../services/utils/utils.service';
import * as music from '../../gd/src/music-module';

@Component({
  selector: 'app-bestkeysandroot',
  templateUrl: './bestkeysandroot.component.html',
  styleUrls: ['./bestkeysandroot.component.scss']
})
export class BestkeysandrootComponent implements OnInit, AfterViewInit, OnChanges {

  public bestKeysAndRoot = new MatTableDataSource<any>();
  public keysAndColumnsToDisplay = ['focusNote', 'mode', 'rootKey', 'scale', 'actionsmenu'];
  public filter;

  @ViewChild(MatSort) sort: MatSort;

  @Input() item;
  @Input() looseMatch;
  @Input() scales;
  @Output() countEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(
      private router: Router,
      private soundService: SoundService,
      private bestApiService: BestApiService,
      private cryptoService: CryptoService,
      private utilsService: UtilsService,
      private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    console.log('ITEM', this.item)
    if (this.scales) {
      this.updateScales(this.item);
    } else {
      this.updateItems();
    }
  }

  ngAfterViewInit(): void {
    if (this.scales) {
      this.updateScales(this.item);
    } else {
      this.updateItems();
    }
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.bestKeysAndRoot.filter = filterValue;
  }

  private updateScales(tuning): void {
    const thisTuning = {strings: tuning.strings.join(''), tuning: removeOctave(tuning.strings).join(''), stringCount: tuning.strings.length } ;
    const uniqueStringNotes = [];
    tuning.strings.forEach(stringNote => {
      const noteWithNoOctave = stringNote.slice(0, -1);
      if (uniqueStringNotes.indexOf(noteWithNoOctave) < 0) {
        uniqueStringNotes.push(noteWithNoOctave);
      }
    });
    const data = [];
    keySignatures.forEach(key => {
      scaleFamily.forEach(family => {
        family.modes.forEach(mode => {
          const keyRoot = family.index === 0 ? key.majorKey : key.minorKey; // TODO: danger, doens't look right
          // tslint:disable-next-line:max-line-length
          const scaleNotes = this.utilsService.getScaleNotes(keyRoot, mode.name, family).map(note => convertNoteNumberToString(note).slice(0, -1));
          let missing = 0;
          uniqueStringNotes.forEach(stringNote => {
            if (!scaleNotes.find(scaleNote => scaleNote === stringNote)) {
              missing++;
            }
          });
          if (!missing) {
            data.push({focusNote: convertNoteStringByOffset(keyRoot, mode.index).slice(0, -1), rootKey: keyRoot, scale: family.name, mode: mode.name, modeNumber: mode.modeNumber});
          }
        });
      });
    });
    this.bestKeysAndRoot.data = data;
    this.bestKeysAndRoot.sort = this.sort;
    this.bestKeysAndRoot.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'mode':
          return item.modeNumber;
        default:
          return item[property];
      }
    };
    this.countEvent.emit(data.length);
  }

  private updateItems(): void {
    this.matchingKeysAndRoot().then(results => {
      this.countEvent.emit(results.length);
      this.bestKeysAndRoot.data = results;
      this.bestKeysAndRoot.sort = this.sort;
      this.bestKeysAndRoot.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'mode':
            return item.modeNumber;
          default:
            return item[property];
        }
      };
      this.bestKeysAndRoot.data.forEach(item => {
        const intervalDifference = modulo(convertNoteStringToNoteNumber(item.focusNote) - convertNoteStringToNoteNumber(item.rootKey), SEMITONES_IN_OCTAVE);
        const mode = scaleFamily.find(family => family.name === item.scale).modes.find(scaleMode => scaleMode.index === intervalDifference);
        if (mode) {
          item.mode = mode.name;
          item.modeNumber = mode.modeNumber;
        } else {
          // console.log('MISSING MODE', intervalDifference, item);
        }
      });
    });
  }

  matchingKeysAndRoot(): Promise<any> {
    let tuning = tuningInfos.find(tuningInfo => tuningInfo.tuning === this.item.tuning);
    if (!tuning) {
      tuning = tuningInfos.find(tuningInfo => tuningInfo.strings.length === this.item.stringCount);
    }
    return this.bestApiService.getTuning(tuning.tableName, this.item.strings, this.looseMatch);
  }

  showCharts(item): void {
    this.countEvent.emit(-1);
    this.router.navigate(['charts', this.cryptoService.encodeJSON({focusNote: item.focusNote, rootKey: item.rootKey, mode: item.mode ? item.mode : item.modeNumber, scale: item.scale, tuning: this.item.tuning, strings: this.item.strings})]);
  }

  playScale(item): void {
    const scale = scaleFamily.find(family => family.name === item.scale);
    const notes = [];
    const firstScaleNote = convertNoteStringToNoteNumber(item.focusNote) + convertNoteStringToNoteNumber('A3') - 1;
    scale.intervals.toArray().forEach((interval, index) => {
      if (interval) {
        notes.push(index);
      }
    });
    while (--item.modeNumber) {
      notes.push(notes.shift() + SEMITONES_IN_OCTAVE);
    }
    notes.push(notes[0] + SEMITONES_IN_OCTAVE);
    this.soundService.play(notes.map(note => convertNoteNumberToString(note + firstScaleNote)), true);
  }

  rowClicked(event, item): void {
    if (this.bestKeysAndRoot.data.indexOf(item) >= 10) {
      event.stopPropagation();
      this.snackBar.open('You need to have a premium subscription to see this');
    }
  }
}
