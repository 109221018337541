import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-contactus-dialog',
  templateUrl: './contactusdialog.component.html',
  styleUrls: ['./contactusdialog.component.scss']
})
export class ContactUsDialogComponent {

  form: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<ContactUsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ContactUsDialogModel,
      private formBuilder: FormBuilder,
      private logService: LogService
  ) {
    this.form = this.formBuilder.group({message: this.formBuilder.control('')});
  }

  onSend(): void {
    this.dialogRef.close({message: this.form.get('message').value });
  }

  onAnnotate(): void {
    this.dialogRef.close({message: this.form.get('message').value, annotate: true });
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}

export class ContactUsDialogModel {
  constructor() {
  }
}
