//
// ===== File constants.service.ts
//
import {MediaMatcher} from '@angular/cdk/layout';
import {Injectable, ChangeDetectorRef, OnDestroy} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IDCConstantsService implements OnDestroy {
    mobileQuery: MediaQueryList;
    private $mobileQueryListener: () => void;

    constructor(public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
        this.mobileQuery = media.matchMedia('(max-width: 767px)');
        this.$mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.$mobileQueryListener);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this.$mobileQueryListener);
    }

}
