import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {AuthService} from '../auth/auth.service';

export interface Firestore {
    createdAt: Timestamp;
    updatedAt: Timestamp;
    viewedAt: Timestamp;
    ownerId: string;
}

export class FirestoreService {

    protected db = firebase.firestore();
    protected collectionName;

    constructor(
        public authService: AuthService,
    ) {
    }

    docFromCollection(docId: string): Promise<any> {
        const docRef = this.db.collection(this.collectionName).doc(docId);
        return docRef.get().then((doc) => {
            const data = doc.data();
            if (data) {
                data.id = doc.id;
            }
            return data;
        }).catch((error) => {
            console.log('Error getting document:', error);
            return false;
        });
    }

    subscribeToDocFromCollection(docId: string, completionFunc: (data) => any): void {
        const docRef = this.db.collection(this.collectionName).doc(docId);
        docRef.onSnapshot((doc) => {
            const data = doc.data();
            data.id = doc.id;
            completionFunc(data);
        });
    }

    getCollectionByUser(userId: string, resultFunc, filterFunc = (collection) => collection): () => void {
        return filterFunc(this.db.collection(this.collectionName).where('_metadata.ownerId', '==', userId))
            .onSnapshot(querySnapshot => resultFunc(querySnapshot.docs.map(doc =>  {
              const data = doc.data(); data.id = doc.id; return data; })));
    }

    addDocument(ownerId: string, data): Promise<string | null> {
        const now = new Date();
        data._metadata = {
            createdAt: now,
            updatedAt: now,
            viewedAt: now,
            ownerId
        };
        return this.db.collection(this.collectionName).add(data).then((doc) => {
            return doc.id;
        }).catch((error) => {
            console.log('Error adding document:', error);
            return null;
        });
    }

    updateDocument(id, data): Promise<boolean> {
        return this.db.collection(this.collectionName).doc(id).update(data).then((doc) => {
            return true;
        }).catch((error) => {
            console.log('Error updating document:', error);
            return false;
        });
    }

    deleteDocument(id): Promise<boolean> {
        return this.db.collection(this.collectionName).doc(id).delete().then((doc) => {
            return true;
        }).catch((error) => {
            console.log('Error deleting document:', error);
            return false;
        });
    }

    deleteAllDocumentsForUser(userId: string, filterFunc = (collection) => collection): Promise<any> {
        return filterFunc(this.db.collection(this.collectionName)).
        where('_metadata.ownerId', '==', userId).get().then(querySnapshot =>
            Promise.all(querySnapshot.docs.map(async doc => await this.db.collection(this.collectionName).doc(doc.id).delete())));
    }

    addOwners(profileService, resultfunc): (array) => void {
        return (array) => {
            array.forEach(item => {
                const id = item._metadata.ownerId;
                if (id) {
                    if (id === this.authService.getUserId()) {
                        item.owner = 'Me';
                    } else {
                        profileService.getUserName(id).then(displayName => {
                            item.owner = displayName;
                        });
                    }
                } else {
                    item.owner = 'Best Chord Charts';
                }
            });
            resultfunc(array);
        };
    }

}
