import {AfterViewInit, Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import * as music from '../../gd/src/music-module';
import {init} from '../../gd/src/gtr-cof';
import * as settings from '../../gd/src/settings-module';
import {MatDialog} from '@angular/material/dialog';
import {CollectionDialogComponent, CollectionDialogModel} from '../dialogs/collectiondialog/collectiondialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CollectionItemsService} from '../../services/firestore/collectionitems/collectionitems.service';
import * as state from '../../gd/src/state-module';
import {State} from '../../gd/src/state-module';
import * as tuning from '../../gd/src/tuning-module';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {PresenceService} from '../../services/firestore/presence/presence.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../dialogs/confirmdialog/confirmdialog.component';
import {UtilsService} from '../../services/utils/utils.service';
import { Title } from '@angular/platform-browser';
import {FormbaseComponent} from '../formbase/formbase.component';
import {Location} from '@angular/common';
import {ParentType} from '../../services/firestore/annotations/annotations.service';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {LeftMenu} from '../../app.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CryptoService} from '../../services/crypto/crypto.service';
import {SoundService} from '../../services/sound/sound.service';

@Component({
  selector: 'app-scales',
  templateUrl: './scales.component.html',
  styleUrls: ['./scales.component.scss']
})
export class ScalesComponent extends FormbaseComponent implements OnInit, AfterViewInit {

  LOCATION = 'scale';

  private firstTime = true;
  public onlineUsers = [];
  public title;
  public itemId;
  private originalState;
  private extraNote;
  public changesPending;
  public tabIndex = 0;
  notes: string[] = [];
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  noteCtrl = new FormControl();

  constructor(
      private formBuilder: FormBuilder,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private route: ActivatedRoute,
      private router: Router,
      private collectionItemsService: CollectionItemsService,
      private authService: AuthService,
      private presenceService: PresenceService,
      private utilsService: UtilsService,
      private cryptoService: CryptoService,
      private soundService: SoundService,
      injector: Injector
      ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('scales', LeftMenu.SCALES);
    this.soundService.soundListener.subscribe(event => {
      if (this.isActive) {
        this.notes = [...this.notes, (event as any).note.slice(0, -1)];
      }
    });
    this.setTitle('',     'Scales', () => this.collectionItemsService.update(this.itemId, {name: this.title}));
    this.route.params.subscribe(params => {
      this.itemId = params.itemId;
      if (this.itemId) {
        this.setPresenceService();
        // tslint:disable-next-line:max-line-length
        state.subscribeToUserChanges((newState) => {
          if (!this.originalState) {
            this.originalState = Object.assign({}, newState);
          }
          this.collectionItemsService.update(this.itemId, {updatedByUserId: this.authService.getUserId(), details: newState});
          this.changesPending = !this.utilsService.deepEqual(newState, this.originalState);
        });
        this.collectionItemsService.subscribeToDocFromCollection(this.itemId, result => {
          this.setTitle(result.name);
          this.titleControl.setValue(this.title);
          if (result.updatedByUserId !== this.authService.getUserId()) {
            state.setNewState(result.details);
          }
        });
      }
      const scalesForm = {
        extranote: this.formBuilder.control({value: false, disabled: false}),
      };
      this.form = this.formBuilder.group(scalesForm);
      this.dataService.send(DataChannelName.APP_SCALE_FORM, {
        form: this.form,
        data: {
          extranote: false,
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.form.get('extranote').valueChanges.subscribe((nv) => {
      this.extraNote = nv;
      console.log('extraNote', nv)
    });
  }

  revertChanges(): void {
    this.changesPending = false;
    state.setNewState(Object.assign({}, this.originalState));
  }

  private setPresenceService(): void {
    this.presenceService.set(this.LOCATION, this.itemId).then(_ => {
      this.presenceService.getAllByLocation(this.LOCATION, this.itemId, users => {
        this.onlineUsers = users;
      });
    });
  }

  addToCollection(): void {
    const dialogRef = this.dialog.open(CollectionDialogComponent, {
      data: new CollectionDialogModel(`Choose a collection to save your scale to.`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const currentState = state.getCurrentState();
        this.setTitle(this.getItemName(currentState));
        const itemInfo = {
          type: ParentType.Scale,
          name: this.title,
          details: {
            ...currentState
          },
          order: -1
        };
        this.collectionItemsService.create(dialogResult, itemInfo).then(item => {
          this.itemId = item;
          this.originalState = null;
          this.setPresenceService();
          this.snackBar.open('Scale added to collection');
        });
      }
    });
  }

  removeFromCollection(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm Delete', `Are you sure you want to remove this item from the collection?`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionItemsService.delete(this.itemId).then(() => {
          this.itemId = null;
          this.setTitle('');
          this.onlineUsers = [];
          this.snackBar.open('Item removed');
        });
      }
    });
  }

  showCharts(): void {
    const items = this.getItemName(state.getCurrentState()).split('-');
    this.router.navigate(['charts', this.cryptoService.encodeJSON({rootKey: items[0], mode: items[1], tuning: items[2]})]);
  }

  getItemName(currentState: State): string {
    function noteIndexToName(naturalIndex: number, index: number): string {
      const noteSpec = music.createNoteSpec(naturalIndex, index);
      return noteSpec.label;
    }
    const modeDescription = music.scaleFamily.find(family => family.index === currentState.scaleFamilyIndex).
      modes.find(mode => mode.index === currentState.modeIndex).name;
    // tslint:disable-next-line:max-line-length
    const tuningDescription = tuning.tunings[currentState.tuningIndex].notes.map(noteIndex => noteIndexToName(noteIndex, noteIndex)).join('');
    const tonicDescription = noteIndexToName(currentState.naturalIndex, currentState.index);
    return `${tonicDescription}-${modeDescription}-${tuningDescription}`;
  }

  onFbNoteTextClick(e): void {
    settings.onFbNoteTextClick(e.target);
  }

  onLeftHandedClick(e): void {
    settings.onLeftHandedClick(e.target);
  }

  onFlipNut(e): void {
    settings.onFlipNut(e.target);
  }

  onSetCToNoon(e): void {
    settings.onSetCToNoon(e.target);
  }

  onTabChanged(event: MatTabChangeEvent): void {
    this.tabIndex = event.index;
    if (this.tabIndex === 1 && this.firstTime) {
      init();
      this.firstTime = false;
    }
  }

  notesChanged(event): void {
    this.notes = [...event];
  }
}
