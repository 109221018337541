import {Injectable} from '@angular/core';
import firebase from 'firebase';
import {SpinnerService} from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})

export class BestApiService {

    constructor(
        private spinnerService: SpinnerService
    ) {}

    postBest(table: string, data: any): Promise<any> {
        this.spinnerService.show();
        return firebase.functions().httpsCallable('best-postBest')({table, data}).then(result => {
            this.spinnerService.hide();
            return result.data;
        });
    }

    getTuning(table: string, tuning: string, looseMatch: boolean): Promise<any> {
        this.spinnerService.show();
        return firebase.functions().httpsCallable('best-getBestSearch')({table, tuning, bestMode: looseMatch ? 2 : 1}).then(result => {
            this.spinnerService.hide();
            return result.data;
        });
    }

    getTuningsByModeAndScale(table: string, rootKey: string, focusNote: string, scale: string, looseMatch: boolean): Promise<any> {
        this.spinnerService.show();
        return firebase.functions().httpsCallable('best-getBestSearch')({table, rootKey, focusNote, scale, bestMode: looseMatch ? 2 : 1}).then(result => {
            this.spinnerService.hide();
            return result.data;
        });
    }

    getTuningsCountByModeAndScale(table: string, rootKey: string, focusNote: string, scale: string, looseMatch: boolean): Promise<any> {
        this.spinnerService.show();
        return firebase.functions().httpsCallable('best-getBestSearchCount')({table, rootKey, focusNote, scale, bestMode: looseMatch ? 2 : 1}).then(result => {
            this.spinnerService.hide();
            return result.data;
        });
    }

}

