import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';
import {UtilsService} from '../../utils/utils.service';

export interface CollectionItem {
  id?: string;
  _metadata?: Firestore;
  name?: string;
  type?: string;
  containerId?: string;
  details: {};
  order: number;
}

@Injectable({
  providedIn: 'root'
})

export class CollectionItemsService extends FirestoreService {

  constructor(
      public authService: AuthService,
      private utilsService: UtilsService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'containerItems';
  }

  private touch(collectionId: string): void {
    this.profileService.setPreviousCollectionId(collectionId);
  }

  get(id: string): Promise<CollectionItem | boolean> {
    return this.docFromCollection(id);
  }

  getAll(collectionId: string, resultFunc): void {
    this.db.collection(this.collectionName).where('containerId', '==', collectionId)
        .onSnapshot(querySnapshot => resultFunc(querySnapshot.docs.map(doc =>  {
          const data = doc.data(); data.id = doc.id; return data; }).sort(this.utilsService.sort('order'))));
  }

  create(collectionId: string, data: CollectionItem): Promise<string | null> {
    this.touch(collectionId);
    data.containerId = collectionId;
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  update(collectionId, data): Promise<boolean> {
    this.touch(collectionId);
    return this.updateDocument(collectionId, data);
  }

  delete(collectionId): Promise<boolean> {
    this.touch(collectionId);
    return this.deleteDocument(collectionId);
  }

  canEdit(collection: CollectionItem): boolean {
    return collection._metadata.ownerId === this.authService.getUserId();
  }

  setItemsOrder(items: Array<CollectionItem>): Promise<any> {
    const promises = [];
    items.forEach((item, index) => {
      promises.push(this.db.collection(this.collectionName).doc(item.id).update({ order: index}));
    });
    return Promise.all(promises);
  }

}
