import * as events from './events-module';

export function init(): void {
    events.leftHandedChange.subscribe(e => {
        const checkbox = document.getElementById('left-handed-checkbox') as HTMLInputElement;
        checkbox.checked = e.isLeftHanded;
    });
    events.flipNutChange.subscribe(e => {
        const checkbox = document.getElementById('flip-nut-checkbox') as HTMLInputElement;
        checkbox.checked = e.isNutFlipped;
    });
    events.setCToNoon.subscribe(e => {
        const checkbox = document.getElementById('set-c-to-noon-checkbox') as HTMLInputElement;
        checkbox.checked = e.isC;
    });
    events.fretboardLabelChange.subscribe(e => {
        const selected = 'fb-note-text' + String(e.labelType);
        const radio = document.getElementById(selected)  as HTMLInputElement;
        radio.checked = true;
    });
}

export function onLeftHandedClick(e: HTMLInputElement): void {
    events.leftHandedChange.publish({ isLeftHanded: e.checked });
}

export function onFlipNut(e: HTMLInputElement): void {
    events.flipNutChange.publish( { isNutFlipped: e.checked });
}

export function onSetCToNoon(e: HTMLInputElement): void {
    events.setCToNoon.publish({ isC: e.checked });
}

export function onFbNoteTextClick(e: HTMLInputElement): void {
    events.fretboardLabelChange.publish({ labelType: parseInt(e.value, 10) });
}
