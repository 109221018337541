import * as mod from './mod-module';

export enum IntervalType {
    Nat,
    Maj,
    Min,
    Aug,
    Dim
}

export let intervalName: {[key: string]: string} = {};
intervalName[IntervalType.Nat] = '';
intervalName[IntervalType.Maj] = 'M';
intervalName[IntervalType.Min] = 'm';
intervalName[IntervalType.Aug] = 'A';
intervalName[IntervalType.Dim] = 'd';

export interface Interval {
    readonly ord: number;
    readonly type: IntervalType;
    readonly colour: number;
}

export let getIntervalName: (x: Interval) => string = interval => intervalName[interval.type] + (interval.ord + 1);

export let inversions = [
    'Root', 'First Inversion', 'Second Inversion', 'Third Inversion', 'Fourth Inversion', 'Fifth Inversion'
];

export let intervals: mod.Mod<Interval[]> = new mod.Mod([
    [{ ord: 0, type: IntervalType.Nat, colour: 0xf44b42 }, { ord: 1, type: IntervalType.Dim, colour: 0xf44b42 }],
    [{ ord: 1, type: IntervalType.Min, colour: 0xf48942 }, { ord: 0, type: IntervalType.Aug, colour: 0xf48942 }],
    [{ ord: 1, type: IntervalType.Maj, colour: 0xf4bf42 }, { ord: 2, type: IntervalType.Dim, colour: 0xf4bf42 }],
    [{ ord: 2, type: IntervalType.Min, colour: 0xf4ee42 }, { ord: 1, type: IntervalType.Aug, colour: 0xf4ee42 }],
    [{ ord: 2, type: IntervalType.Maj, colour: 0x8cf442 }, { ord: 3, type: IntervalType.Dim, colour: 0x8cf442 }],
    [{ ord: 3, type: IntervalType.Nat, colour: 0x42f4bf }, { ord: 2, type: IntervalType.Aug, colour: 0x42f4bf }],
    [{ ord: 4, type: IntervalType.Dim, colour: 0x42d4f4 }, { ord: 3, type: IntervalType.Aug, colour: 0x42d4f4 }],
    [{ ord: 4, type: IntervalType.Nat, colour: 0x429ef4 }, { ord: 5, type: IntervalType.Dim, colour: 0x429ef4 }],
    [{ ord: 5, type: IntervalType.Min, colour: 0xe542f4 }, { ord: 4, type: IntervalType.Aug, colour: 0xe542f4 }],
    [{ ord: 5, type: IntervalType.Maj, colour: 0xf44289 }, { ord: 6, type: IntervalType.Dim, colour: 0xf44289 }],
    [{ ord: 6, type: IntervalType.Min, colour: 0xff8282 }, { ord: 5, type: IntervalType.Aug, colour: 0xff8282 }],
    [{ ord: 6, type: IntervalType.Maj, colour: 0xff82fc }, { ord: 0, type: IntervalType.Dim, colour: 0xff82fc }],
]);

export interface ScaleFamily {
    readonly index: number;
    name: string;
    score?: Array<number>;
    intervals: mod.Mod<boolean>;
    readonly noteCount: number;
    modes: Mode[];
    readonly defaultModeIndex: number;
}

export function notesInScaleFamily(family: ScaleFamily): number {
    return family.intervals.items.filter(x => x).length;
}

const majorModes: Mode[] = [
    {
        name: 'Ionian',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Dorian',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: 'Phrygian',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: 'Lydian',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: 'Mixolydian',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: 'Natural Minor',
        index: 9,
        modeNumber: 6,
        aliases: [
            'Aeolian'
        ]
    },
    {
        name: 'Locrian',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const melodicMinorModes: Mode[] = [
    {
        name: "Melodic Minor",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Half Diminished #2 3",
            "Acoustic",
            "Hindu 4"
        ]
    },
    {
        name: "Dorian b2",
        index: 2,
        modeNumber: 2,
        aliases: [
            "Half Diminished #2 4",
            "Javanese ",
            "Hindu 5",
            "Jazz Minor Inverse"
        ]
    },
    {
        name: "Lydian #5",
        index: 3,
        modeNumber: 3,
        aliases: [
            "Half Diminished #2 5",
            "Lydian Augmented",
            "Hindu 6",
            "Hindi #IV & #V"
        ]
    },
    {
        name: "Lydian b7",
        index: 5,
        modeNumber: 4,
        aliases: [
            "Half Diminished #2 6",
            "Overtone Dominant",
            "Hindu 7",
            "Lydian Dominant"
        ]
    },
    {
        name: "Mixolydian b13",
        index: 7,
        modeNumber: 5,
        aliases: [
            "Half Diminished #2 7",
            "Major Minor",
            "Hindu 1",
            "Hindu bVI & bVII"
        ]
    },
    {
        name: "Locrian n9",
        index: 9,
        modeNumber: 6,
        aliases: [
            "Half Diminished #2 1",
            "Minor Locrian",
            "Hindu 2",
            "Hindi 3 flats & bV"
        ]
    },
    {
        name: "Altered",
        index: 11,
        modeNumber: 7,
        aliases: [
            "Half Diminished #2 2",
            "Super Locrian ",
            "Hindu 3",
            "Hindi 5 flats & bIV",
            "Diminished Whole Tone"
        ]
    }
];

const harmonicMinorModes: Mode[] = [
    {
        name: "Harmonic Minor",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Mohammedan",
            "Dorian #4 5 "
        ]
    },
    {
        name: "Locrian n13",
        index: 2,
        modeNumber: 2,
        aliases: [
            "Locrian Natural Maj 6",
            "Dorian #4 6",
            "Pseudo Turkish"
        ]
    },
    {
        name: "Ionian #5",
        index: 3,
        modeNumber: 3,
        aliases: [
            "Harmonic Major 2",
            "Dorian #4 7"
        ]
    },
    {
        name: "Dorian #4",
        index: 5,
        modeNumber: 4,
        aliases: [
            "Ukrainian Dorian",
            "Dorian #4 1 ",
            "Romanian",
            "Gnossiennes"
        ]
    },
    {
        name: "Mixolydian b9 b13",
        index: 7,
        modeNumber: 5,
        aliases: [
            "Phrygian Dominant",
            "Dorian #4 2 ",
            "Dorico Flamenca",
            "Avaha"
        ]
    },
    {
        name: "Lydian #9",
        index: 8,
        modeNumber: 6,
        aliases: [
            "Lydian #2",
            "Dorian #4 3"
        ]
    },
    {
        name: "Altered Dominant dim7",
        index: 11,
        modeNumber: 7,
        aliases: [
            "Ultra Locrian",
            "Dorian #4 4",
            "Locrian bb7"
        ]
    }
];

/*
const naturalMinorModes: Mode[] = [
    {
        name: '',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 3,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 10,
        modeNumber: 7,
        aliases: []
    }
];
*/

const majorBluesModes: Mode[] = [
    {
        name: "Mixo-Blues 1",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Spetatonic (Blues) VI",
            "Spetatonic (Blues) III",
            "Spetatonic (Blues) VII"
        ]
    },
    {
        name: "Mixo-Blues 2",
        index: 3,
        modeNumber: 2,
        aliases: []
    },
    {
        name: "Mixo-Blues 3",
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: "Mixo-Blues 4",
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: "Mixo-Blues 5",
        index: 6,
        modeNumber: 5,
        aliases: []
    },
    {
        name: "Mixo-Blues 6",
        index: 7,
        modeNumber: 6,
        aliases: []
    },
    {
        name: "Mixo-Blues 7",
        index: 10,
        modeNumber: 7,
        aliases: []
    }
];

const harmonicMajorModes: Mode[] = [
    {
        name: "Harmonic Major, Lydian Diminished 5 ",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Phrygian b4",
            "Lydian Diminished",
            "Mixolydian b9"
        ]
    },
    {
        name: "Lydian Diminished 6 ",
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: "Lydian Diminished 7 ",
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: "Lydian Diminished 1 ",
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: "Lydian Diminished 2 ",
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: "Lydian Diminished 3 ",
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: "Lydian Diminished 4 ",
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const neapolitanModes: Mode[] = [
    {
        name: "Leading Whole Tone 7",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Neapolitan Major"
        ]
    },
    {
        name: "Leading Whole Tone 1 ",
        index: 1,
        modeNumber: 2,
        aliases: [
            "Lydian Augmented #6"
        ]
    },
    {
        name: "Leading Whole Tone 2 ",
        index: 3,
        modeNumber: 3,
        aliases: [
            "Lydian Augmented Dominant"
        ]
    },
    {
        name: "Leading Whole Tone 3",
        index: 5,
        modeNumber: 4,
        aliases: [
            "Mixolydian #11 b13",
            "Lydian Minor"
        ]
    },
    {
        name: "Leading Whole Tone 4",
        index: 7,
        modeNumber: 5,
        aliases: [
            "Major Locrian",
            "Mixolydian \u266d5 b13"
        ]
    },
    {
        name: "Leading Whole Tone 5 ",
        index: 9,
        modeNumber: 6,
        aliases: [
            "Locrian (2) b4"
        ]
    },
    {
        name: "Leading Whole Tone 6 ",
        index: 11,
        modeNumber: 7,
        aliases: [
            "Superlocrian bb3"
        ]
    }
];

const neapolitanMinorModes: Mode[] = [
    {
        name: "Lydian #6",
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: "Lydian #6",
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: "Mixolydian Augmented",
        index: 3,
        modeNumber: 3,
        aliases: []
    },
    {
        name: "Aeolian #4",
        index: 5,
        modeNumber: 4,
        aliases: [
            "Gypsy"
        ]
    },
    {
        name: "Oriental (a)",
        index: 7,
        modeNumber: 5,
        aliases: [
            "Locrian Dominant"
        ]
    },
    {
        name: "Ionian #2",
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: "Altered Locrian",
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const hungarianMinorModes: Mode[] = [
    {
        name: "Double Harmonic 4",
        index: 0,
        modeNumber: 1,
        aliases: [
            "Algerian",
            "Hungarian Gypsy"
        ]
    },
    {
        name: "Double Harmonic 5",
        index: 2,
        modeNumber: 2,
        aliases: [
            "Oriental (b)",
            "Flamenco"
        ]
    },
    {
        name: "Double Harmonic 6 ",
        index: 3,
        modeNumber: 3,
        aliases: []
    },
    {
        name: "Double Harmonic 7 ",
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: "Double Harmonic 1 ",
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: "Double Harmonic 2 ",
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: "Double Harmonic 3 ",
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const enigmaticModes: Mode[] = [
    {
        name: "Enigmatic 1 ",
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: "Enigmatic 2 ",
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: "Enigmatic 3 ",
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: "Enigmatic 4 ",
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: "Enigmatic 5 ",
        index: 8,
        modeNumber: 5,
        aliases: []
    },
    {
        name: "Enigmatic 6 ",
        index: 10,
        modeNumber: 6,
        aliases: []
    },
    {
        name: "Enigmatic 7",
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const enigmaticMinorModes: Mode[] = [
    {
        name: '',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 3,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 10,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const locrianNatural7Modes: Mode[] = [
    {
        name: '',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 3,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 6,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const persianModes: Mode[] = [
    {
        name: 'Persian 1',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Persian 2',
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: 'Persian 3',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: 'Persian 4',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: 'Persian 5',
        index: 6,
        modeNumber: 5,
        aliases: []
    },
    {
        name: 'Persian 6',
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: 'Persian 7',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const compositeIIModes: Mode[] = [
    {
        name: 'Blues Variation 7',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Blues Variation 1',
        index: 1,
        modeNumber: 2,
        aliases: []
    },
    {
        name: 'Blues Variation 2',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: 'Blues Variation 3',
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: 'Blues Variation 4',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: 'Blues Variation 5',
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: 'Blues Variation 6',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const ionianb5Modes: Mode[] = [
    {
        name: '',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 6,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 9,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 11,
        modeNumber: 7,
        aliases: []
    }
];

const hungarianMajorModes: Mode[] = [
    {
        name: 'Hungarian Major 1 ',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Hungarian Major 2 ',
        index: 3,
        modeNumber: 2,
        aliases: []
    },
    {
        name: 'Hungarian Major 3 ',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: 'Hungarian Major 4 ',
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: 'Hungarian Major 5 ',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: 'Hungarian Major 6 ',
        index: 9,
        modeNumber: 6,
        aliases: []
    },
    {
        name: 'Hungarian Major 7 ',
        index: 10,
        modeNumber: 7,
        aliases: []
    }
];

const majorPentatonicModes: Mode[] = [
    {
        name: 'Pentatonic Major',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Pentatonic sus4 b7',
        index: 2,
        modeNumber: 2,
        aliases: [
            'Pentatonic Major 2 Suspended',
            'Egyptian',
            'Suspended Pentatonic'
        ]
    },
    {
        name: 'Pentatonic Minor b6',
        index: 4,
        modeNumber: 3,
        aliases: [
            'Minor Blues ',
            'Slendro',
            'Raga Malkauns'
        ]
    },
    {
        name: 'Pentatonic sus4',
        index: 7,
        modeNumber: 4,
        aliases: [
            'Major Blues',
            'Ritusen Japan',
            'Scottish Pentaonic'
        ]
    },
    {
        name: 'Pentatonic Minor ',
        index: 9,
        modeNumber: 5,
        aliases: [
            'Minor Pentatonic',
            'Yo',
            'Blues Pentatonic Minor',
            'Hard Japan'
        ]
    }
];

const ionianPentaChordModes: Mode[] = [
    {
        name: 'Major Pentachord',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Raga Bagesri Omit 6',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: 'Ritsu Omit 4',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '7sus4 add13',
        index: 7,
        modeNumber: 5,
        aliases: []
    }
];

const wholeToneModes: Mode[] = [
    {
        name: 'Anhemitonic Hexatonic',
        index: 0,
        modeNumber: 1,
        aliases: []
    }
];

const bluesModes: Mode[] = [
    {
        name: 'Blues Minor',
        index: 0,
        modeNumber: 1,
        aliases: [
            'Blues Minor'
        ]
    },
    {
        name: 'Pentatonic add #9',
        index: 3,
        modeNumber: 2,
        aliases: [
            'Major Blues'
        ]
    },
    {
        name: 'Insen add9',
        index: 5,
        modeNumber: 3,
        aliases: []
    },
    {
        name: 'Raga Hamsanandi',
        index: 6,
        modeNumber: 4,
        aliases: []
    },
    {
        name: 'Chromatic Phrygian Omit 3',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: 'Chromatic Dorian Omit b2',
        index: 10,
        modeNumber: 6,
        aliases: []
    }
];

const octatonicModes: Mode[] = [
    {
        name: 'Diminished 2, Diminished 4, Diminished 6, Diminished 8 ',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: 'Diminished 1, Diminished 3, Diminished 5, Diminished 7 ',
        index: 1,
        modeNumber: 2,
        aliases: []
    }
];

const bebopDominantModes: Mode[] = [
    {
        name: 'Dominant Bebop',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 9,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 10,
        modeNumber: 7,
        aliases: []
    },
    {
        name: '',
        index: 11,
        modeNumber: 8,
        aliases: []
    }
];

const majorBebopModes: Mode[] = [
    {
        name: '',
        index: 0,
        modeNumber: 1,
        aliases: []
    },
    {
        name: '',
        index: 2,
        modeNumber: 2,
        aliases: []
    },
    {
        name: '',
        index: 4,
        modeNumber: 3,
        aliases: []
    },
    {
        name: '',
        index: 5,
        modeNumber: 4,
        aliases: []
    },
    {
        name: '',
        index: 7,
        modeNumber: 5,
        aliases: []
    },
    {
        name: '',
        index: 8,
        modeNumber: 6,
        aliases: []
    },
    {
        name: '',
        index: 9,
        modeNumber: 7,
        aliases: []
    },
    {
        name: '',
        index: 11,
        modeNumber: 8,
        aliases: []
    }
];

export let scaleFamily: ScaleFamily[] = [
    // 7 note
    { index: 0, name: 'Major', noteCount: 7, intervals: new mod.Mod([true, false, true, false, true, true, false, true, false, true, false, true]), modes: majorModes, defaultModeIndex: 0 },
    { index: 1, name: 'Melodic Minor', noteCount: 7, intervals: new mod.Mod([true, false, true, true, false, true, false, true, false, true, false, true]), modes: melodicMinorModes, defaultModeIndex: 0 },
    { index: 2, name: 'Harmonic Minor', noteCount: 7, intervals: new mod.Mod([true, false, true, true, false, true, false, true, true, false, false, true]), modes: harmonicMinorModes, defaultModeIndex: 9 },
    { index: 3, name: 'Major Blues', noteCount: 7, intervals: new mod.Mod([true, false, false, true, true, true, true, true, false, false, true, false]), modes: majorBluesModes, defaultModeIndex: 0 },
    { index: 4, name: 'Harmonic Major', noteCount: 7, intervals: new mod.Mod([true, false, true, false, true, true, false, true, true, false, false, true]), modes: harmonicMajorModes, defaultModeIndex: 0 },
    { index: 5, name: 'Neapolitan', noteCount: 7, intervals: new mod.Mod([true, true, false, true, false, true, false, true, false, true, false, true]), modes: neapolitanModes, defaultModeIndex: 0 },
    { index: 6, name: 'Neapolitan Minor', noteCount: 7, intervals: new mod.Mod([true, true, false, true, false, true, false, true, true, false, false, true]), modes: neapolitanMinorModes, defaultModeIndex: 0 },
    { index: 7, name: 'Hungarian Minor', noteCount: 7, intervals: new mod.Mod([true, false, true, true, false, false, true, true, true, false, false, true]), modes: hungarianMinorModes, defaultModeIndex: 0 },
    { index: 8, name: 'Enigmatic', noteCount: 7, intervals: new mod.Mod([true, true, false, false, true, false, true, false, true, false, true, true]), modes: enigmaticModes, defaultModeIndex: 0 },
    { index: 9, name: 'Enigmatic Minor', noteCount: 7, intervals: new mod.Mod([true, true, false, true, false, false, true, true, false, false, true, true]), modes: enigmaticMinorModes, defaultModeIndex: 0 },
    { index: 10, name: 'Locrian Natural 7', noteCount: 7, intervals: new mod.Mod([true, true, false, true, false, true, true, false, true, false, false, true]), modes: locrianNatural7Modes, defaultModeIndex: 0 },
    { index: 11, name: 'Persian', noteCount: 7, intervals: new mod.Mod([true, true, false, false, true, true, true, false, true, false, false, true]), modes: persianModes, defaultModeIndex: 0 },
    { index: 12, name: 'Composite II', noteCount: 7, intervals: new mod.Mod([true, true, false, false, true, false, true, true, true, false, false, true]), modes: compositeIIModes, defaultModeIndex: 0 },
    { index: 13, name: 'Ionian ♭5', noteCount: 7, intervals: new mod.Mod([true, false, true, false, true, true, true, false, false, true, false, true]), modes: ionianb5Modes, defaultModeIndex: 0 },
    { index: 14, name: 'Hungarian Major', noteCount: 7, intervals: new mod.Mod([true, false, false, true, true, false, true, true, false, true, true, false]), modes: hungarianMajorModes, defaultModeIndex: 0 },
    // 5 note
    { index: 15, name: 'Major Pentatonic', noteCount: 5, intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, true, false, false]), modes: majorPentatonicModes, defaultModeIndex: 0 },
    { index: 16, name: 'Ionian Pentachord', noteCount: 5, intervals: new mod.Mod([true, false, true, false, true, true, false, true, false, false, false, false]), modes: ionianPentaChordModes, defaultModeIndex: 0 },
    // 6 note
    { index: 17, name: 'Whole Tone', noteCount: 6, intervals: new mod.Mod([true, false, true, false, true, false, true, false, true, false, true, false]), modes: wholeToneModes, defaultModeIndex: 0 },
    { index: 18, name: 'Blues', noteCount: 6, intervals: new mod.Mod([true, false, false, true, false, true, true, true, false, false, true, false]), modes: bluesModes, defaultModeIndex: 0 },
    // 8 note
    { index: 19, name: 'Octatonic', noteCount: 8, intervals: new mod.Mod([true, true, false, true, true, false, true, true, false, true, true, false]), modes: octatonicModes, defaultModeIndex: 0 },
    { index: 20, name: 'Bebop Dominant', noteCount: 8, intervals: new mod.Mod([true, false, true, false, true, true, false, true, false, true, true, true]), modes: bebopDominantModes, defaultModeIndex: 0 },
    { index: 21, name: 'Major Bebop', noteCount: 8, intervals: new mod.Mod([true, false, true, false, true, true, false, true, true, true, false, true]), modes: majorBebopModes, defaultModeIndex: 0 },
    // { index: 22, name: 'Natural Minor', noteCount: 7, intervals: new mod.Mod([true, false, true, true, false, true, false, true, true, false, true, false]), modes: naturalMinorModes, defaultModeIndex: 0 },
];

// root diatonic scale is major
export let diatonic: mod.Mod<boolean> = new mod.Mod([true, false, true, false, true, true, false, true, false, true, false, true]);
export let indexList: mod.Mod<number> = new mod.Mod([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11]);

export interface NoteSpec {
    readonly natural: Natural;
    readonly index: number;
    readonly offset: number;
    readonly label: string;
}

export function createNoteSpec(naturalIndex: number, index: number): NoteSpec {
    const natural = naturals.filter(x => x.index === naturalIndex)[0];
    if (!naturals.some(x => x.index === naturalIndex)) {
        throw new Error('naturalIndex is not valid: ' + naturalIndex);
    }

    const offset = mod.diff(12, naturalIndex, index);
    if (Math.abs(offset) > 2) {
        throw new Error('offset between naturalIndex: ' + naturalIndex + ', and index: ' + index + ', is invalid: ' + offset);
    }

    const noteLabel = noteLabels.filter(x => x.offset === offset)[0];

    return {
        natural,
        index,
        offset,
        label: natural.label + noteLabel.label
    };
}

export interface Natural {
    id: number; // order of the number in the natural set.
    index: number; // number against the fixed chromatic series
    label: string; // the natural name, e.g: 'A'
}

// fixed index:
// 0  1  2  3  4  5  6  7  8  9  10 11
// A     B  C     D     E  F     G
export let naturals: Natural[] = [
    { id: 0, index: 0, label: 'A' },
    { id: 1, index: 2, label: 'B' },
    { id: 2, index: 3, label: 'C' },
    { id: 3, index: 5, label: 'D' },
    { id: 4, index: 7, label: 'E' },
    { id: 5, index: 8, label: 'F' },
    { id: 6, index: 10, label: 'G' }
];

const naturalList = new mod.Mod(naturals);

interface NoteName {
    readonly name: string;
    readonly index: number;
}

export let noteNames: NoteName[] = [
    { name: 'A', index: 0 },
    { name: 'A♯', index: 1 },
    { name: 'A♭', index: 11 },

    { name: 'B', index: 2 },
    { name: 'B♯', index: 3 },
    { name: 'B♭', index: 1 },

    { name: 'C', index: 3 },
    { name: 'C♯', index: 4 },
    { name: 'C♭', index: 2 },

    { name: 'D', index: 5 },
    { name: 'D♯', index: 6 },
    { name: 'D♭', index: 4 },

    { name: 'E', index: 7 },
    { name: 'E♯', index: 8 },
    { name: 'E♭', index: 6 },

    { name: 'F', index: 8 },
    { name: 'F♯', index: 9 },
    { name: 'F♭', index: 7 },

    { name: 'G', index: 10 },
    { name: 'G♯', index: 11 },
    { name: 'G♭', index: 9 },
];

interface NoteLabel {
    readonly offset: number;
    readonly label: string;
}

const noteLabels: Array<NoteLabel> = [
    { offset: 0, label: '' },
    { offset: 1, label: '♯' },
    { offset: 2, label: 'x' },
    { offset: -1, label: '♭' },
    { offset: -2, label: '♭♭' },
];

export interface Mode {
    readonly name: string;
    readonly index: number;
    readonly modeNumber: number;
    readonly aliases: Array<string>;
}

export interface ScaleSpec {
    noteSpec: NoteSpec;
    mode: Mode;
}

export function createScaleSpec(index: number, naturalIndex: number, modeIndex: number): ScaleSpec {
    return {
        noteSpec: createNoteSpec(naturalIndex, index),
        mode: scaleFamily[0].modes[modeIndex]
    };
}

export enum ChordType { Major, Minor, Diminished, Augmented }

export interface Chord {
    readonly romanNumeral: string;
    readonly type: ChordType;
}

export interface ScaleNote {
    readonly note: NoteSpec;
    readonly interval: Interval;
    readonly intervalName: string;
    readonly isScaleNote: boolean;
    readonly noteNumber: number;
    chord?: Chord;
}

export interface Node {
    readonly scaleNote: ScaleNote;
    readonly chordInterval: Interval;
    readonly intervalName: string;
    readonly isChordRoot: boolean;
    readonly toggle: boolean;
    readonly midiToggle: boolean;
}

export let nullNode: Node = {
    scaleNote: {
        note: {
            natural: {
                id: 0,
                index: 0,
                label: ''
            },
            index: 0,
            offset: 0,
            label: ''
        },
        interval: {
            ord: 0,
            type: 0,
            colour: 0
        },
        intervalName: '',
        isScaleNote: false,
        noteNumber: 0
    },
    chordInterval: {
        ord: 0,
        type: 0,
        colour: 0
    },
    intervalName: '',
    isChordRoot: false,
    toggle: false,
    midiToggle: false
};

export function generateScaleShim(
    noteSpec: NoteSpec,
    mode: Mode,
    chordIndex: number,
    chordIntervals: number[],
    toggledIndexes: number,
    toggledMidiNotes: number,
    family: ScaleFamily): Node[] {

    const scale = generateScale(noteSpec, mode, family);
    mod.zip(scale, generateChordNumbers(scale, mode, family.intervals)).forEach(x => x[0].chord = x[1]);
    if (chordIndex === -1) {
        return generateNodes(scale, mode, scale[0].note.index, chordIntervals, toggledIndexes, toggledMidiNotes, family.intervals);
    }
    else {
        return generateNodes(scale, mode, chordIndex, chordIntervals, toggledIndexes, toggledMidiNotes, family.intervals, true);
    }
}

export function generateScale(noteSpec: NoteSpec, mode: Mode, family: ScaleFamily): ScaleNote[] {
    indexList.setStart(noteSpec.index);
    naturalList.setStart(noteSpec.natural.id);
    family.intervals.setStart(mode.index);
    intervals.setStart(0);
    const workingSet = indexList.merge3(buildScaleCounter(family.intervals.toArray()), intervals.toArray());
    const isSevenNoteScale = notesInScaleFamily(family) === 7;

    return workingSet.map(item => {
        const index = item[0];
        const isScaleNote = item[1][0];

        let noteNumber: number;
        let natural: Natural;
        let activeInterval: Interval;

        if (isScaleNote &&  isSevenNoteScale) {
            noteNumber = item[1][1];
            natural = naturalList.itemAt(noteNumber);
            activeInterval = item[2].filter(x => x.ord === noteNumber)[0];
            if (activeInterval == null) {
                activeInterval = item[2][0];
            }
        }
        else {
            activeInterval = item[2][0];
            noteNumber = isScaleNote ? item[1][1] : activeInterval.ord;
            natural = naturalList.itemAt(activeInterval.ord);
        }

        // console.log('index: ' + index + ', isScaleNote: ' + isScaleNote
        //     + ', noteNumber: ' + noteNumber + ', natural.index: ' + natural.index
        //     + ', natural.label: ' + natural.label
        //     + ', interval: ' + getIntervalName(activeInterval))

        return {
            note: createNoteSpec(natural.index, index),
            interval: activeInterval,
            intervalName: getIntervalName(activeInterval),
            isScaleNote,
            noteNumber
        };
    });
}

// generateNodes creates an 'outer' sliding interval ring that can change with
// chord selections.
export function generateNodes(
    scaleNotes: ScaleNote[],
    mode: Mode,
    chordIndex: number,
    chordIntervals: number[],
    toggledIndexes: number,
    toggledMidiNotes: number,
    family: mod.Mod<boolean>,
    chordSelected: boolean = false
): Node[] {
    const chordIndexOffset = ((chordIndex + 12) - scaleNotes[0].note.index) % 12;
    intervals.setStart(12 - chordIndexOffset);
    family.setStart(mode.index);
    const startAt = scaleNotes.filter(x => x.note.index === chordIndex)[0].noteNumber;
    const workingSet = intervals.merge3(
        scaleNotes,
        buildScaleCounter(family.toArray(), startAt));

    return workingSet.map(item => {
        const chordIntervalCandidates = item[0];
        const scaleNote = item[1];
        const scaleCounter = item[2];
        let activeInterval = scaleNote.isScaleNote
            ? chordIntervalCandidates.filter(x => x.ord === scaleCounter[1])[0]
            : chordIntervalCandidates[0];
        if (activeInterval == null) {
            activeInterval = chordIntervalCandidates[0];
        }

        // if(chordSelected) {
        //     console.log('chordIndex: ' + chordIndex +
        //         ', scaleNote.isScaleNote: ' + scaleNote.isScaleNote +
        //         ', scaleNote.notenumber: ' + scaleNote.noteNumber +
        //         ', scaleCounter: ' + scaleCounter +
        //         ', activeInterval: ' + getIntervalName(activeInterval) +
        //         ', toggle: ' + calculateToggle(activeInterval, scaleNote, chordSelected, toggledIndexes, chordIntervals));
        // }

        return {
            scaleNote,
            chordInterval: activeInterval,
            intervalName: getIntervalName(activeInterval),
            isChordRoot: chordSelected && activeInterval.ord === 0 && activeInterval.type === 0,
            toggle: calculateToggle(activeInterval, scaleNote, chordSelected, toggledIndexes, chordIntervals),
            // tslint:disable-next-line:no-bitwise
            midiToggle: (toggledMidiNotes & (2 ** scaleNote.note.index)) !== 0
        };
    });
}

function buildScaleCounter(diatonics: boolean[], startAt: number = 0): [boolean, number][] {
    const noteCount = diatonics.filter(x => x).length;
    let i = (noteCount - startAt) % noteCount;
    return diatonics.map(isNote => {
        if (isNote) {
            const value = [true, i] as [boolean, number];
            i = (i + 1) % noteCount;
            return value;
        }
        return [false, 0] as [boolean, number];
    });
}

const romanNumeral: Array<string> = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii'];

export function generateChordNumbers(scaleNotes: ScaleNote[], mode: Mode, family: mod.Mod<boolean>): Chord[] {
    return scaleNotes.map((scaleNote, i) => {
        if (scaleNote.isScaleNote) {
            let roman = romanNumeral[scaleNote.noteNumber];
            const nodes = generateNodes(scaleNotes, mode, scaleNote.note.index, [], 0, 0, family);
            let diminished = '';
            let type: ChordType = ChordType.Minor;
            // does it have a diminished 5th?
            if (nodes.some(x => x.scaleNote.isScaleNote && x.chordInterval.ord === 4 && x.chordInterval.type === IntervalType.Dim)) {
                diminished = '°';
                type = ChordType.Diminished;
            }
            // does it have an augmented 5th?
            else if (nodes.some(x => x.scaleNote.isScaleNote && x.chordInterval.ord === 4 && x.chordInterval.type === IntervalType.Aug)) {
                diminished = '+';
                type = ChordType.Augmented;
            }
            // does it have a major 3rd?
            else if (nodes.some(x => x.scaleNote.isScaleNote && x.chordInterval.ord === 2 && x.chordInterval.type === IntervalType.Maj)) {
                roman = roman.toLocaleUpperCase();
                type = ChordType.Major;
            }
            return {
                romanNumeral: roman + diminished,
                type
            };
        }

        return {
            romanNumeral: '',
            type: ChordType.Major
        };
    });
}

export function calculateToggle(
    activeInterval: Interval,
    scaleNote: ScaleNote,
    chordSelected: boolean,
    toggledIndexes: number,
    chordIntervals: number[]
): boolean {
    if (toggledIndexes === 0) {
        return chordSelected && scaleNote.isScaleNote && chordIntervals.some(x => activeInterval.ord === x);
    }
    // tslint:disable-next-line:no-bitwise
    return (toggledIndexes & (2 ** scaleNote.note.index)) !== 0;
}

export function fifths(): Array<number> {
    const indexes: Array<number> = [];
    let current = 0;
    for (let i = 0; i < 12; i++) {
        indexes.push(current);
        current = (current + 7) % 12;
    }
    return indexes;
}

export function chromatic(): Array<number> {
    const indexes: Array<number> = [];
    for (let i = 0; i < 12; i++) {
        indexes.push(i);
    }
    return indexes;
}
