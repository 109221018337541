import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';
import {UtilsService} from '../../utils/utils.service';

export interface History {
  id?: string;
  _metadata?: Firestore;
  name: string;
  url: string;
  isBookmark: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class HistoryService extends FirestoreService {

  constructor(
      public authService: AuthService,
      private utilsService: UtilsService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'history';
  }

  get(id: string): Promise<History | boolean> {
    return this.docFromCollection(id);
  }

  getAll(bookmarks: boolean, resultFunc): () => void {
    return this.getCollectionByUser(this.authService.getUserInfo().uid, (result) => resultFunc(result.sort(this.utilsService.sort('_metadata.createdAt', true))), (collection) => collection.where('isBookmark', '==', bookmarks));
  }

  create(data: History): Promise<string | null> {
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  update(historyId, data): Promise<boolean> {
    return this.updateDocument(historyId, data);
  }

  delete(historyState: string): Promise<boolean> {
    return this.deleteAllDocumentsForUser(this.authService.getUserInfo().uid, (collection) => collection.where('isBookmark', '==', true).where('name', '==', historyState));
  }

  deleteAll(bookmarks: boolean): Promise<boolean> {
    return this.deleteAllDocumentsForUser(this.authService.getUserInfo().uid, (collection) => collection.where('isBookmark', '==', bookmarks));
  }

  isBookmarked(historyState: string, resultFunc): () => void {
    return this.getCollectionByUser(this.authService.getUserInfo().uid, (result) => resultFunc(result.length > 0),
        (collection) => collection.where('isBookmark', '==', true).where('name', '==', historyState));
  }

}
