import {Component, ElementRef, Input, OnChanges, OnInit, Pipe, PipeTransform, QueryList, ViewChild} from '@angular/core';
import {flatNotes, flatOctave, intervalNames, SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import {
  convertNoteNumberToString,
  convertNoteStringToNoteNumber,
  modulo, normalizeNoteString,
  tuningStringToArray
} from '../../services/best/best.service.utils';
import {UtilsService} from '../../services/utils/utils.service';
import {SoundService} from '../../services/sound/sound.service';
import {LogContext, LogService, LogType} from '../../services/firestore/log/log.service';
import {noteNames, ScaleFamily} from '../../gd/src/music-module';
import {bestCombinations, tuningInfos} from '../../gd/src/tuning-module';
import {AnnotationsDialogComponent} from '../dialogs/annotationsdialog/annotationsdialog.component';
import {ParentType} from '../../services/firestore/annotations/annotations.service';
import {CollectionDialogComponent, CollectionDialogModel} from '../dialogs/collectiondialog/collectiondialog.component';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DownloadService} from '../../services/download/download.service';
import * as music from '../../gd/src/music-module';
import {MatDialog} from '@angular/material/dialog';
import {CollectionItemsService} from '../../services/firestore/collectionitems/collectionitems.service';
import {FullscreenService} from '../../services/fullscreen/fullscreen.service';
import {instruments} from '../../services/sound/sound.service.instruments';
import {InstrumentName} from 'soundfont-player';
import {CryptoService} from '../../services/crypto/crypto.service';

@Component({
  selector: 'app-guitarchart',
  templateUrl: './guitarchart.component.html',
  styleUrls: ['./guitarchart.component.scss']
})
export class GuitarchartComponent implements OnInit, OnChanges {

  @Input() title;
  @Input() renderingMode = {
    leftHanded: false,
    format: 'sharps',
    notedisplay: 'scale',
    frets: 24,
    instrument: 'G'
  };
  @Input() capo = 0;
  @Input() tuning;
  @Input() chart;

  @Input() currentDots;
  @Input() scaleIndex;
  @Input() root;  // root note of scale, used by chord displays substring(0, 2)
  @Input() key;
  @Input() mode;
  @Input() type; // of scale [ 0]
  @Input() playInstrument = 3;  // nylon acoustic guitar

  @ViewChild('guitarchart') guitarChart: ElementRef;

  @Input() strings;
  public frets = new Array(this.renderingMode.frets);

  public showLogo = false;

  constructor(
      public utilsService: UtilsService,
      private soundService: SoundService,
      private logService: LogService,
      private profileService: ProfileService,
      private snackBar: MatSnackBar,
      private downloadService: DownloadService,
      private dialog: MatDialog,
      private collectionItemsService: CollectionItemsService,
      private fullscreenService: FullscreenService,
      private cryptoService: CryptoService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.frets = new Array(this.renderingMode.frets)
  }

  play(chart, guitarSound = false): void {
    let notesToPlay;
    if (chart.scale) {
      // tslint:disable-next-line:max-line-length
      const scaleNoteIndexes = this.utilsService.getScaleNotes(chart.root.substring(0, 2), chart.type, this.getCurrentScale()).map(note => note - 1);
      let octaveJump = 0;
      const notes = scaleNoteIndexes.map((note, index) => {
        if (index > 0 && scaleNoteIndexes[index] < scaleNoteIndexes[index - 1]) {
          octaveJump = SEMITONES_IN_OCTAVE;
        }
        return note + octaveJump;
      });
      notesToPlay = notes.map(note => convertNoteNumberToString(note + convertNoteStringToNoteNumber('A3')));
      notesToPlay.push(convertNoteNumberToString(notes[0] + convertNoteStringToNoteNumber('A4')));
    } else {
      notesToPlay = this.convertChordToPitchedNotes(chart.notes);
    }
    if (guitarSound) {
      notesToPlay.forEach(note => {
        this.soundService.play([note], chart.scale, instruments[this.playInstrument] as InstrumentName);
        // this.playGuitar(note, chart.scale);
      });
    } else {
      this.soundService.play(notesToPlay, chart.scale, instruments[this.playInstrument] as InstrumentName);
    }
  }

  playGuitar(note, arpeggiate = false): void {
    this.soundService.play([note], arpeggiate, instruments[this.playInstrument] as InstrumentName);
  }

  chordNoteClicked(mouseDown: boolean, noteList, index): void {
    if (mouseDown) {
      this.logService.write(LogType.Play, LogContext.Chord, { noteList, index})
      this.playGuitar(this.convertChordToPitchedNotes(noteList)[index]);
    }
  }

  fretNoteClicked(mouseDown: boolean, chart, index, stringNote): void {
    const note = this.getNoteLabel(chart, stringNote, index, true);
    if (note && mouseDown) {
      this.logService.write(LogType.Play, LogContext.Fret, {chart, index, stringNote})
      this.playGuitar(convertNoteNumberToString(index + convertNoteStringToNoteNumber(stringNote)));
    }
  }

  getNoteLabel(chart, note: string, fretOffset: number, ignoreFormat = false): string {
    if (fretOffset < this.capo) {
      return '';
    }
    let noteNumberInOctave = -1;
    const noteNumber = this.utilsService.convertNoteToBaseOctave(convertNoteStringToNoteNumber(note) + fretOffset);
    if (chart.scale) {
      // figure out if note is in the scale of the given mode
      if (this.utilsService.getScaleNotes(chart.root.substring(0, 2), chart.type, this.getCurrentScale()).indexOf(noteNumber) >= 0) {
        noteNumberInOctave = noteNumber;
      }
    } else {
      // figure out if note is in the specified chord
      const chordAsNoteNumbers = this.convertChordToNoteNumbers(chart.notes.concat(chart.addedNotes));
      const noteIndexInChord = chordAsNoteNumbers.indexOf(noteNumber);
      if (noteIndexInChord >= 0) {
        noteNumberInOctave = chordAsNoteNumbers[noteIndexInChord];
      }
    }
    if (noteNumberInOctave < 0) {
      return '';
    }
    let noteInRootKey = (noteNumberInOctave  - 1);
    if (noteInRootKey < 0) {
      noteInRootKey += SEMITONES_IN_OCTAVE;
    }
    let offset = convertNoteStringToNoteNumber(this.root.substring(0, 2));
    if (/*this.renderingMode.notedisplay === 'chord' &&*/ !chart.scale) {
      offset = convertNoteStringToNoteNumber(chart.root.substring(0, 2));
    }
    return this.utilsService.noteIndexToNoteName(noteInRootKey, ignoreFormat ? 'academic' : this.renderingMode.format, offset - 1, this.scaleIndex, this.key, this.mode);
  }

  convertChordToPitchedNotes(notes): Array<string> {
    // tslint:disable-next-line:max-line-length
    notes = notes.map(note => note.slice(0, 2));
    // tslint:disable-next-line:max-line-length
    const noteIndexes = notes.map(note => modulo(noteNames.find(noteName => noteName.name === note).index - 3, SEMITONES_IN_OCTAVE));
    let octave = '4';
    return notes.map((note, index) => {
      if (index > 0 && noteIndexes[index] < noteIndexes[index - 1]) {
        octave = '5';
      }
      return note + octave;
    });
  }

  downloadPDF(chart): void {
    this.showLogo = chart;
    setTimeout(() => {
      this.downloadService.downloadPNGFromDOM(this.guitarChart.nativeElement,
          `${chart.root} ${chart.type}, ${this.tuning.join('')}`);
      this.showLogo = undefined;
    }, 1000);
  }

  needsDot(chart, index: number, position: number): boolean {
    return this.currentDots.find(dotInfo => dotInfo[0] === index + 1 && dotInfo[1] === position) ? true : false;
  }

  position(chart, index: number, position: number): string {
    const stringCount = this.tuning.length;
    switch (stringCount) {
      case 4:
        break;
      case 5:
        break;
      case 6:
        return '104px';
        break;
      case 7:
        break;
      case 8:
        break;
    }
  }

  stringSequence(obeyHandedness = true): Array<string> {
    const pitchedTuning = tuningInfos.find(tuningInfo => tuningInfo.tuning === this.tuning.join(''));
    if (!pitchedTuning) {
      const tuningStrings = this.strings.map(thisString => thisString.note); // tuningStringToArray(this.tuning.join(''));
      return (this.renderingMode.leftHanded && obeyHandedness) ? tuningStrings.reverse() : tuningStrings;
    }
    return (this.renderingMode.leftHanded && obeyHandedness) ? pitchedTuning.strings.reverse() : pitchedTuning.strings;
  }

  getFillerNotes(chart): Array<any> {
    const fillerNoteCount = this.tuning.length - chart.notes.length - chart.addedNotes.length;
    return new Array(fillerNoteCount < 0 ? 0 : fillerNoteCount);
  }

  getSecondRowFillerNotes(chart): Array<any> {
    return new Array(this.tuning.length - this.secondRowAddedNotes(chart).length);
  }

  private convertNotesToNames(chart, notes: Array<string>): Array<string> {
    return notes.map(note => {
      const index = convertNoteStringToNoteNumber(note);
      let offset = convertNoteStringToNoteNumber(this.root.substring(0, 2));
      if (!chart.scale) {
        offset = convertNoteStringToNoteNumber(chart.root.substring(0, 2));
      }
      return this.utilsService.noteIndexToNoteName(index - 1, this.renderingMode.format, offset - 1);
    });
  }

  chordNotes(chart): Array<string> {
    return this.convertNotesToNames(chart, this.convertChordToPitchedNotes(chart.notes));
  }

  firstRowAddedNotes(chart): Array<string> {
    return this.convertNotesToNames(chart, chart.addedNotes.slice(0, this.tuning.length - chart.notes.length));
  }

  secondRowAddedNotes(chart): Array<string> {
    // tslint:disable-next-line:max-line-length
    return this.convertNotesToNames(chart, chart.addedNotes.slice(-chart.addedNotes.length  + this.tuning.length - chart.notes.length));
  }

  needSecondNoteRow(chart): boolean {
    return this.tuning.length - chart.notes.length - chart.addedNotes.length < 0;
  }

  convertChordToNoteNumbers(chord): Array<number> {
    return chord.map(chordNote => modulo(convertNoteStringToNoteNumber(chordNote) - 1, SEMITONES_IN_OCTAVE) + 1);
  }

  getCurrentScale(): ScaleFamily {
    // const scaleIndex = this.form.get('scale').value;
    return music.scaleFamily[this.scaleIndex >= 0 ? this.scaleIndex : 0];
  }

  nutClass(chart, stringNote): string {
    const hoverclass = this.getNoteLabel(chart, stringNote, 0) ? '  fretnote' : '';
    return this.capo === 0 ? 'thickbottomborder' + hoverclass : hoverclass;
  }

  fretClass(chart, index, guitarString): string {
    if (index + 1 === this.capo) {
      return 'nutrow thickbottomborder';
    }
    const hasAddedNotes = chart.addedNotes.length > 0;
    const hoverclass = this.getNoteLabel(chart, guitarString, index + 1) ? '  fretnote' : '';
    let returnvalue;
    index = index % SEMITONES_IN_OCTAVE;
    if (index === 11) {
      returnvalue =  hasAddedNotes ? 'doubledotfretrowextra' : 'doubledotfretrow';
    } else if (index === 2 || index === 4 || index === 6 || index === 8) {
      returnvalue =  hasAddedNotes ? 'dotfretrowextra' : 'dotfretrow';
    } else {
      returnvalue = 'fretrow fretrow' + (index % 4);
    }
    return returnvalue + hoverclass;
  }

  bestChartIcon(chart): string | undefined {
    const bestCombination = bestCombinations.find(combination => combination.tuning === this.tuning.join(''));
    if (chart.scale) {
      if (bestCombination) {
        // tslint:disable-next-line:max-line-length
        return bestCombination.bestCombinations.find(keyMode => keyMode.key === this.root && keyMode.mode === this.type) ? 'assets/images/icons/star-64.png' : undefined;
      }
    } else {
      const overlapCount = this.utilsService.chordOpenStringOverlap(chart, this.tuning);
      const starType = overlapCount < 0 ? 'assets/images/icons/red-star-64.png' : 'assets/images/icons/star-64.png';
      return starType;
    }
    return undefined;
  }

  showAnnotations(chart): void {
    const dialog = this.dialog.open(AnnotationsDialogComponent);
    dialog.componentInstance.data = { modal: true, message: `${chart.scale ? 'Scale' : 'Chord'} ${chart.root} ${chart.type}`, parentId: `${this.renderingMode.instrument}-${chart.scale}-${chart.root}-${chart.type.replace('/', ':')}-${chart.notes.join('.')}-${chart.addedNotes.join('.')}`, parentType: ParentType.Chart};
  }

  zoomClicked(chart): void {
    this.logService.write(LogType.Play, LogContext.Zoom, {chart});
    this.fullscreenService.open(this.guitarChart.nativeElement);
  }

  openClicked(chart): void {
    const params = {
      playInstrument: this.playInstrument,
      tuning: this.tuning,
      strings: this.strings,
      root: this.root,
      type: this.type,
      currentDots: this.currentDots,
      scaleIndex: this.scaleIndex,
      chart: this.chart
    };
    window.open(`fretboard/${this.cryptoService.encodeJSON(params)}`, 'chartwindow', 'height=640, width=320, toolbar=no, menubar=no, resizable=yes');
  }

  addToCollection(chart): void {
    const dialogRef = this.dialog.open(CollectionDialogComponent, {
      data: new CollectionDialogModel(`Choose a collection to save your chart to.`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const itemInfo = {
          type: ParentType.Chart,
          name: `${chart.root} ${chart.type}`,
          details: {
            name: this.title
          },
          order: -1
        };
        this.collectionItemsService.create(dialogResult, itemInfo).then(item => {
          this.snackBar.open('Chart added to collection');
        });
      }
    });
  }

  addToPrevious(chart): void {
    this.profileService.getPreviousCollectionId().then(previous => {
      if (previous) {
        const itemInfo = {
          type: ParentType.Chart,
          name: `${chart.root} ${chart.type}`,
          details: {
            name: this.title
          },
          order: -1
        };
        this.collectionItemsService.create(previous, itemInfo).then(item => {
          this.snackBar.open('Chart added to collection');
        });
      } else {
        this.snackBar.open('No previous collection');
      }
    });
  }

}

@Pipe({
  name: 'chordOpenStringOverlap',
  pure: true
})
export class ChordOpenStringOverlapPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): number {
    return thisArg.utilsService.chordOpenStringOverlap(chart, thisArg.tuning);
  }

}

@Pipe({
  name: 'chordNotes',
  pure: true
})
export class ChordNotesPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): Array<string> {
    return thisArg.chordNotes(chart);
  }

}

@Pipe({
  name: 'firstRowAddedNotes',
  pure: true
})
export class FirstRowAddedNotesPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): Array<string> {
    return thisArg.firstRowAddedNotes(chart);
  }

}

@Pipe({
  name: 'secondRowAddedNotes',
  pure: true
})
export class SecondRowAddedNotesPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): Array<string> {
    return thisArg.secondRowAddedNotes(chart);
  }

}

@Pipe({
  name: 'needSecondNoteRow',
  pure: true
})
export class NeedSecondNoteRowPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): boolean {
    return thisArg.needSecondNoteRow(chart);
  }

}

@Pipe({
  name: 'getSecondRowFillerNotes',
  pure: true
})
export class GetSecondRowFillerNotesPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): Array<string> {
    return thisArg.getSecondRowFillerNotes(chart);
  }

}

@Pipe({
  name: 'getFillerNotes',
  pure: true
})
export class FillerNotesPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): Array<string> {
    return thisArg.getFillerNotes(chart);
  }

}

@Pipe({
  name: 'stringSequence',
  pure: true
})
export class StringSequencePipe implements PipeTransform {

  transform(thisArg: GuitarchartComponent): Array<string> {
    return thisArg.stringSequence();
  }

}

@Pipe({
  name: 'nutClass',
  pure: true
})
export class NutClassPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, guitarString): string {
    return thisArg.nutClass(chart, guitarString);
  }

}

@Pipe({
  name: 'fretClass',
  pure: true
})
export class FretClassPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, index, guitarString): string {
    return thisArg.fretClass(chart, index, guitarString);
  }

}

@Pipe({
  name: 'getNoteLabel',
  pure: true
})
export class GetNoteLabelPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, guitarString, index): string {
    return thisArg.getNoteLabel(chart, guitarString, index);
  }

}

@Pipe({
  name: 'showLeftDot',
  pure: true
})
export class ShowLeftDotPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, index): boolean {
    return thisArg.needsDot(chart, index, -1);
  }

}

@Pipe({
  name: 'showMiddleDot',
  pure: true
})
export class ShowMiddleDotPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, index): boolean {
    return thisArg.needsDot(chart, index, 0);
  }

}

@Pipe({
  name: 'showRightDot',
  pure: true
})
export class ShowRightDotPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent, index): boolean {
    return thisArg.needsDot(chart, index, 1);
  }

}


@Pipe({
  name: 'bestChartIcon',
  pure: true
})
export class BestChartIconPipe implements PipeTransform {

  transform(chart: any, thisArg: GuitarchartComponent): string | undefined {
    return thisArg.bestChartIcon(chart);
  }

}

