import { Injectable } from '@angular/core';
import firebase from 'firebase';
import {AuthService} from '../auth/auth.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {

  USER_STORAGE_BASE = 'media/user/';

  private storageRef = firebase.storage().ref();

  constructor(
      private authService: AuthService
  ) { }

  upload(name: string = null, blob, progressFunc: (totalBytes: number, bytesTransferred: number) => void = null): Promise<string> {
    if (!name) {
      name = uuidv4();
    }
    return new Promise((resolve, reject) => {
      const uploadTask = this.storageRef.child(`${this.USER_STORAGE_BASE}${this.authService.getUserId()}/${name}`).put(blob);
      uploadTask.on('state_changed', snapshot => {
        if (progressFunc) {
          progressFunc(snapshot.totalBytes, snapshot.bytesTransferred);
        }
      }, error => {
        reject(error.code);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          resolve(downloadURL);
        });
      });
    });
  }

  delete(name: string): Promise<void> {
      return this.storageRef.child(this.USER_STORAGE_BASE + name).delete();
  }

}
