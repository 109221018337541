import {AfterViewInit, Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {guitarDots, TuningInfo, tuningInfos, TuningLimit} from '../../../gd/src/tuning-module';
import {SoundService} from '../../../services/sound/sound.service';
import {UtilsService} from '../../../services/utils/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {keySignatures, SEMITONES_IN_OCTAVE, sharpOctave} from '../../../data/charts';
import {scaleFamily} from '../../../gd/src/music-module';
import {LogService} from '../../../services/firestore/log/log.service';
import {
  convertNoteNumberToString, convertNoteStringByOffset,
  convertNoteStringToNoteNumber,
  deepCopy,
  modulo,
  removeOctave, tuningStringToArray
} from '../../../services/best/best.service.utils';
import {CryptoService} from '../../../services/crypto/crypto.service';

interface Chart {
  key: string;
  scale: string;
  mode: string;
}

@Component({
  selector: 'app-tuningsdialog',
  templateUrl: './tuningsdialog.component.html',
  styleUrls: ['./tuningsdialog.component.scss']
})

export class TuningsDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<Chart>();
  public columnsToDisplay = ['focusNote', 'mode', 'key', 'scale', 'actionsmenu' ];
  public form: FormGroup;
  public tuning: TuningInfo;
  public title;
  public currentTuning;
  public baseTuning: Array<string>;
  public tuningDeltas: Array<number>;
  public bestCount;
  public prettyGoodCount;
  public availableCount;

  private initialTuning: TuningInfo;
  public mode;
  public filter;

  constructor(
      public dialogRef: MatDialogRef<TuningsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TuningsDialogModel,
      private formBuilder: FormBuilder,
      private soundService: SoundService,
      private utilsService: UtilsService,
      private snackBar: MatSnackBar,
      private router: Router,
      private logService: LogService,
      private cryptoService: CryptoService,
  ) {
    this.title = data.title;
    this.mode = data.mode;
    this.initialTuning = deepCopy(data.item.tuning)
    console.log('INITIALTUNING', this.initialTuning)
    this.revertChanges();
    this.form = this.formBuilder.group({
      instrument: new FormControl('', Validators.required),
      tuning: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.showAvailableScalesAndModes();
  }

  ngAfterViewInit(): void {
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.items.filter = filterValue;
  }

  play(): void {
    this.soundService.play(this.tuning.strings, true);
  }

  showAvailableScalesAndModes(): void {
    const thisTuning = {strings: this.tuning.strings.join(''), tuning: removeOctave(this.tuning.strings).join(''), stringCount: this.tuning.strings.length } ;
    this.currentTuning = thisTuning;
    this.tuning.tuning = removeOctave(this.tuning.strings).join('');
    const uniqueStringNotes = [];
    this.tuning.strings.forEach(stringNote => {
      const noteWithNoOctave = stringNote.slice(0, -1);
      if (uniqueStringNotes.indexOf(noteWithNoOctave) < 0) {
        uniqueStringNotes.push(noteWithNoOctave);
      }
    });
    const data = [];
    sharpOctave.forEach(key => {
      scaleFamily.forEach(family => {
        const scaleNotes = this.utilsService.getScaleNotes(key, 1, family).map(note => convertNoteNumberToString(note).slice(0, -1));
        let missing = 0;
        uniqueStringNotes.forEach(stringNote => {
          if (!scaleNotes.find(scaleNote => scaleNote === stringNote)) {
            missing++;
          }
        });
        if (!missing) {
          family.modes.forEach(mode => {
              data.push({focusNote: convertNoteStringByOffset(key, mode.index).slice(0, -1), key, scale: family.name, mode: mode.name, modeNumber: mode.modeNumber});
          });
        }
      });
    });
    this.items.data = data;
  }

  private limitInfo(): TuningLimit {
    return this.tuning.parentIndex === -1 ? this.tuning.tuningLimit : tuningInfos[this.tuning.parentIndex].tuningLimit;
  }

  private canTuneUp(index): boolean {
    return !this.limitInfo().noUpStrings.find(stringNumber => stringNumber === this.tuning.strings.length - index);
  }

  upTune(index): void {
    const currentNoteNumber = convertNoteStringToNoteNumber(this.tuning.strings[index]);
    const baseNoteNumber = convertNoteStringToNoteNumber(this.baseTuning[index]);
    if (false/*!this.canTuneUp(index)*/) {
      this.snackBar.open('You can\'t tune this string up or it might break.');
    } else {
      let upSemitones = this.limitInfo().upSemitones;
      if (!this.canTuneUp(index)) {
        upSemitones--;
      }
      if (currentNoteNumber < baseNoteNumber + upSemitones) {
        this.tuning.strings[index] = convertNoteNumberToString(currentNoteNumber + 1);
        this.tuningDeltas[index]++;
        this.showAvailableScalesAndModes();
      } else {
        this.snackBar.open('You can\'t tune this string up any more.');
      }
    }
  }

  downTune(index): void {
    const currentNoteNumber = convertNoteStringToNoteNumber(this.tuning.strings[index]);
    const baseNoteNumber = convertNoteStringToNoteNumber(this.baseTuning[index]);
    if (currentNoteNumber > baseNoteNumber - this.limitInfo().downSemitones) {
      this.tuning.strings[index] = convertNoteNumberToString(currentNoteNumber - 1);
      this.tuningDeltas[index]--;
      this.showAvailableScalesAndModes();
    } else {
      this.snackBar.open('You can\'t tune this string down any more.');
    }
  }

  revertChanges(): void {
    this.tuning = deepCopy(this.initialTuning);
    // tslint:disable-next-line:max-line-length
    this.baseTuning = this.tuning.parentIndex === -1 ? deepCopy(this.tuning.strings) : tuningInfos[this.tuning.parentIndex].strings;
    this.setTuningDeltas();
    this.showAvailableScalesAndModes();
  }

  private setTuningDeltas(): void {
    this.tuningDeltas = this.tuning.strings.map((stringNote, index) => {
      const tuningNoteNumber = convertNoteStringToNoteNumber(stringNote);
      const baseNoteNumber = convertNoteStringToNoteNumber(this.baseTuning[index]);
      return (tuningNoteNumber - baseNoteNumber);
    });
  }

  onDismiss(): void {
    this.dialogRef.close(undefined);
  }

  showChart(item): void {
    const newTuning = this.tuning.strings.map(aString => aString.slice(0, -1)).join('');
    if (!tuningInfos.find(tuningInfo => tuningInfo.tuning === newTuning)) {
      tuningInfos.unshift({ index: tuningInfos.length, tuning: newTuning, strings: this.tuning.strings, dots: guitarDots, description: 'Custom Tuning', parentIndex: this.tuning.parentIndex });
    }
    this.dialogRef.close(undefined);
    this.router.navigate(['charts', this.cryptoService.encodeJSON({focusNote: item.focusNote, rootKey: item.focusNote, mode: item.mode ? item.mode : item.modeNumber, scale: item.scale, tuning: newTuning, strings: this.tuning.strings})]);
  }

  randomTuning(): void {
    const range = this.limitInfo().upSemitones + this.limitInfo().downSemitones + 1;
    this.baseTuning.forEach((aString, index) => {
      const randomDelta = Math.floor(Math.random() * Math.floor(this.canTuneUp(index) ? range : this.limitInfo().downSemitones + 1)) - this.limitInfo().downSemitones;
      const currentNoteNumber = convertNoteStringToNoteNumber(aString);
      this.tuning.strings[index] = convertNoteNumberToString(currentNoteNumber + randomDelta);
    });
    this.setTuningDeltas();
    this.showAvailableScalesAndModes();
    if (this.items.data.length === 0) {
      this.randomTuning();
    }
  }

  setBestCount(event): void {
    if (event < 0) {
      this.dialogRef.close();
    }
    this.bestCount = event;
  }

  setPrettyGoodCount(event): void {
    if (event < 0) {
      this.dialogRef.close();
    }
    this.prettyGoodCount = event;
  }

  setAvailableCount(event): void {
    if (event < 0) {
      this.dialogRef.close();
    }
    this.availableCount = event;
  }

  onTabChanged(event): void {
    this.items.sort = this.sort;
  }

  selectTuning(event): void {
    this.tuning.tuning = this.tuning.strings.map(aString => aString.slice(0, -1)).join('');
    this.dialogRef.close(this.tuning);
  }

}

export class TuningsDialogModel {
  constructor(public title: string, public item: TuningInfo, public mode?: string) {
  }
}
