import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {FirestoreService } from '../firestore';
import firebase from 'firebase';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

export interface Profile {
  displayName: string;
  uid: string;
  email: string;
  imageURL: string;
  format: string;
  handedness: string;
  frets: number;
  instrument: string;
  playbackInstrument: string;
  marketingId: string;
  previousCollectionId: string;
  loginCount: number;
  role: string;
  chargebeeCustomer: any;
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin'
}

@Injectable({
  providedIn: 'root'
})

export class ProfileService extends FirestoreService {

  MAX_ALLOWED_LOGINS = 2000;

  private previousCollectionId;

  public role;

  constructor(
      public authService: AuthService,
      private snackBar: MatSnackBar,
      private router: Router
  ) {
    super(authService);
    this.collectionName = 'profiles';
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.isLoginAllowed().then(allowed => {
          if (allowed) {
            this.incrementLoginCount();
          } else {
            firebase.auth().signOut();
            this.router.navigate(['']).then(() => {
              this.snackBar.open('You are already logged in on the maximum of 2 devices.');
            });
          }
        });
      }
      AuthService.loginObservable.next(user ? true : false);
    });

  }

  get(): Promise<Profile> {
    return this.docFromCollection(this.authService.getUserInfo().uid).then(result => {
        this.role = result?.role;
        return result;
    });
  }

  set(values): Promise<boolean> {
    return this.updateDocument(this.authService.getUserInfo().uid, values);
  }

  getUserName(id: string): Promise<string | null> {
    return this.docFromCollection(id).then(profile => profile.displayName);
  }

  getUserProfile(id: string): Promise<string | null> {
    return this.docFromCollection(id);
  }

  getPreviousCollectionId(): Promise<string> {
    if (this.previousCollectionId) {
      return this.previousCollectionId;
    } else {
      return this.get().then(result => result.previousCollectionId);
    }
  }

  setPreviousCollectionId(id: string): void {
    this.previousCollectionId = id;
    this.set({ previousCollectionId: id });
  }

  incrementLoginCount(): void {
    this.get().then(profile => {
      this.set({loginCount: profile.loginCount ? profile.loginCount + 1 : 1});
    });
  }

  logOut(): Promise<void> {
    return this.get().then(profile => {
      this.set({loginCount: profile.loginCount - 1}).then(() => {
        return firebase.auth().signOut();
      });
    });
  }

  isLoginAllowed(): Promise<boolean> {
    return this.get().then(profile => {
      return profile.loginCount ? profile.loginCount < this.MAX_ALLOWED_LOGINS : true;
    });
  }

}
