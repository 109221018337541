import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DataChannelName, DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-sidenav-base',
  templateUrl: './sidenav-base.component.html',
  styleUrls: ['./sidenav-base.component.scss']
})
export class SidenavBaseComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formDataChannel: Subscription;
  public formChangeChannel: Subscription;
  public data;

  constructor(
      protected formBuilder: FormBuilder,
      protected dataService: DataService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy(): void {
    if (this.formDataChannel) {
      this.formDataChannel.unsubscribe();
    }
  }

  subscribeToChannel(name: DataChannelName, changeFunc = null): void {
    this.formDataChannel = this.dataService.subscribe(name, data => {
      if (data) {
        this.form = data.form;
        this.data = data.data;
        if (changeFunc) {
          changeFunc();
        }
      }
    });
  }
}
