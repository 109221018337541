import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './adddialog.component.html',
  styleUrls: ['./adddialog.component.scss']
})
export class AddDialogComponent {

  title: string;
  message: string;
  editMode: boolean;
  formItems = [];
  form: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<AddDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: AddDialogModel,
      private formBuilder: FormBuilder,
      private logService: LogService
  ) {
    this.title = data.title;
    this.message = data.message;
    this.editMode = data.edit;
    const formFields = {};
    data.formItems.forEach(item => {
      formFields[item.controlName] = new FormControl(item.value, item.required ? Validators.required : null);
    });
    this.form = this.formBuilder.group(formFields);
    this.formItems = data.formItems;
  }

  onConfirm(): void {
    const returnValues = {};
    this.formItems.forEach(item => {
      returnValues[item.controlName] = this.form.get(item.controlName).value;
    });
    this.dialogRef.close(returnValues);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}

export class AddDialogModel {
  constructor(public edit: boolean, public title: string, public message: string,
              public formItems: Array<{type: string, value: any, controlName: string, label: string, required: boolean }>) {
  }
}
