import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ChordType} from '../../gd/src/chord-module';
import * as music from '../../gd/src/music-module';
import {FormBuilder, FormControl} from '@angular/forms';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {LeftMenu} from '../../app.component';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {ReusePageBaseComponent} from '../reusepagebase/reusepagebase.component';
import {SoundService} from '../../services/sound/sound.service';

@Component({
  selector: 'app-chords',
  templateUrl: './chords.component.html',
  styleUrls: ['./chords.component.scss']
})
export class ChordsComponent extends ReusePageBaseComponent implements OnInit {

  @ViewChild('tabs') tabs: MatTabGroup;

  public items = new MatTableDataSource<ChordType>();
  public columnsToDisplay = ['name', 'abbreviations', 'intervals', 'guitar', 'actionmenu' ];
  public tabIndex = 0;
  public notes = [];
  public changeCounter = 0;
  visible = true;

  constructor(
      private soundService: SoundService,
      injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('chords', LeftMenu.CHORDS);
    this.soundService.soundListener.subscribe(event => {
        if (this.isActive) {
          this.notes = [...this.notes, (event as any).note.slice(0, -1)];
        }
    });
  }

  indicateChange(): void {
    this.changeCounter++;
  }

  notesChanged(event): void {
      this.notes = [...event];
  }

  onTabChanged(event: MatTabChangeEvent): void {
    this.tabIndex = event.index;
  }

  menuItemSelected(event): void {
    if (event.type === 'related') {
      this.tabs.selectedIndex = 0;
      this.notes = event.notes;
    }
  }

}

