import { Component, OnInit } from '@angular/core';
import {chordTypes} from '../../gd/src/chord-module';
import {flatOctave, sharpOctave} from '../../data/charts';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-easystart',
  templateUrl: './easystart.component.html',
  styleUrls: ['./easystart.component.scss']
})
export class EasystartComponent implements OnInit {

  public workspaceItems = [];
  public chords;
  public keys;

  constructor() { }

  ngOnInit(): void {
    this.chords = this.clone(chordTypes);
    this.keys = this.clone(sharpOctave.map((note, index) => ({ name: `${note}${note === flatOctave[index] ? '' : ('/' + flatOctave[index])}`})));
  }

  private clone(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }

  workspaceDrop(event: CdkDragDrop<string[]>): void {
    if (event.container.id === event.previousContainer.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
    }
  }

}
