import * as d3 from 'd3';
import * as tonics from './tonics-module';
import * as modes from './modes-module';
import * as music from './music-module';
import * as chordInterval from './chord-interval-module';
import * as chords from './chord-module';
import * as cof from './cof-module';
import * as gtr from './gtr-module';
import * as tuning from './tuning-module';
import * as scaleFamily from './scale-family-module';
import * as settings from './settings-module';
import * as state from './state-module';
import * as permalink from './permalink-module';
import * as midi from './midi-module';

export function init(): void {
    midi.init();
    tonics.init();
    modes.init(music.scaleFamily[0]);
    chordInterval.init();
    const chromatic = new cof.NoteCircle(d3.select('#chromatic'), music.chromatic(), 'Chromatic');
    const circleOfFifths = new cof.NoteCircle(d3.select('#cof'), music.fifths(), 'Circle of Fifths');
    gtr.init();
    tuning.init();
    scaleFamily.init();
    settings.init();
    permalink.init();
    state.init();
    chords.test();
}
