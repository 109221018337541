import { Component, OnInit } from '@angular/core';
import {fromEvent, interval, Observable} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {pluck, take, tap} from 'rxjs/operators';

declare var MediaRecorder: any;
declare var WaveSurfer: any;

@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.scss']
})
export class RecordingComponent implements OnInit {

  MAX_RECORD_TIME = 600;  // seconds

  public recordTime;
  public recording = false;

  private tick;
  private stream;
  private canRecord;
  private audioData;

  public msaapDisplayTitle = false;
  public msaapDisplayPlayList = false;
  public msaapPageSizeOptions = [2, 4, 6];
  public msaapDisplayVolumeControls = true;
  public msaapDisablePositionSlider = true;

  public msaapPlaylist = [
    {
      title: '',
      link: this.domSanitizer.bypassSecurityTrustResourceUrl('assets/audio/250-milliseconds-of-silence.mp3')
    }
  ];

  private mediaRecorder;
  private recordings$: Observable<any>;
  private wavesurfer;

  constructor(
      private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    navigator.mediaDevices.enumerateDevices().then(results => {
      console.log('MEDIA', results);
    });
    /*
    navigator.mediaDevices.getUserMedia({audio: true})
        .then(mediaStream => {
          const ac = new AudioContext();
          const source = ac.createMediaStreamSource(mediaStream);
          source.connect(ac.destination);
          this.stream = source;
          this.mediaRecorder = new MediaRecorder(source);
          this.recordings$ = fromEvent(this.mediaRecorder, 'dataavailable');
          this.canRecord = true;
        });
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.stream = stream;
          this.mediaRecorder = new MediaRecorder(stream);
          this.recordings$ = fromEvent(this.mediaRecorder, 'dataavailable');
          this.canRecord = true;
        })
        .catch(error => {
          this.canRecord = false;
        });
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
    });
    */

  }

  recordClicked(): void {
    if (this.recording) {
      this.mediaRecorder.stop();
      this.tick.unsubscribe();
    } else {
      this.recordTime = '00:00:00';
      this.tick = interval(1000).subscribe(time => {
        this.recordTime = new Date((time + 1) * 1000).toISOString().substr(11, 8);
        if (time >= this.MAX_RECORD_TIME) {
          this.tick.unsubscribe();
          this.recording = false;
          return;
        }
      });
      this.mediaRecorder.start();
      this.recordings$.pipe(
          take(1),
          pluck('data'),
          tap((data: BlobPart) => {
            this.audioData = data;
            this.msaapPlaylist[0].link = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
            this.wavesurfer.load(URL.createObjectURL(data));
          })
      ).subscribe();

    }
    this.recording = !this.recording;
  }

  onEnded(event): void {

  }


}
