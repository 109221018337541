import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { Track } from 'ngx-audio-player';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloudStorageService} from '../../../services/cloudstorage/cloudstorage.service';
import {fromEvent, interval, Observable} from 'rxjs';
import {pluck, take, tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LogService} from '../../../services/firestore/log/log.service';

declare var MediaRecorder: any;
declare var WaveSurfer: any;

@Component({
  selector: 'app-recordaudiodialog',
  templateUrl: './recordaudiodialog.component.html',
  styleUrls: ['./recordaudiodialog.component.scss']
})
export class RecordAudioDialogComponent implements OnInit {

  MAX_RECORD_TIME = 600;  // seconds

  filename: string;
  message: string;
  private: boolean;

  public uploading = false;
  public uploadingPercent = 0;
  public form: FormGroup;

  public recording = false;
  public recordTime = '';
  private tick;
  private canRecord;
  private audioData;
  private stream;

  public msaapDisplayTitle = false;
  public msaapDisplayPlayList = false;
  public msaapPageSizeOptions = [2, 4, 6];
  public msaapDisplayVolumeControls = true;
  public msaapDisablePositionSlider = true;

  public msaapPlaylist = [
    {
      title: '',
      link: this.domSanitizer.bypassSecurityTrustResourceUrl('assets/audio/250-milliseconds-of-silence.mp3')
    }
  ];

  private mediaRecorder;
  private recordings$: Observable<any>;
  private wavesurfer;

  constructor(
      private cloudStorageService: CloudStorageService,
      private domSanitizer: DomSanitizer,
      private formBuilder: FormBuilder,
      private logService: LogService,
      public dialogRef: MatDialogRef<RecordAudioDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: RecordAudioDialogModel
  ) {
    this.message = data.message;
    this.private = data.isPrivate;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({private: new FormControl(this.private)});
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.stream = stream;
          this.mediaRecorder = new MediaRecorder(stream);
          this.recordings$ = fromEvent(this.mediaRecorder, 'dataavailable');
          this.canRecord = true;
        })
        .catch(error => {
          this.canRecord = false;
        });
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
    });
  }

  stopAllAudio(): void {
    this.stream.getAudioTracks().forEach(track => {
      track.stop();
    });
  }

  onDismiss(): void {
    this.stopAllAudio();
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.stopAllAudio();
    const blob = new Blob([this.audioData], { type: 'audio/mpeg' });
    this.uploading = true;
    this.cloudStorageService.upload(null, blob, (totalBytes, bytesTransferred) => {
      this.uploadingPercent = 100 * bytesTransferred * totalBytes;
    }).then(url => {
      this.dialogRef.close({ url, isPrivate: this.form.get('private').value });
    }).catch(error => {
      this.dialogRef.close(null);
    });
  }

  onEnded(event): void {

  }

  recordClicked(): void {
    if (this.recording) {
      this.tick.unsubscribe();
      this.mediaRecorder.stop();
    } else {
      this.recordTime = '00:00:00';
      this.tick = interval(1000).subscribe(time => {
        this.recordTime = new Date((time + 1) * 1000).toISOString().substr(11, 8);
        if (time >= this.MAX_RECORD_TIME) {
          this.tick.unsubscribe();
          this.recording = false;
          return;
        }
      });
      this.mediaRecorder.start();
      this.recordings$.pipe(
          take(1),
          pluck('data'),
          tap((data: BlobPart) => {
            this.audioData = data;
            this.msaapPlaylist[0].link = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data));
            this.wavesurfer.load(URL.createObjectURL(data));
          })
      ).subscribe();
    }
    this.recording = !this.recording;
  }

}

export class RecordAudioDialogModel {
  constructor(public message: string, public isPrivate: boolean) {
  }
}
