import {Injectable} from '@angular/core';
import {SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import {BestApiService} from './best.service.api';
import {noteNames, scaleFamily} from '../../gd/src/music-module';
import {scales282} from '../../data/282 Scales and Modes';
import {getBestTunings} from './best.service.utils';

@Injectable({
  providedIn: 'root'
})

export class BestService {

  private workers: Array<Worker> = [];
  private callbackFunc;

  constructor(
      private bestApiService: BestApiService
  ) {
    console.log(`${navigator.hardwareConcurrency} threads`);
    console.log('****bestTunings', getBestTunings({tuning: 'DGADGB', parentIndex: -1, description: '', index: 0, dots: [], strings: ['D2', 'G2', 'A2', 'D3', 'G3', 'B3']}, 'A', ['C', 'D', 'E', 'G', 'A'], true, false));

    // this.addModesToScales();
    sharpOctave.forEach(key => {
      const worker = new Worker('./best.service.worker', { type: 'module' });
      worker.onmessage = ({ data }) => {
        this.workerEventFunc(data);
      };
      this.workers.push(worker);
    });
  }

  addModesToScales(): void {
    scaleFamily.forEach(family => {
      const modes = scales282.filter(scale => scale.ScaleName === family.name);
      family.modes = modes.map(mode => {
        const aliases = [];
        [2, 3, 4, 5, 6].forEach(n => {
          const alias = mode['ModeName' + n];
          if (alias) {
            aliases.push(alias);
          }
        });
        let modeCount = mode.ModeNumber;
        let index = 0;
        for (; index < SEMITONES_IN_OCTAVE; index++) {
          if (family.intervals.itemAt(index)) {
            if (--modeCount === 0) {
              break;
            }
          }
        }
        return {name: mode.ModeName1, index, modeNumber: mode.ModeNumber, aliases };
      });
    });
  }

  workerEventFunc(data: any): void {
    this.bestApiService.postBest(data.table, data);
    this.callbackFunc(data);
  }

  buildTuningsReverseIndex(tuningIndex, table, looseMatch, eventFunc): void {
    this.callbackFunc = eventFunc;
    sharpOctave.forEach((key, index) => {
      this.workers[index].postMessage({op: 'buildTuningsReverseIndex', tuningIndex, key, table, looseMatch});
    });
  }

  drawFromNotes(notes, extraNote, eventFunc): void {
    this.callbackFunc = eventFunc;
    this.workers[0].postMessage({op: 'drawFromNotes', notes, extraNote});
  }

}
