import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dragbin',
  templateUrl: './dragbin.component.html',
  styleUrls: ['./dragbin.component.scss']
})
export class DragbinComponent implements OnInit {

  @Input() title;
  @Input() items;
  @Input() listId;
  @Input() connectedToId;

  constructor() { }

  ngOnInit(): void {
  }

  workspaceDrop(event: CdkDragDrop<string[]>): void {
    console.log('DROP', event)
    if (event.container.id === event.previousContainer.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
    }
  }

}
