// internal representation uses academic notation. e.g Bb Major is a legal scale, A# major is not

interface Chart {
    root: string;
    type: string;
    scale?: boolean;
    notes?: Array<string>;
    addedNotes?: Array<string>;
}

export const chartDictionary = [
    {
        family: 'C Major Dorian',
        tuning: ['D3', 'A3', 'D4', 'G4', 'A4', 'D5'],
        charts: [
        ] as Array<Chart>
    }
];

export const renderingModes = [
    {
        leftHanded: [false, true],
        formats: ['Academic', 'Numbers', 'Sharps', 'Flats', 'Intervals'],
        frets: [15, 22, 24]
    }
];

/*
export const scaleIntervals = {
    Major: [2, 2, 1, 2, 2, 2, 1],
    NaturalMinor: [2, 1, 2, 2, 1, 2, 2],
    MelodicMinor: [2, 1, 2, 2, 2, 2, 1],
    HarmonicMinor: [2, 2, 1, 2, 1, 3, 1]
};
 */

export const intervalNames = [
    'P1', 'm2', 'M2', 'm3', 'M3', 'P4', 'd5', 'P5', 'm6', 'M6', 'm7', 'M7'
];

export const augmentedDiminishedIntervalNames = [
    'd2', 'A1', 'd3', 'A2', 'd4', 'A3', 'd5/A4', 'd6', 'A5', 'd7', 'A6', 'd8'
];

export const flatNotes = [
    '1', '♭2', '2', '♭3', '3', '4', '♭5', '5', '♭6', '6', '♭7', '7'
];

export const sharpNotes = [
    '1', '♯1', '2', '♯2', '3', '4', '♯4', '5', '♯5', '6', '♯6', '7'
];

export const sharpOctave = [
    'A', 'A♯', 'B', 'C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯'
];

export const flatOctave = [
    'A', 'B♭', 'B', 'C', 'D♭', 'D', 'E♭', 'E', 'F', 'G♭', 'G', 'A♭'
];

export const progressions = [
    {
        name: '',
        chords: [ 'I', 'IV', 'V']
    },
    {
        name: '',
        chords: [ 'I', 'V', 'vi', 'IV']
    },
    {
        name: '',
        chords: [ 'ii', 'V', 'I']
    },
]

export interface KeySignature {
    readonly scale: string;
    readonly useSharps: boolean;
    readonly majorKey: string;
    readonly minorKey: string;
    readonly enharmonicMajor?: string;
    readonly enharmonicMinor?: string;
}

export const keySignatures: Array<KeySignature> = [
    { majorKey: 'C', minorKey: 'A', scale: 'Major', useSharps: true },
    { majorKey: 'G', minorKey: 'E', scale: 'Major', useSharps: true },
    { majorKey: 'D', minorKey: 'B', scale: 'Major', useSharps: true },
    { majorKey: 'A', minorKey: 'F♯', scale: 'Major', useSharps: true },
    { majorKey: 'E', minorKey: 'C♯', scale: 'Major', useSharps: true },
    { majorKey: 'B', minorKey: 'G♯', scale: 'Major', useSharps: true, enharmonicMajor: 'C♭', enharmonicMinor: 'A♭' },
    { majorKey: 'F♯', minorKey: 'D♯', scale: 'Major', useSharps: true, enharmonicMajor: 'G♭', enharmonicMinor: 'E♭' },
    { majorKey: 'C♯', minorKey: 'A♯', scale: 'Major', useSharps: true, enharmonicMajor: 'D♭', enharmonicMinor: 'B♭' },
    { majorKey: 'F', minorKey: 'D', scale: 'Minor', useSharps: false },
    { majorKey: 'B♭', minorKey: 'G', scale: 'Minor', useSharps: false },
    { majorKey: 'E♭', minorKey: 'C', scale: 'Minor', useSharps: false },
    { majorKey: 'A♭', minorKey: 'F', scale: 'Minor', useSharps: false },
    { majorKey: 'D♭', minorKey: 'E♭', scale: 'Minor', useSharps: false, enharmonicMajor: 'C♯', enharmonicMinor: 'A♯' },
    { majorKey: 'G♭', minorKey: 'B♭', scale: 'Minor', useSharps: false, enharmonicMajor: 'F♯', enharmonicMinor: 'D♯' },
    { majorKey: 'C♭', minorKey: 'A♭', scale: 'Minor', useSharps: false, enharmonicMajor: 'B', enharmonicMinor: 'G♯' },
]

export const SEMITONES_IN_OCTAVE = 12;

export default chartDictionary;
