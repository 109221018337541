import { Injectable, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { Router } from '@angular/router';
import fscreen from 'fscreen';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {
    public storage;

    private user = {
        getCookie: this.getCookie,
        setCookie: this.setCookie,
        deleteCookie: this.deleteCookie
    };
    private fullscreen = false;

    constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
        this.storage = this.Storage('localStorage');
        this.storageInit();
    }

    validateColor(color): boolean {
        const div = document.createElement('div');
        div.style.borderColor = color;
        return !!div.style.borderColor;
    }

    navChange(route?, routeCommands?: any[], queryParams?: any): Promise<boolean> {
        return this.router.navigate(routeCommands || [], {
            queryParams,
            relativeTo: route || null
        });
    }

    getCookie(name?): string | null {
        name = (typeof name === 'undefined') ? '__ch_temp_cookie' : name;
        const v = document.cookie.match(new RegExp('(^|;) ?' + name + '=([^;]*)(;|$)'));
        return v ? v[2] : null;
    }

    setCookie(name, value, days): void {
        const d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
    }

    deleteCookie(name): void { this.setCookie(name, '', -1); }

    // From: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    private _storageAvailable(type): boolean {
        let storage;
        try {
            storage = window[type];
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    private Storage(storage): {} {
        const storageAvailable = this._storageAvailable(storage || 'localStorage');
        const fakeIt = [];
        function _get(dataName): string {
            return storageAvailable ? window[storage][dataName] || '' : fakeIt[dataName] || '';
        }
        function _set(dataName, value): string {
            const $storage = storageAvailable ? window[storage] : fakeIt;
            $storage[dataName] = value;
            return value;
        }
        return {
            getItem: _get,
            setItem: _set,
            get chordType(): string {
                return _get('chordType');
            },
            set chordType(value) {
                _set('chordType', value);
            },
            init: this.storageInit
        };
    }

    private storageInit(callback?): void {
        // this.initCookieSaveState();
        if (callback && callback.constructor === Function) {
            callback();
        }
    }

    public randomElementID(): string {
        const ID = 'generated_random_element_' + (Math.random().toString().split('0.').pop()) + '_ID';
        return document.getElementById(ID) === null ? ID : this.randomElementID();
    }

    private initCookieSaveState(): void {
        const cookieVal = this.user.getCookie('__ch_temp_cookie');
        const oldCookie = this.storage.getItem('__ch_temp_cookie');
        if (cookieVal && oldCookie && cookieVal === oldCookie) {
            // Do nothing.
        } else if (oldCookie) {
            this.user.setCookie('__ch_temp_cookie', oldCookie, 30);
        } else if (cookieVal) {
            this.storage.setItem('__ch_temp_cookie', cookieVal);
        } else {
            console.error('Temporary save key not found in cookie data.');
        }
    }

    toggleFullScreen(element, closeCallback?: (fscreen?, element?, evnt?) => void): void {
        const $this = this;
        $this.fullscreen = 'fullscreen' in window.document && window.document.fullscreen === true ? true : $this.fullscreen;
        if (fscreen.fullscreenEnabled && !$this.fullscreen) {
            fscreen.requestFullscreen(element);
            if (closeCallback && typeof closeCallback === 'function') {
                fscreen.addEventListener('fullscreenchange', handler);
            }
        }
        else {
            fscreen.exitFullscreen();
        }

        let fullscreen = false;
        function handler(evnt): void {
            if (fullscreen === true) {
                $this.fullscreen = fullscreen = false;
                closeCallback(fscreen, element, evnt);
                fscreen.removeEventListener('fullscreenchange', handler);
            } else {
                $this.fullscreen = fullscreen = true;
            }
        }
    }

    removeDuplicatesFromObject(things: any[]): {} {
        return things.filter((thing, index) => {
            const $thing = JSON.stringify(thing);
            return index === things.findIndex(obj => {
                return JSON.stringify(obj) === $thing;
            });
        });
    }

    getKeys(obj: any, sort?: boolean, key?: string): any[] {
        const $obj: any[] = obj instanceof Array ? obj : Object.keys(obj);
        return sort ? this.sort($obj, key) : $obj;
    }

    sort(arr, key?: string): [] {
        return arr.sort(($a, $b) => {
            if (key) {
                $a = $a[key];
                $b = $b[key];
            }
            const a = $a.toString().toLowerCase().replace(/\s/, '');
            const b = $b.toString().toLowerCase().replace(/\s/, '');
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return a > b ? 1 : a === b ? 0 : -1;
        });
    }

    leftRightSwipeEventListener(addRemove, el, left?: () => void, right?: () => void, up?: () => void, down?: () => void) {
        const $ = window['jQuery'];
        let touchstartX = 0;
        let touchstartY = 0;
        let touchendX = 0;
        let touchendY = 0;
        const $ngNavListener = $(el);

        const touchend = ['touchend', (event) => {
            event = event.originalEvent || event;
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            handleGesture(event);
        }];

        const touchstart = ['touchstart', (event) => {
            event = event.originalEvent || event;
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        }];

        const touchmove = ['touchmove', (e) => {
            e = e.originalEvent || e;
            e.stopPropagation();
        }];

        if (addRemove) {
            $ngNavListener.on(...touchstart);

            $ngNavListener.on(...touchmove);

            $ngNavListener.on(...touchend);
        }
        else {
            $ngNavListener.off(...touchstart);

            $ngNavListener.off(...touchmove);

            $ngNavListener.off(...touchend);
        }

        function handleGesture(event) {
            const dimensions = document.body.getBoundingClientRect();
            const ratio_horizontal = (touchendX - touchstartX) / dimensions.width;
            const ratio_vertical = (touchendY - touchstartY) / dimensions.height;
            // Left (for right action)
            if (ratio_horizontal < ratio_vertical && ratio_horizontal < -0.25) {
                if (typeof left === 'function') {
                    left();
                }
            }
            // Right (for left action)
            if (ratio_horizontal > ratio_vertical && ratio_horizontal > 0.25) {
                if (typeof right === 'function') {
                    right();
                }
            }
            // Swipe Down
            if (ratio_vertical > ratio_horizontal && ratio_vertical > 0.25) {
                if (typeof down === 'function') {
                    down();
                }
                else {
                    event.stopPropagation();
                }
            }
            // Swipe Up
            if (ratio_vertical < ratio_horizontal && ratio_vertical < -0.25) {
                if (typeof up === 'function') {
                    up();
                }
                else {
                    event.stopPropagation();
                }
            }
            // Tap
            if (touchendY === touchstartY) {
                // event.preventDefault();
            }
            // event.preventDefault();
        }
        return $ngNavListener;
    }
}
