import { Component, OnInit } from '@angular/core';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {FormBuilder} from '@angular/forms';
import {DataChannelName, DataService} from '../../../services/data/data.service';
import {instrumentNames} from '../../../services/sound/sound.service.instruments';

@Component({
  selector: 'app-pro-form',
  templateUrl: './pro-form.component.html',
  styleUrls: ['./pro-form.component.scss', '../sidenav-base/sidenav-base.component.scss']
})
export class ProFormComponent extends SidenavBaseComponent implements OnInit {

  public playInstruments = instrumentNames();

  constructor(
      formBuilder: FormBuilder,
      dataService: DataService
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
    this.subscribeToChannel(DataChannelName.APP_PRO_FORM);
  }

  private updateForm(): void {
  }


}
