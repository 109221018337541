import * as events from './events-module';
import * as music from './music-module';

let stateChangeFunc = (state) => {};

export interface State {
    index: number;
    naturalIndex: number;
    chordIndex: number;
    chordIntervals: number[];
    toggledIndexes: number;
    scaleFamilyIndex: number;
    modeIndex: number;
    midiToggledIndexes: number;
    isLeftHanded: boolean;
    isNutFlipped: boolean;
    fretboardLabelType: events.FretboardLabelType;
    circleIsCNoon: boolean;
    tuningIndex: number;
}

// default initial state
export const defaultState: State = {
    index: 3, // C
    naturalIndex: 3, // C
    chordIndex: -1, // no chord
    chordIntervals: [0, 2, 4], // standard triad
    toggledIndexes: 0, // index bitflag
    scaleFamilyIndex: 0, // diatornic
    modeIndex: 0, // major
    midiToggledIndexes: 0,
    isLeftHanded: false,
    isNutFlipped: false,
    fretboardLabelType: events.FretboardLabelType.NoteName,
    circleIsCNoon: true,
    tuningIndex: 0,
};

let current: State = {
    index: defaultState.index,
    naturalIndex: defaultState.naturalIndex,
    chordIndex: defaultState.chordIndex,
    chordIntervals: defaultState.chordIntervals,
    toggledIndexes: defaultState.toggledIndexes,
    scaleFamilyIndex: defaultState.scaleFamilyIndex,
    modeIndex: defaultState.modeIndex,
    midiToggledIndexes: defaultState.midiToggledIndexes,
    isLeftHanded: defaultState.isLeftHanded,
    isNutFlipped: defaultState.isNutFlipped,
    fretboardLabelType: defaultState.fretboardLabelType,
    circleIsCNoon: defaultState.circleIsCNoon,
    tuningIndex: defaultState.tuningIndex,
};

export function init(): void {

    /*
    try{
        const cookieState = cookies.readCookie2();
        if (cookieState !== null) {
            current = cookieState;
        }
    }
    catch (e) {
        // ignore the invalid cookie:
    }
     */

    // lets remember this while we reset everything.
    const tempChordIndex = current.chordIndex;
    const tempToggledIndexes = current.toggledIndexes;

    const scaleFamily = music.scaleFamily.find(x => x.index === current.scaleFamilyIndex);
    if (!scaleFamily) {
        throw new Error('scaleFamily is ' + scaleFamily + ', current.scaleFamilyIndex = ' + current.scaleFamilyIndex);
    }
    const mode = scaleFamily.modes.find(x => x.index === current.modeIndex);
    if (!mode) {
        throw new Error('mode is ' + mode + 'current.modeIndex' + current.modeIndex);
    }

    // publish scale and mode
    events.scaleFamilyChange.publish({ scaleFamily });
    events.modeChange.publish({ mode });
    events.chordIntervalChange.publish( { chordIntervals: current.chordIntervals });

    // subscriptions
    events.tonicChange.subscribe(tonicChanged);
    events.modeChange.subscribe(modeChanged);
    events.chordChange.subscribe(chordChanged);
    events.toggle.subscribe(toggle);
    events.chordIntervalChange.subscribe(chordIntervalChanged);
    events.scaleFamilyChange.subscribe(scaleFamilyChanged);
    events.midiNote.subscribe(midiNote);

    // publish tonic and chord
    events.tonicChange.publish({ noteSpec: music.createNoteSpec(current.naturalIndex, current.index) });
    events.chordChange.publish({ chordIndex: tempChordIndex });
    // restore toggles
    current.toggledIndexes = tempToggledIndexes;
    updateScale();

    // publish settings
    events.leftHandedChange.publish({ isLeftHanded: current.isLeftHanded });
    events.flipNutChange.publish( { isNutFlipped: current.isNutFlipped });
    events.fretboardLabelChange.publish({ labelType: current.fretboardLabelType });
    events.setCToNoon.publish({ isC: current.circleIsCNoon });
    events.tuningChange.publish({ index: current.tuningIndex });

    // subscribe to settings changes
    events.leftHandedChange.subscribe(leftHandedChange);
    events.flipNutChange.subscribe(flipNutChange);
    events.fretboardLabelChange.subscribe(fretboardLabelChange);
    events.setCToNoon.subscribe(setCToNoon);
    events.tuningChange.subscribe(tuningChange);
}

export function getCurrentState(): State {
    return current;
}

function tonicChanged(tonicChangedEvent: events.TonicChangedEvent): void {
    current.index = tonicChangedEvent.noteSpec.index;
    current.naturalIndex = tonicChangedEvent.noteSpec.natural.index;
    current.chordIndex = -1;
    updateScale();
    notifyStateChange();
}

function modeChanged(modeChangedEvent: events.ModeChangedEvent): void {
    current.modeIndex = modeChangedEvent.mode.index;
    current.chordIndex = -1;
    updateScale();
    notifyStateChange();
}

function chordChanged(chordChangedEvent: events.ChordChangeEvent): void {
    if (chordChangedEvent.chordIndex === current.chordIndex) {
        current.chordIndex = -1;
    }
    else {
        current.chordIndex = chordChangedEvent.chordIndex;
    }
    current.toggledIndexes = 0;
    updateScale();
    notifyStateChange();
}

function toggle(toggleEvent: events.ToggleEvent): void {
    // tslint:disable-next-line:no-bitwise
    current.toggledIndexes = current.toggledIndexes ^ 2 ** toggleEvent.index;
    updateScale();
    notifyStateChange();
}

function chordIntervalChanged(chordIntervalChangedEvent: events.ChordIntervalChangeEvent): void {
    current.chordIntervals = chordIntervalChangedEvent.chordIntervals;
    current.toggledIndexes = 0;
    updateScale();
    notifyStateChange();
}

function scaleFamilyChanged(scaleFamilyChangedEvent: events.ScaleFamilyChangeEvent): void {
    current.scaleFamilyIndex = scaleFamilyChangedEvent.scaleFamily.index;
    current.modeIndex = scaleFamilyChangedEvent.scaleFamily.defaultModeIndex;
    current.chordIndex = -1;
    updateScale();
    notifyStateChange();
}

function midiNote(midiNoteEvent: events.MidiNoteEvent): void {
    current.midiToggledIndexes = midiNoteEvent.toggledIndexes;
    updateScale();
    notifyStateChange();
}

// setttings event handlers

function leftHandedChange(leftHandedChangeEvent: events.LeftHandedFretboardEvent): void {
    current.isLeftHanded = leftHandedChangeEvent.isLeftHanded;
    publishStateChange();
    notifyStateChange();
}

function flipNutChange(flipNutChangeEvent: events.FlipNutEvent): void {
    current.isNutFlipped = flipNutChangeEvent.isNutFlipped;
    publishStateChange();
    notifyStateChange();
}

function fretboardLabelChange(fretboardLabelChangeEvent: events.FretboardLabelChangeEvent): void {
    current.fretboardLabelType = fretboardLabelChangeEvent.labelType;
    publishStateChange();
    notifyStateChange();
}

function setCToNoon(setCToNoonEvent: events.SetCToNoonEvent): void {
    current.circleIsCNoon = setCToNoonEvent.isC;
    publishStateChange();
    notifyStateChange();
}

function tuningChange(tuningChangedEvent: events.TuningChangedEvent): void {
    current.tuningIndex = tuningChangedEvent.index;
    publishStateChange();
    notifyStateChange();
}

function updateScale(doUpdate = true): void {

    const scaleFamily = music.scaleFamily.find(x => x.index === current.scaleFamilyIndex);
    if (!scaleFamily) {
        throw new Error('scaleFamily is ' + scaleFamily + ', current.scaleFamilyIndex = ' + current.scaleFamilyIndex);
    }
    const mode = scaleFamily.modes.find(x => x.index === current.modeIndex);
    if (!mode) {
        throw new Error('mode is ' + mode + 'current.modeIndex' + current.modeIndex);
    }
    const noteSpec = music.createNoteSpec(current.naturalIndex, current.index);

    const nodes = music.generateScaleShim(
        noteSpec,
        mode,
        current.chordIndex,
        current.chordIntervals,
        current.toggledIndexes,
        current.midiToggledIndexes,
        scaleFamily);

    // update togges, because a chord may have been generated.
    current.toggledIndexes = nodes
        .filter(x => x.toggle)
        .map(x => x.scaleNote.note.index)
        .reduce((a, b) => a + 2 ** b, 0);

    events.scaleChange.publish({
        nodes,
        mode
    });

    if (doUpdate) {
        publishStateChange();
    }
}

function publishStateChange(): void {
    events.stateChange.publish({
        state: current
    });
}

function publishNewSettings(): void {
    const tempChordIndex = current.chordIndex;
    const tempToggledIndexes = current.toggledIndexes;
    const scaleFamily = music.scaleFamily.find(x => x.index === current.scaleFamilyIndex);
    if (!scaleFamily) {
        throw new Error('scaleFamily is ' + scaleFamily + ', current.scaleFamilyIndex = ' + current.scaleFamilyIndex);
    }
    const mode = scaleFamily.modes.find(x => x.index === current.modeIndex);
    if (!mode) {
        throw new Error('mode is ' + mode + 'current.modeIndex' + current.modeIndex);
    }
    // publish scale and mode
    events.scaleFamilyChange.publish({ scaleFamily });
    events.modeChange.publish({ mode });
    events.chordIntervalChange.publish( { chordIntervals: current.chordIntervals });

    // publish tonic and chord
    events.tonicChange.publish({ noteSpec: music.createNoteSpec(current.naturalIndex, current.index) });
    events.chordChange.publish({ chordIndex: tempChordIndex });
    // restore toggles
    current.toggledIndexes = tempToggledIndexes;
    updateScale(false);

    // publish settings
    events.leftHandedChange.publish({ isLeftHanded: current.isLeftHanded });
    events.flipNutChange.publish( { isNutFlipped: current.isNutFlipped });
    events.fretboardLabelChange.publish({ labelType: current.fretboardLabelType });
    events.setCToNoon.publish({ isC: current.circleIsCNoon });
    events.tuningChange.publish({ index: current.tuningIndex });
}

function notifyStateChange(): void {
   stateChangeFunc(current);
}

function deepEqual(object1, object2): boolean {
    function isObject(object): boolean {
        return object != null && typeof object === 'object';
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }
    return true;
}

export function setNewState(newState: State): void {
    if (!deepEqual(current, newState)) {
        current = newState;
        publishNewSettings();
    }
}

export function subscribeToUserChanges(changeFunc): void {
    stateChangeFunc = changeFunc;
}
