import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export interface Presence {
  id: string;
  _metadata: Firestore;
  userId: string;
  location: string;
}

@Injectable({
  providedIn: 'root'
})

export class PresenceService extends FirestoreService {

  TIMEOUT = 3600;

  constructor(
      public authService: AuthService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'presence';
  }

  getAllByLocation(location: string, itemId: string, resultFunc: (array) => void): void {
    this.db.collection(this.collectionName).where('location', '==', location).where('itemId', '==', itemId)
        .onSnapshot(querySnapshot => Promise.all(querySnapshot.docs.map(async doc => {
          const data = doc.data();
          return await this.profileService.getUserProfile(data._metadata.ownerId);
        })).then(result => resultFunc(result)));
  }

  set(location: string, itemId: string): Promise<string | null> {
    const data = {
      location,
      itemId
    };
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  clear(): Promise<any> {
    const userId = this.authService.getUserId();
    if (!userId) {
      return Promise.resolve();
    }
    return this.db.collection(this.collectionName).
     where('_metadata.ownerId', '==', userId).get().then(querySnapshot =>
      Promise.all(querySnapshot.docs.map(async doc => await this.db.collection(this.collectionName).doc(doc.id).delete())));
  }

  /*
  private clearExpired(location: string): Promise<any> {
    const date = new Date();
    console.log('clearExpired')
    date.setSeconds(date.getSeconds() - this.TIMEOUT);
    return this.db.collection(this.collectionName).where('location', '==', location).
    where('_metadata.createdAt', '<', Timestamp.fromDate(date)).get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        return this.db.collection(this.collectionName).doc(doc.id).delete();
      });
    });
  }
  */

}

