import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderState} from './loader';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private $disabled = false;
    public loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    get disabled(): boolean {
        return this.$disabled;
    }

    private $initComplete = false;
    public get initComplete(): boolean {
        return this.$initComplete;
    }
    public setInitComplete(initComplete: boolean): void {
        this.$initComplete = initComplete;
    }

    constructor() { }

    show(): void {
        this.loaderSubject.next({ show: true } as LoaderState);
        this.$disabled = false;
    }

    hide(): void {
        this.loaderSubject.next({ show: false } as LoaderState);
        this.$disabled = true;
    }

}
