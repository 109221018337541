import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LeftMenu} from '../../app.component';
import {ReusePageBaseComponent} from '../reusepagebase/reusepagebase.component';
import {CryptoService} from '../../services/crypto/crypto.service';

@Component({
  selector: 'app-best',
  templateUrl: './best.component.html',
  styleUrls: ['./best.component.scss']
})
export class BestComponent extends ReusePageBaseComponent implements OnInit {

  public tiles = {
    title: 'The Modes of DADGAD Tuning',
    sections: [
      {
        title: 'Modes in D',
        items: [
          { title: 'D Ionian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'D', rootKey: 'D', mode: 'Ionian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/2004-K65CE-L7-Tropical-Vine-Inlay-20041018132-front.png' },
          { title: 'D Dorian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'D', rootKey: 'D', mode: 'Dorian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Dragonfly fretless.png' },
          { title: 'D Phyrgian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'D', rootKey: 'D', mode: 'Phrygian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/DWHOUNDLX-xlarge.png' },
          { title: 'D Mixolydian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'D', rootKey: 'D', mode: 'Mixolydian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/evertune__guitars__BC_Rich_Mockingbird_Extreme_Exotic_cyan_blue__INDEX_v1.png' },
          { title: 'D Aeolian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'D', rootKey: 'D', mode: 'Natural Minor', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Kremona Fiesta.png' }
        ]
      },
      {
        title: 'Modes in A',
        items: [
          { title: 'A Dorian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'A', rootKey: 'A', mode: 'Dorian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Lee SIgnature SSS Electric Guitar.png' },
          { title: 'A Phyrgian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'A', rootKey: 'A', mode: 'Phrygian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Oral-History-Rickenbacker-1963-BB-Hero@1400x560.png' },
          { title: 'A Mixolydian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'A', rootKey: 'A', mode: 'Mixolydian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Ovation double neck.png' },
          { title: 'A Aeolian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'A', rootKey: 'A', mode: 'Natural Minor', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Takamine acoustic.png' },
          { title: 'A Locrian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'A', rootKey: 'A', mode: 'Locrian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/retrofret vintage.png' }
        ]
      },
      {
        title: 'Modes in G',
        items: [
          { title: 'G Ionian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'G', rootKey: 'G', mode: 'Ionian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Taylor Nylon Acoustic.png' },
          { title: 'G Dorian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'G', rootKey: 'G', mode: 'Dorian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Taylor 414ce-R.png' },
          // tslint:disable-next-line:max-line-length
          { title: 'G Lydian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'G', rootKey: 'G', mode: 'Lydian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/traben-array-limited-5-l.png' },
          { title: 'G Mixolydian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'G', rootKey: 'G', mode: 'Mixolydian', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/Vox Starstream.png' },
          { title: 'G Aeolian', tooltip: '', linkUrl: `charts/${this.cryptoService.encodeJSON({focusNote: 'G', rootKey: 'G', mode: 'Natural Minor', tuning: 'DADGAD'})}`, contents: '', imageUrl: 'assets/instruments/X20-12 Artisan.png' }
        ]
      }
    ]
  };

  private instrument;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('best', LeftMenu.BEST);
    this.route.params.subscribe(params => {
      this.instrument = params.instrument;
    });
  }

  tileClicked(tile): void {
    this.router.navigate([tile.linkUrl]);
  }

}
