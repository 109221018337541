import {AfterViewInit, Component, Inject, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Collection} from '../../../services/firestore/collections/collections.service';
import {MatSort} from '@angular/material/sort';
import {Annotation, AnnotationsService, AnnotationType, ParentType} from '../../../services/firestore/annotations/annotations.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddDialogComponent, AddDialogModel} from '../adddialog/adddialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RecordAudioDialogComponent, RecordAudioDialogModel} from '../recordaudiodialog/recordaudiodialog.component';
import {RecordMIDIDialogComponent, RecordMIDIDialogModel} from '../recordmididialog/recordmididialog.component';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../confirmdialog/confirmdialog.component';
import {AudioPlayerComponent} from 'ngx-audio-player';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-annotationsdialog',
  templateUrl: './annotationsdialog.component.html',
  styleUrls: ['./annotationsdialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AnnotationsDialogComponent implements OnInit, AfterViewInit {

  @Input() data;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('audioPlayer') audioPlayer: AudioPlayerComponent;

  public items = new MatTableDataSource<Collection>();
  public columnsToDisplay = ['type', '_metadata.createdAt', 'owner', 'actionmenu' ];

  constructor(
      private annotationsService: AnnotationsService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private logService: LogService,
  @Optional() public dialogRef: MatDialogRef<AnnotationsDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.annotationsService.getMergedCollections(annotations => {
      this.items.data = annotations;
    }, this.data.parentId, this.data.parentType);
    this.items.sortingDataAccessor = (item, property) => {
      switch (property) {
        case '_metadata.createdAt': return item._metadata.createdAt;
        default: return item[property];
      }
    };
    this.items.sort = this.sort;
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  onTrackEnded(event): void {
    this.audioPlayer.resetSong();
  }

  canEdit(item: Annotation): boolean {
    return this.annotationsService.canEdit(item);
  }

  copyItem(item: Annotation): void {
    this.annotationsService.create(item).then(() => {
      this.snackBar.open('Annotation copied');
    });
  }

  deleteItem(item: Annotation): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm Delete', `Are you sure you want to delete this annotation?`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.annotationsService.delete(item.id).then(() => {
          this.snackBar.open('Item deleted');
        });
      }
    });
  }

  editItem(item: Annotation): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: new AddDialogModel(true, 'Edit Item', `Change the contents and attributes of the annotation.`,
          [
            {type: 'textarea', value: item.contents, controlName: 'contents', label: 'Contents', required: true },
            {type: 'checkbox', value: item.private, controlName: 'private', label: 'Private', required: false }
          ]
      )
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.annotationsService.update(item.id, dialogResult).then(() => {
          this.snackBar.open('Annotation updated');
        });
      }
    });
  }

  addMidiAnnotation(): void {
    const dialogRef = this.dialog.open(RecordMIDIDialogComponent, {
      data: new RecordMIDIDialogModel( this.data.parentId + '.mp3')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.snackBar.open('MIDI annotation added');
      }
    });
  }

  addAudioAnnotation(): void {
    const dialogRef = this.dialog.open(RecordAudioDialogComponent, {
      data: new RecordAudioDialogModel( this.data.message, false)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const annotation = {
          parentType: this.data.parentType,
          type: AnnotationType.Audio,
          parentId: this.data.parentId,
          contents: dialogResult.url,
          private: dialogResult.isPrivate
        };
        this.annotationsService.create(annotation).then(() => {
          this.snackBar.open('Audio Annotation added');
        });
      }
    });
  }

  addAnnotation(): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: new AddDialogModel(false, 'Add New Annotation', `Enter the contents and attributes of the annotation you wish to create.`,
          [
            {type: 'textarea', value: '', controlName: 'contents', label: 'Contents', required: true },
            {type: 'checkbox', value: false, controlName: 'private', label: 'Private', required: false }
          ]
      )
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        dialogResult.parentType = this.data.parentType;
        dialogResult.parentId = this.data.parentId;
        dialogResult.type = AnnotationType.Text;
        this.annotationsService.create(dialogResult).then(() => {
          this.snackBar.open('Annotation added');
        });
      }
    });
  }

}

export class AnnotationsDialogModel {
  constructor(public message: string, public parentType: ParentType, public parentId: string) {
  }
}
