import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {RemoteConfigService} from '../../services/firestore/remoteconfig/remoteconfig.service';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

  protected selectedApp;

  private apps = [
    { description: 'Best Chord Charts', title: 'Be', color: '#34acdd', linkUrl: '/best/guitar'},
    { description: 'Keyboard GPS', title: 'Kb', color: '#5776b6', linkUrl: '/charts/keyboard' },
    { description: 'Fretboard GPS', title: 'Fb', color: '#5f3817', linkUrl: '/charts'},
    { description: 'Tuning GPS', title: 'Tu', color: '#085d24', linkUrl: '/instruments'},
    { description: 'Scale GPS', title: 'Sc', color: '#0d014b', linkUrl: '/scales'},
    { description: 'Chord GPS', title: 'Ch', color: '#ab9f1d', linkUrl: '/chords'},
//    { description: 'PRO Instrument', title: 'P', titlesecondletter: 'i', color: '#bd0347', linkUrl: '/pro'},
    { description: 'Progressions', title: 'Pr', color: '#b01f26', linkUrl: '/progressions'},
    { description: 'Compose', title: 'Co', color: '#009aa5', linkUrl: '/compose'}
  ];

  public activeApps = this.apps;

  constructor(
      private router: Router,
      private remoteConfigService: RemoteConfigService
  ) { }

  ngOnInit(): void {
    const info = this.remoteConfigService.getDomainInfo();
    if (info) {
      this.activeApps = this.apps.filter(app => info.showApps.includes(app.title));
    }
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.selectedApp = this.apps.find(app => {
          const linkUrlParts = app.linkUrl.split('/');
          let matches =  linkUrlParts[1] === e.urlAfterRedirects.split('/')[1];
          if (matches && linkUrlParts.length > 2) {
            matches = linkUrlParts[2] === e.urlAfterRedirects.split('/')[2];
          }
          return matches;
        });
      }
    });
  }

  appClicked(newApp): void {
    this.router.navigate([newApp.linkUrl], {...newApp.params} );
  }

}
