import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';

export interface Collection {
  id: string;
  _metadata: Firestore;
  name: string;
  parentId: string;
  private: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class CollectionsService extends FirestoreService {

  constructor(
      public authService: AuthService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'containers';
  }

  get(id: string): Promise<Collection | boolean> {
    return this.docFromCollection(id);
  }

  getAll(resultFunc: (array) => void): void {
    this.getCollectionByUser(this.authService.getUserInfo().uid, this.addOwners(this.profileService, resultFunc));
  }

  getPublic(resultFunc): void {
    this.db.collection(this.collectionName).where('_metadata.ownerId', '!=', this.authService.getUserInfo().uid)
    // tslint:disable-next-line:max-line-length
        .where('private', '==', false).onSnapshot(querySnapshot => this.addOwners(this.profileService, resultFunc)(querySnapshot.docs.map(doc =>  {
          const data = doc.data(); data.id = doc.id; return data; })));
  }

  getMergedCollections(resultFunc): void {
    let myCollections = [];
    let publicCollections = [];
    function mergeCollections(): void {
      resultFunc([...myCollections, ...publicCollections]);
    }
    this.getAll(items => {
      myCollections = items;
      mergeCollections();
    });
    this.getPublic(items => {
      publicCollections = items;
      mergeCollections();
    });
  }

  create(data): Promise<string | null> {
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  update(id, data): Promise<boolean> {
    return this.updateDocument(id, data);
  }

  delete(id): Promise<boolean> {
    return this.deleteDocument(id);
  }

  canEdit(collection: Collection): boolean {
    return collection._metadata.ownerId === this.authService.getUserId();
  }

}
