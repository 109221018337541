import {Component, OnInit, OnDestroy, ViewEncapsulation, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {LoaderService} from '../loader/loader.service';
import {LoaderState} from '../loader/loader';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-idx-mat-icon',
    templateUrl: './idx-mat-icon.component.html',
    styleUrls: ['./idx-mat-icon.component.scss'],
    providers: [MatIconRegistry],
    encapsulation: ViewEncapsulation.None
})
export class IdxMatIconComponent implements OnInit, OnDestroy {

    @Input() icon: string|null = null;
    @Input() path: string|null = null;
    show = false;
    private subscription: Subscription;

    constructor(private iconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer,
                private loaderService: LoaderService) {}

    ngOnInit(): void {
        const $this = this;
        if ($this.icon) {
            $this.iconRegistry.addSvgIconInNamespace('idxicon',
                $this.icon,
                $this.sanitizer.bypassSecurityTrustResourceUrl('//' + environment.siteUrl + $this.path + $this.icon + '.svg'));
        }
        $this.subscription = $this.loaderService.loaderState.subscribe((state: LoaderState) => {
            if (state.show) {
                $this.show = state.show;
            } else {
                setTimeout(() => {
                    $this.show = state.show;
                }, 1300);
            }
        });
    }

    ngOnDestroy(): void {
        const $this = this;
        $this.subscription.unsubscribe();
    }
}
