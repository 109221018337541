import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CollectionsService} from '../../../services/firestore/collections/collections.service';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-collectiondialog',
  templateUrl: './collectiondialog.component.html',
  styleUrls: ['./collectiondialog.component.scss']
})
export class CollectionDialogComponent implements OnInit {

  public items;
  public selectedItem;
  message: string;

  constructor(
      private collectionsService: CollectionsService,
      public dialogRef: MatDialogRef<CollectionDialogComponent>,
      private logService: LogService,
  @Inject(MAT_DIALOG_DATA) public data: CollectionDialogModel)
  {
    this.message = data.message;
  }

  ngOnInit(): void {
    this.collectionsService.getMergedCollections(items => {
      this.items = items;
    });
  }

  onChoose(): void {
    this.dialogRef.close(this.selectedItem.id);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  itemClicked(item): void {
    this.selectedItem = item;
  }

}

export class CollectionDialogModel {
  constructor(public message: string) {
  }
}
