import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatTabGroup} from '@angular/material/tabs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import * as music from '../../gd/src/music-module';

@Component({
  selector: 'app-noteselector',
  templateUrl: './noteselector.component.html',
  styleUrls: ['./noteselector.component.scss']
})
export class NoteselectorComponent implements OnInit {

  @Input() placeHolder;
  @Input() notes = [];
  @Output() notesChangedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('noteInput', {static: false}) noteInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  public keys = music.noteNames;

  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  noteCtrl = new FormControl();
  filteredNotes: Observable<string[]>;
  allNotes: string[] = this.keys.map(note => note.name);

  constructor() {
    this.filteredNotes = this.noteCtrl.valueChanges.pipe(
        startWith(null),
        map((note: string | null) => note ? this._filter(note) : this.allNotes.slice()));
  }

  ngOnInit(): void {
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.notes.push(event.option.viewValue);
    this.indicateChange();
    this.noteInput.nativeElement.value = '';
    this.noteCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allNotes.filter(note => note.toLowerCase().indexOf(filterValue) === 0);
  }

  indicateChange(): void {
    this.notesChangedEvent.emit(this.notes);
  }

  add(event: MatChipInputEvent): void {
    // Add note only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.notes.push(value.trim());
        this.indicateChange();
      }
      if (input) {
        input.value = '';
      }
      this.noteCtrl.setValue(null);
    }
  }

  remove(note: string): void {
    const index = this.notes.indexOf(note);

    if (index >= 0) {
      this.notes.splice(index, 1);
      this.indicateChange();
    }
  }

}
