import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import Vex from 'vexflow';

@Component({
  selector: 'app-chord-stave',
  templateUrl: './chord-stave.component.html',
  styleUrls: ['./chord-stave.component.scss']
})
export class ChordStaveComponent implements OnInit, AfterViewInit {

  DIAGRAM_HEIGHT = 180;

  @Input() item;
  @ViewChild('stave') stave;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.showChordOnStave(this.item.notes);
  }

  private showChordOnStave(notes): void {
    const previous = this.stave.nativeElement.firstChild;
    if (previous) {
      previous.remove();
    }
    const vf = new Vex.Flow.Factory({renderer: {elementId: this.stave.nativeElement, width: 150, height: this.DIAGRAM_HEIGHT}});
    const score = vf.EasyScore();
    const system = vf.System();
    const scoreNotes = `(${notes.join(' ')})/1`;
    system.addStave({
      voices: [score.voice(score.notes(scoreNotes))]
    }).addClef('treble');
    vf.draw();
  }

}
