import {Injectable} from '@angular/core';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {LogContext, LogType, LogService} from '../firestore/log/log.service';
import {ProfileService} from '../firestore/profile/profile.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static loginObservable = new BehaviorSubject(false);
  public static ui;
  private startPath;

  constructor(
      private router: Router,
      // private logService: LogService
  ) {
    const firebaseConfig = {
      apiKey: 'AIzaSyD83-66XFgVmS_0tvXfNOH8uF6mPNyCHd8',
      authDomain: 'bestchordcharts-d93b6.firebaseapp.com',
      databaseURL: 'https://bestchordcharts-d93b6.firebaseio.com',
      projectId: 'bestchordcharts-d93b6',
      storageBucket: 'bestchordcharts-d93b6.appspot.com',
      messagingSenderId: '198724788330',
      appId: '1:198724788330:web:a6d9758441d86d90ba6a13',
      measurementId: 'G-XVQM2GJEGJ'
    };
    firebase.initializeApp(firebaseConfig);
  }

  setInitialPath(): void {
    const initialPath = window.location.pathname + window.location.search;
    this.startPath = initialPath && initialPath !== '/' ? initialPath : '/best/guitar'/*'/dashboard'*/;
  }

  getInitialPath(): string {
    return this.startPath ? this.startPath : '/best/guitar';
  }

  showSignin(element): void {
    const $this = this;
    const uiConfig = {
      signInFlow: 'popup',
      callbacks: {
        signInSuccessWithAuthResult: () => {
          // this.logService.write(LogType.Auth, LogContext.Login, this.getUserInfo());
          console.log('SIGNIN SUCCESS');
          $this.router.navigateByUrl(this.getInitialPath());
        },
      },
        signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      tosUrl: '/tos',
      privacyPolicyUrl: () => {
        window.location.assign('/privacy');
      }
    };
    if (!AuthService.ui) {
      AuthService.ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    AuthService.ui.start(element, uiConfig);
  }

  isLoggedIn(): boolean {
    return AuthService.loginObservable.getValue();
  }

  getUserInfo(): firebase.User | null {
    return firebase.auth().currentUser || {} as firebase.User;
  }

  getUserId(): string {
    return this.getUserInfo().uid;
  }

}
