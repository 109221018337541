import {trigger, transition, style, query, group, animateChild, animate} from '@angular/animations';

export const appRoutingAnimations =
    trigger('routeAnimations', [
        transition('* <=> FilterPage', [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ], {optional: true}),
            query(':enter', [
                style({ left: '-100%' })
            ], {optional: true}),
            query(':leave', animateChild(),
                {optional: true}),
            group([
                query(':leave', [
                    animate('200ms ease-out', style({ left: '100%' }))
                ], {optional: true}),
                query(':enter', [
                    animate('300ms ease-out', style({ left: '0%' }))
                ], {optional: true})
            ]),
            query(':enter', animateChild(),
                {optional: true}),
        ])
    ]);

/*
if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker('./app.worker', { type: 'module' });
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}

 */
