import {AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ChordType, chordTypes} from '../../gd/src/chord-module';
import {chordParserFactory, chordRendererFactory} from 'chord-symbol';
import * as music from '../../gd/src/music-module';
import {AnnotationsDialogComponent} from '../dialogs/annotationsdialog/annotationsdialog.component';
import {ParentType} from '../../services/firestore/annotations/annotations.service';
import {CollectionDialogComponent, CollectionDialogModel} from '../dialogs/collectiondialog/collectiondialog.component';
import {SoundService} from '../../services/sound/sound.service';
import {FormBuilder} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {UtilsService} from '../../services/utils/utils.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as tuning from '../../gd/src/tuning-module';
import {MatDialog} from '@angular/material/dialog';
import {CollectionItemsService} from '../../services/firestore/collectionitems/collectionitems.service';
import {SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import {noteNames} from '../../gd/src/music-module';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {fixSharpsAndFlatsReverse, modulo, rotate} from '../../services/best/best.service.utils';
import {CryptoService} from '../../services/crypto/crypto.service';
import {FormbaseComponent} from '../formbase/formbase.component';

@Component({
  selector: 'app-chordlist',
  templateUrl: './chordlist.component.html',
  styleUrls: ['./chordlist.component.scss']
})
export class ChordlistComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() items = new MatTableDataSource<ChordType>();
  @ViewChild(MatSort) sort: MatSort;
  @Input() notes;
  @Input() change;
  @Output() menuItemSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  public columnsToDisplay = ['name', 'abbreviations', 'intervals', 'guitar', 'actionmenu' ];
  public inversions = music.inversions;
  public tunings = tuning.tuningInfos;
  public keys = music.noteNames;

  private parseChord;
  private renderChord;
  private currentPage = 0;
  public pageSize = 25;
  public allItems = [];

  public tuningIndex = 0;
  public keyIndex = 0;
  public form;

  constructor(
      private soundService: SoundService,
      private formBuilder: FormBuilder,
      private utilsService: UtilsService,
      private router: Router,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private dataService: DataService,
      private collectionItemsService: CollectionItemsService,
      private cryptoService: CryptoService,
  ) {
  }

  ngOnInit(): void {
    this.parseChord = chordParserFactory();
    this.renderChord = chordRendererFactory({ useShortNamings: true });

    const searchForm = {
      inversion: this.formBuilder.control({value: 0, disabled: false}),
      tuning: this.formBuilder.control({value: 0, disabled: false}),
    };
    if (!this.notes) {
      searchForm['key'] = this.formBuilder.control({value: 0, disabled: false});
    }
    this.form = this.formBuilder.group(searchForm);
    if (!this.notes) {
      this.form.get('key').valueChanges.subscribe((nv) => {
        this.keyIndex = nv;
        this.form.get('inversion').setValue(0);
        this.drawItems();
      });
    }
    this.form.get('inversion').valueChanges.subscribe((nv) => {
      this.drawItems();
    });
    this.form.get('tuning').valueChanges.subscribe((nv) => {
      this.tuningIndex = nv;
      this.drawItems();
    });
  }

  ngAfterViewInit(): void {
    this.items.sort = this.sort;
    this.drawItems();
    console.log('SEND APP_CHORD_FORM')
    this.dataService.send(DataChannelName.APP_CHORD_FORM, {
      form: this.form,
      data: {
        keys: this.keys,
        inversions: this.inversions,
        tunings: this.tunings
      }
    });
  }

  ngOnChanges(): void {
    this.drawItems();
  }

  drawFromNotes(): void {
    const workData = [];
    const enteredNoteOffsets = this.notes.map(note => noteNames.find(noteName => noteName.name === note).index);
    if (enteredNoteOffsets.length >= 2) {
      chordTypes.forEach((chord, chordIndex) => {
        for (let offset = 0; offset < SEMITONES_IN_OCTAVE; offset++) {
          const workChord = Object.assign({}, chord);
          let allNotesMatch = true;
          enteredNoteOffsets.forEach(noteOffset => {
            if (!chord.intervals.itemAt(modulo(noteOffset - offset, SEMITONES_IN_OCTAVE))) {
              allNotesMatch = false;
            }
          })
          if (allNotesMatch) {
            workData.push(workChord);
            this.addInfoToChord(workChord, sharpOctave[offset]);
            workChord.name = `${sharpOctave[offset]} ${workChord.name}`;
          }
        }
      });
    }
    this.allItems = workData;
    this.setPageSlice();
  }

  drawItems(): void {
    this.currentPage = 0;
    if (this.notes) {
      this.drawFromNotes();
      return;
    }
    this.allItems = chordTypes;
    this.setPageSlice();
    const rootKey = this.keys[this.form.get('key').value];
    chordTypes.forEach((chord, chordIndex) => {
      this.addInfoToChord(chord, rootKey.name);
    });
  }

  pageEvent(event): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setPageSlice();
  }

  private setPageSlice(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.allItems.slice(start, end);
    this.items.data = part;
  }

  private addInfoToChord(chord, rootKeyName): void {
    const chordAbbreviation = rootKeyName + chord.abbreviations[0];
    const newChord = this.parseChord(chordAbbreviation);
    let notes = rotate(newChord.normalized.notes, this.form.get('inversion').value, 0);
    const semitones = rotate(newChord.normalized.semitones, this.form.get('inversion').value, 12);
    const rootKeySemitoneOffset = modulo(music.noteNames.find(note => note.name === rootKeyName).index - 3, 12);
    notes = notes.map((note, index) => {
      const semitoneOffset = rootKeySemitoneOffset + semitones[index];
      return note + (semitoneOffset >= 12 ? (semitoneOffset >= 24 ? '6' : '5') : '4');
    });
    chord.notes = notes;
    chord.rootKeyName = rootKeyName;
  }

  showCharts(chord): void {
    this.router.navigate(['charts', this.cryptoService.encodeJSON({chord: chord.name})]);
  }

  showAnnotations(chord: ChordType): void {
    const dialog = this.dialog.open(AnnotationsDialogComponent);
    dialog.componentInstance.data = { modal: true, message: chord.name, parentType: ParentType.Chord };
  }

  addToCollection(chord: ChordType): void {
    const dialogRef = this.dialog.open(CollectionDialogComponent, {
      data: new CollectionDialogModel(`Choose a collection to save your chord to.`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const itemInfo = {
          type: ParentType.Chord,
          name: chord.name,
          details: {
            name: chord.intervals.toArray().join('')
          },
          order: -1
        };
        this.collectionItemsService.create(dialogResult, itemInfo).then(item => {
          this.snackBar.open('Chord added to collection');
        });
      }
    });
  }

  playChord(item: ChordType): void {
    this.soundService.play(item.notes);
  }

  showRelatedChords(item: ChordType): void {
    this.menuItemSelectedEvent.emit({type: 'related', notes: item.notes.map(note => fixSharpsAndFlatsReverse(note.slice(0, -1)))});
  }

}
