import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../../components/loader/loader.service';
import {tap} from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
    public state: Observable<string>;

    constructor(
        private loaderService: LoaderService
    ) {  }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<any> {
        const $this = this;
        $this.showLoader();
        return this.passRequest(req, next);
    }

    private passRequest(req, next): Observable<any> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.hideLoader();
            }
        }, (err: any) => {
            this.hideLoader();
        }));
    }

    private showLoader(): void {
        setTimeout(() => {
            this.loaderService.show();
        });
    }

    private hideLoader(): void {
        setTimeout(() => {
            this.loaderService.hide();
        });
    }
}
