import { Component, OnInit } from '@angular/core';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-recordmididialog',
  templateUrl: './recordmididialog.component.html',
  styleUrls: ['./recordmididialog.component.scss']
})
export class RecordMIDIDialogComponent implements OnInit {

  constructor(
      private logService: LogService
  ) { }

  ngOnInit(): void {
  }

}

export class RecordMIDIDialogModel {
  constructor(
      public filename: string,
  ) {
  }
}
