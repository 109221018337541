import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {AnnotationsDialogComponent} from '../dialogs/annotationsdialog/annotationsdialog.component';
import {ParentType} from '../../services/firestore/annotations/annotations.service';
import {MatDialog} from '@angular/material/dialog';
import {TuningInfo, tuningInfos} from '../../gd/src/tuning-module';
import {CollectionDialogComponent, CollectionDialogModel} from '../dialogs/collectiondialog/collectiondialog.component';
import {CollectionItemsService} from '../../services/firestore/collectionitems/collectionitems.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TuningsDialogComponent, TuningsDialogModel} from '../dialogs/tuningsdialog/tuningsdialog.component';
import {LogContext, LogService, LogType} from '../../services/firestore/log/log.service';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {LeftMenu} from '../../app.component';
import * as music from '../../gd/src/music-module';
import {FormBuilder} from '@angular/forms';
import {deepCopy, fixSharpsAndFlatsReverse} from '../../services/best/best.service.utils';
import {removeOctave} from '../../services/best/best.service.utils';
import {MatTabGroup} from '@angular/material/tabs';
import {BestApiService} from '../../services/best/best.service.api';
import {HistoryService} from '../../services/firestore/history/history.service';
import {ReusePageBaseComponent} from '../reusepagebase/reusepagebase.component';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss']
})
export class InstrumentsComponent extends ReusePageBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('bestTuningsSort') bestTuningsSort: MatSort;
  @ViewChild('itemsSort') itemsSort: MatSort;
  @ViewChild('tabs') tabs: MatTabGroup;

  public items = new MatTableDataSource<TuningInfo>();
  public columnsToDisplay = ['tuning', 'description', 'actionsmenu' ];

  public bestTunings = new MatTableDataSource<TuningInfo>();
  public tuningColumnsToDisplay = ['tuning', 'n', 'actionsmenu'];

  public waiting = false;
  public currentTuning;
  public tuningCount;
  public title = 'Tuning';
  private bestKeysTitle;
  private tuningsTitle = 'Tuning';

  private keys = music.noteNames;
  private scales = music.scaleFamily;
  private form;
  private currentIndex;
  private looseMatch;
  public keysAndRootCount;
  public filter;
  public showBest;

  constructor(
      private dialog: MatDialog,
      private collectionItemsService: CollectionItemsService,
      private snackBar: MatSnackBar,
      private logService: LogService,
      private formBuilder: FormBuilder,
      private bestApiService: BestApiService,
      private historyService: HistoryService,
      injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('instruments', LeftMenu.INSTRUMENTS);
    this.items.data = tuningInfos;
    const searchForm = {
      instrument: this.formBuilder.control({value: 0, disabled: false}),
      scale: this.formBuilder.control({value: 0, disabled: false}),
      key: this.formBuilder.control({value: 0, disabled: false}),
      moderoot: this.formBuilder.control({value: 0, disabled: false}),
      match: this.formBuilder.control({value: 0, disabled: false})
    };
    this.form = this.formBuilder.group(searchForm);
    this.form.get('match').valueChanges.subscribe(value => {
      this.looseMatch = value;
    })
    this.form.valueChanges.subscribe(change => {
      this.buildTunings();
    });
    this.setForm(0);
    this.buildTunings();
  }

  ngAfterViewInit(): void {
    this.items.sort = this.itemsSort;
    this.bestTunings.sort = this.bestTuningsSort;
  }

  private buildTunings(): void {
    let scale = this.scales[this.form.get('scale').value];
    let root = music.noteNames[this.form.get('moderoot').value].name;
    let key = music.noteNames[this.form.get('key').value].name;
    if (this.currentIndex === 2) {

    }
    const table = tuningInfos.find(tuningInfo => tuningInfo.index === this.form.get('instrument').value).tableName;
    this.bestApiService.getTuningsByModeAndScale(table, key, root, scale.name, this.form.get('match').value).then(results => {
      this.bestTunings.data = results.map(result => {
        const strings = [];
        for (let workStrings = result.tuning; workStrings.length > 0;) {
          const stringLength = workStrings[1] === '♭' || workStrings[1] === '♯' ? 3 : 2;
          strings.push(workStrings.slice(0, stringLength));
          workStrings = workStrings.slice(stringLength);
        }
        const tuning = removeOctave(strings).join('');
        const standardTuning = tuningInfos.find(tuningInfo => tuningInfo.tuning === tuning);
        return {parentIndex: tuningInfos.find(tuningInfo => tuningInfo.strings.length === strings.length).parentIndex, n: result.n, strings, tuning, description: standardTuning ? standardTuning.description : 'Custom Tuning'};
      });
    });
    this.bestApiService.getTuningsCountByModeAndScale(table, key, root, scale.name, this.form.get('match').value).then(result => {
      this.tuningCount = result.n;
      this.title = this.tuningsTitle = `${this.tuningCount} ${this.form.get('match').value === 0 ? 'Best' : 'Pretty Good'} Tunings for ${key} ${scale.name} Mode Root ${root}`;
    });
  }

  private addAllOption(selectList): any {
    if (this.currentIndex === 2) {
      selectList.unshift({name: 'All'});
    }
    return selectList;
  }

  private setForm(index): void {
    this.currentIndex = index;
    this.dataService.send(DataChannelName.APP_INSTRUMENT_FORM, {
      form: this.form,
      data: {
        instruments: [
          { name: '6 String Guitar', index: 0},
          { name: '7 String Guitar', index: 7},
          { name: '8 String Guitar', index: 8},
          { name: 'Baritone Guitar', index: 13},
        ],
        keys: this.addAllOption(deepCopy(this.keys)),
        scales: this.addAllOption(deepCopy(this.scales)),
        index
      }
    });
  }

  showContents(instrument: TuningInfo): void {
  }

  getTuning(item): string {
    return removeOctave(item.strings).join('');
  }

  showAnnotations(instrument: TuningInfo): void {
    const dialog = this.dialog.open(AnnotationsDialogComponent);
    dialog.componentInstance.data = { modal: true, message: instrument.description, parentType: ParentType.Instrument };
  }

  addToCollection(instrument: TuningInfo): void {
    const dialogRef = this.dialog.open(CollectionDialogComponent, {
      data: new CollectionDialogModel(`Choose a collection to save your instrument to.`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const itemInfo = {
          type: ParentType.Chord,
          name: instrument.description,
          details: {
            name: instrument.tuning
          },
          order: -1
        };
        this.logService.write(LogType.Create, LogContext.Collection, {type: LogContext.Instruments, itemInfo})
        this.collectionItemsService.create(dialogResult, itemInfo).then(item => {
          this.snackBar.open('Instrument added to collection');
        });
      }
    });
  }

  adjustTuning(item): void {
    console.log('ADJUSTTUNING ITEM', item)
    if (!('tuningLimit' in item)) {
        item.parentIndex = this.form.get('instrument').value;
        item.tuningLimit = tuningInfos[item.parentIndex].tuningLimit;
    }
    const tuningInfo = {tuning: item, strings: item.strings, description: item.description, parentIndex: 0, index: -1, dots: undefined};
    const dialogRef = this.dialog.open(TuningsDialogComponent, {
      data: new TuningsDialogModel(`${item.description} ${item.tuning}`, tuningInfo)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.form.get('tuning').setValue();
      }
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.bestTunings.filter = fixSharpsAndFlatsReverse(filterValue.trim().toUpperCase());
  }

  bestModesAndKey(item, looseMatch: boolean): void {
    this.currentTuning = {strings: item.strings.join(''), tuning: item.tuning, stringCount: item.strings.length} ;
    this.showBest = true;
    setTimeout(() => {
      this.tabs.selectedIndex = 2;
    }, 50)
    this.looseMatch = looseMatch;
    this.bestKeysTitle = `${this.looseMatch ? 'Pretty Good' : 'Best'} Modes & Keys for ${this.getTuning(item)}`;
    this.historyService.create({isBookmark: false, name: `Tuning: ${this.bestKeysTitle}`, url: ''});
  }

  keysAndRootCountEvent(value): void {
    this.keysAndRootCount = value;
  }

  onTabChanged(event): void {
    this.setForm(event.index);
    switch (event.index) {
      case 0:
        this.title = this.tuningsTitle;
        this.keysAndRootCount = '';
        break;
      case 2:
        this.title = this.bestKeysTitle;
        break;
      case 1:
        this.title = 'Instruments';
        this.keysAndRootCount = '';
        break;
    }
    this.items.sort = this.itemsSort;
    this.bestTunings.sort = this.bestTuningsSort;
  }

}
