import { Injectable } from '@angular/core';
import fscreen from 'fscreen';

@Injectable({
  providedIn: 'root'
})

export class FullscreenService {

  constructor() { }

  open(domElement): Promise<void> {
    return fscreen.requestFullscreen(domElement);
  }

  close(): Promise<void> {
    return fscreen.exitFullscreen();
  }

}
