import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-introdialog',
  templateUrl: './introdialog.component.html',
  styleUrls: ['./introdialog.component.scss']
})

export class IntroDialogComponent {

  public form: FormGroup;
  public form2: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<IntroDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IntroDialogModel,
      private formBuilder: FormBuilder,
      private router: Router,
      private logService: LogService
  ) {
    this.form = this.formBuilder.group({
      instrument: new FormControl('', Validators.required),
      level: new FormControl('', Validators.required)
    });
    this.form2 = this.formBuilder.group({
      activity: new FormControl('', Validators.required),
    });
  }

  levelChanged(event): void {
    this.form.get('level').setValue(event.value);
  }

  instrumentChanged(event): void {
    this.form.get('instrument').setValue(event.value);
  }

  activityChanged(event): void {
    this.form2.get('activity').setValue(event.value);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    switch (this.form2.get('activity').value) {
      case '1':
        break;
      case '2':
        this.router.navigate(['best/guitar']);
        break;
      case '3':
        break;
      case '4':
        break;
      case '5':
        break;
      case '6':
        break;
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class IntroDialogModel {
  constructor() {
  }
}
