import {Component, ElementRef, Injector, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {convertNoteNumberToString, deepCopy} from '../../services/best/best.service.utils';
import {SEMITONES_IN_OCTAVE} from '../../data/charts';
import {GuitarchartComponent} from '../guitarchart/guitarchart.component';
import {Pianokeys} from 'custom-piano-keys';
import {instruments} from '../../services/sound/sound.service.instruments';
import {InstrumentName} from 'soundfont-player';
import {SoundService} from '../../services/sound/sound.service';
import {FormbaseComponent} from '../formbase/formbase.component';
import {FormBuilder} from '@angular/forms';
import {LeftMenu} from '../../app.component';
import {DataChannelName} from '../../services/data/data.service';

@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.scss']
})
export class ProComponent extends FormbaseComponent implements OnInit {

  @ViewChild('keyboard') keyboard: ElementRef;

  public rows = Array(9);
  public columns = Array(SEMITONES_IN_OCTAVE);
  public tuning;
  public strings;
  public root;
  public type;
  public scaleIndex;
  public chart;
  public currentDots;

  public renderingMode = {
    leftHanded: false,
    format: 'sharps',
    notedisplay: 'scale',
    frets: 24,
    instrument: 'G'
  };

  private pads;
  private dragging = false;
  private dragStartCol;
  private dragStartRow;

  private chordNoteNumbers = [
    {
      chordNoteNumber: 1,
      noteNumber: 33
    },
    {
      chordNoteNumber: 3,
      noteNumber: 36
    },
    {
      chordNoteNumber: 5,
      noteNumber: 40
    }
  ];

  constructor(
      private soundService: SoundService,
      private formBuilder: FormBuilder,
      injector: Injector,
  ) {
    super(injector);
    this.pads = Array(88).fill(0).map((_, index) => ({noteNumber: index + 1, name: convertNoteNumberToString(index + 1)}));
    this.pads.unshift(...Array(9));
    this.pads.push(...Array(SEMITONES_IN_OCTAVE - 1));
  }

  ngOnInit(): void {
    this.setContext('charts', LeftMenu.PRO);
    this.renderKeyboard();
    const searchForm = {
      instrument: this.formBuilder.control({value: this.renderingMode.instrument, disabled: false}),
      orientation: this.formBuilder.control({value: this.renderingMode.leftHanded ? 'L' : 'R', disabled: false}),
      frets: this.formBuilder.control({value: this.renderingMode.frets.toString(), disabled: false}),
      format: this.formBuilder.control({value: this.renderingMode.format, disabled: false}),
      notedisplay: this.formBuilder.control({value: 'scale', disabled: false}),
      key: this.formBuilder.control({value: 0, disabled: false}),
      rootkey: this.formBuilder.control({value: 0, disabled: false}),
      scale: this.formBuilder.control({value: 0, disabled: false}),
      mode: this.formBuilder.control({value: 0, disabled: false}),
      tuning: this.formBuilder.control({value: 0, disabled: false}),
      capo: this.formBuilder.control({value: 0, disabled: false}),
      chord: this.formBuilder.control({value: 0, disabled: false}),
      sort: this.formBuilder.control({value: 'B', disabled: false}),
      playInstrument: this.formBuilder.control({ value: 3, disabled: false})
    };
    this.form = this.formBuilder.group(searchForm);
    this.dataService.send(DataChannelName.APP_PRO_FORM, {
      form: this.form,
      data: {
        // keys: this.keys,
        // scales: this.scales,
        // modes: this.modes,
        // chords: this.chords,
        // tunings: this.tunings.map(aTuning => `${aTuning.tuning} ${aTuning.description}`),
        // instrument: this.renderingMode.instrument,
        // capos: this.capos,
        // strings: this.customStrings
      }
    });

  }

  private renderKeyboard(): void {
    setTimeout(() => {
        const pianokeys = document.createElement('custom-piano-keys') as Pianokeys;
        pianokeys.setAttribute('marked-keys', this.chordNoteNumbers.map(chordNote => chordNote.chordNoteNumber).join(' '));
        pianokeys.setAttribute('oct-count', '7');
        pianokeys.setAttribute('height', '100');
        pianokeys.setAttribute('oct-w-factor', '1.1');

        this.keyboard.nativeElement.appendChild(pianokeys);
    }, 100);
  }

  private getIndex(padRow, padCol): number {
    return padRow * SEMITONES_IN_OCTAVE + padCol;
  }

  padName(padRow, padCol): string {
    const pad = this.pads[this.getIndex(padRow, padCol)];
    return (pad ? pad.name : '');
  }

  padClass(padRow, padCol): string {
    const pad = this.pads[padRow * SEMITONES_IN_OCTAVE + padCol];
    return (pad ? pad.name : '');
  }

  padTag(padRow, padCol): number | undefined{
    const index = this.getIndex(padRow, padCol);
    const chordNote = this.chordNoteNumbers.find(note => note.noteNumber === index);
    return chordNote ? chordNote.chordNoteNumber : undefined;
  }

  private playPad(padRow, padCol): void {
    if (this.padTag(padRow, padCol)) {
      this.play(this.chordNoteNumbers.map(note => convertNoteNumberToString(note.noteNumber - 8)));
    } else {
      this.play([convertNoteNumberToString(this.getIndex(padRow, padCol) - 8)]);
    }
  }

  padClick(padRow, padCol): void {
  }

  padEnter(event, padRow, padCol): void {
    if (event.buttons !== 0) {
      if (this.dragging) {
        this.chordNoteNumbers.forEach(note => {
          note.noteNumber = note.noteNumber + (padRow - this.dragStartRow) * SEMITONES_IN_OCTAVE + padCol - this.dragStartCol;
          console.log('noteNumber', note.noteNumber);
        });
        this.dragStartRow = padRow;
        this.dragStartCol = padCol;
      }
      this.playPad(padRow, padCol);
    }
  }

  padLeave(padRow, padCol): void {
  }

  padOver(event, padRow, padCol): void {
    console.log('OVER', event);
  }

  padUp(padRow, padCol): void {
    console.log('UP')
    this.dragging = false;
  }

  padDown(padRow, padCol): void {
    console.log('DOWN')
    this.playPad(padRow, padCol);
    if (this.padTag(padRow, padCol)) {
      this.dragging = true;
      this.dragStartRow = padRow;
      this.dragStartCol = padCol;
    }
  }

  padDragOver(padRow, padCol): void {
    console.log('DRAGOVER')
    const rowDelta = padRow - this.dragStartRow;
    const colDelta = padCol - this.dragStartCol;
    this.chordNoteNumbers.forEach(note => {
      note.noteNumber += rowDelta * SEMITONES_IN_OCTAVE + colDelta;
    });
  }

  padDrag(padRow, padCol): void {
    console.log('DRAG');
    this.dragging = true;
    this.dragStartRow = padRow;
    this.dragStartCol = padCol;
  }

  play(notes): void {
    this.soundService.play(notes, false, instruments[this.form.get('playInstrument').value] as InstrumentName);
  }

}

@Pipe({
  name: 'padName',
  pure: true
})
export class PadNamePipe implements PipeTransform {

  transform(thisArg: ProComponent, padRow: number, padCol: number): string | undefined {
    return thisArg.padName(padRow, padCol);
  }

}

@Pipe({
  name: 'padTag',
  pure: false
})
export class PadTagPipe implements PipeTransform {

  transform(thisArg: ProComponent, padRow: number, padCol: number): number | undefined {
    return thisArg.padTag(padRow, padCol);
  }

}
