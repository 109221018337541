import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-keyboardcharts-form',
  templateUrl: './keyboardcharts-form.component.html',
  styleUrls: ['./keyboardcharts-form.component.scss', '../sidenav-base/sidenav-base.component.scss']
})
export class KeyboardchartsFormComponent extends SidenavBaseComponent implements OnInit {

  constructor(
      formBuilder: FormBuilder,
      dataService: DataService
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
    const searchForm = {
      instrument: this.formBuilder.control({value: 'G', disabled: false}),
      orientation: this.formBuilder.control({value: 'R', disabled: false}),
      frets: this.formBuilder.control({value: '24', disabled: false}),
      format: this.formBuilder.control({value: 'intervals', disabled: false}),
      notedisplay: this.formBuilder.control({value: 'scale', disabled: false}),
      key: this.formBuilder.control({value: 0, disabled: false}),
      rootkey: this.formBuilder.control({value: 0, disabled: false}),
      scale: this.formBuilder.control({value: 0, disabled: false}),
      mode: this.formBuilder.control({value: 0, disabled: false}),
      tuning: this.formBuilder.control({value: 0, disabled: false}),
      capo: this.formBuilder.control({value: 0, disabled: false}),
      chord: this.formBuilder.control({value: 0, disabled: false}),
      sort: this.formBuilder.control({value: 'S', disabled: false}),
    };
    this.form = this.formBuilder.group(searchForm);
  }

}
