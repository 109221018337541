import { Injectable } from '@angular/core';
import firebase from 'firebase';

export enum ParamName {
  'Apps',
  'WelcomeMessage'
}

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  private remoteConfig = firebase.remoteConfig();

  constructor() {
    this.remoteConfig.settings.minimumFetchIntervalMillis = 300000;
    this.remoteConfig.defaultConfig = {
      _localhost: '{"showApps":["Be"]}'
    };
  }

  activate(): Promise<any> {
    return this.remoteConfig.fetchAndActivate();
  }

  getParameter(paramName: string): any {
    return this.remoteConfig.getValue(paramName).asString();
  }

  getDomainInfo(): any {
    console.log('GETDOMAININFO', `_${window.location.hostname.replace(/\./g, '_')}`, this.getParameter(`_${window.location.hostname.replace('.', '_')}`))
    return JSON.parse(this.getParameter(`_${window.location.hostname.replace(/\./g, '_')}`));
  }

}
