import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ChordType, chordTypes} from '../../gd/src/chord-module';
import {chordParserFactory, chordRendererFactory} from 'chord-symbol';
import * as music from '../../gd/src/music-module';
import {AnnotationsDialogComponent} from '../dialogs/annotationsdialog/annotationsdialog.component';
import {ParentType} from '../../services/firestore/annotations/annotations.service';
import {CollectionDialogComponent, CollectionDialogModel} from '../dialogs/collectiondialog/collectiondialog.component';
import {SoundService} from '../../services/sound/sound.service';
import {FormBuilder} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {UtilsService} from '../../services/utils/utils.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as tuning from '../../gd/src/tuning-module';
import {MatDialog} from '@angular/material/dialog';
import {CollectionItemsService} from '../../services/firestore/collectionitems/collectionitems.service';
import {SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import {noteNames, scaleFamily} from '../../gd/src/music-module';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {BestService} from '../../services/best/best.service';
import {convertNoteNumberToString, convertNoteStringToNoteNumber, modulo} from '../../services/best/best.service.utils';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {tuningInfos} from '../../gd/src/tuning-module';
import {CryptoService} from '../../services/crypto/crypto.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {Mod} from '../../gd/src/mod-module';

@Component({
  selector: 'app-scalelist',
  templateUrl: './scalelist.component.html',
  styleUrls: ['./scalelist.component.scss']
})
export class ScalelistComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() items = new MatTableDataSource<ChordType>();
  @ViewChild(MatSort) sort: MatSort;
  @Input() notes;
  @Input() change;
  @Input() extraNote = false;
  @Output() menuItemSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  public columnsToDisplay = ['name', 'notes', 'actionmenu' ];
  public inversions = music.inversions;
  public tunings = tuning.tuningInfos;
  public keys = music.noteNames;

  private parseChord;
  private renderChord;
  private currentPage = 0;
  public pageSize = 25;
  public allItems = [];

  public tuningIndex = 0;
  public keyIndex = 0;
  public filter;

  public form;

  constructor(
      private soundService: SoundService,
      private formBuilder: FormBuilder,
      private utilsService: UtilsService,
      private dataService: DataService,
      private router: Router,
      private snackBar: MatSnackBar,
      private dialog: MatDialog,
      private collectionItemsService: CollectionItemsService,
      private bestService: BestService,
      private profileService: ProfileService,
      private cryptoService: CryptoService,
      private spinnerService: SpinnerService
  ) {
  }

  ngOnInit(): void {
    this.parseChord = chordParserFactory();
    this.renderChord = chordRendererFactory({ useShortNamings: true });
    this.form = this.formBuilder.group({});
    this.dataService.send(DataChannelName.APP_CHORD_FORM, {
      form: this.form,
      data: {
      }
    });
  }

  ngAfterViewInit(): void {
    this.items.sort = this.sort;
    this.drawItems();
  }

  ngOnChanges(): void {
    this.drawItems();
  }

  pageEvent(event): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setPageSlice();
  }

  private setPageSlice(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.allItems.slice(start, end);
    this.items.data = part;
  }

  drawFromNotes(): void {
      const workData = [];
      const enteredNoteOffsets = this.notes.map(note => noteNames.find(noteName => noteName.name === note).index);
      if (enteredNoteOffsets.length >= 2) {
        scaleFamily.forEach((scale, chordIndex) => {
          for (let offset = 0; offset < SEMITONES_IN_OCTAVE; offset++) {
            const workScale = Object.assign({}, scale);
            workScale.intervals = new Mod(scale.intervals.toArray());
            let noteMismatchCount = 0;
            let mismatchIndex;
            enteredNoteOffsets.forEach(noteOffset => {
              const index = modulo(noteOffset - offset, SEMITONES_IN_OCTAVE);
              if (!scale.intervals.itemAt(index)) {
                noteMismatchCount++;
                if (this.extraNote && noteMismatchCount === 1) {
                  workScale.intervals.items[index] = true;
                  mismatchIndex = noteOffset;
                }
              }
            })
            if (noteMismatchCount === 0 || (this.extraNote && noteMismatchCount === 1)) {
              workData.push(workScale);
              this.addInfoToScale(workScale, sharpOctave[offset]);
              workScale.name = `${sharpOctave[offset]} ${workScale.name}`;
              if (noteMismatchCount === 1) {
                workScale.name += ` (Added ${sharpOctave[mismatchIndex]})`;
              }
            }
          }
        });
      }
      this.allItems = workData;
      this.currentPage = 0;
      this.setPageSlice();
}

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.items.filter = filterValue;
  }

  drawItems(): void {
      this.drawFromNotes();
  }

  private addInfoToScale(scale, rootKeyName): void {
    const rootKeySemitoneOffset = modulo(music.noteNames.find(note => note.name === rootKeyName).index, 12);
    const semitones = [];
    scale.notes = [];
    scale.intervals.toArray().map((interval, index) => {
      if (interval) {
        semitones.push(index);
        scale.notes.push(sharpOctave[index]);
      }
    })
    scale.notes = scale.notes.map((note, index) => {
      return note + (semitones[index] >= 3 ? '4' : '3');
    });
    const noteNumbersInScale = scale.notes.map(note => convertNoteStringToNoteNumber(note) + rootKeySemitoneOffset);
    scale.notes = noteNumbersInScale.map(noteNumber => convertNoteNumberToString(noteNumber));
    scale.rootKeyName = rootKeyName;
  }

  showCharts(chord): void {
    this.profileService.get().then(profile => {
      const instrument = tuningInfos.find(tuningInfo => tuningInfo.index === parseInt(profile.instrument, 10));
      const chordParts = chord.name.split(' ');
      this.router.navigate(['charts', this.cryptoService.encodeJSON({chord: chordParts[0], mode: 'Ionian', tuning: instrument.tuning})]);
    });
  }

  showAnnotations(chord: ChordType): void {
    const dialog = this.dialog.open(AnnotationsDialogComponent);
    dialog.componentInstance.data = { modal: true, message: chord.name, parentType: ParentType.Chord };
  }

  addToCollection(chord: ChordType): void {
    const dialogRef = this.dialog.open(CollectionDialogComponent, {
      data: new CollectionDialogModel(`Choose a collection to save your chord to.`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const itemInfo = {
          type: ParentType.Chord,
          name: chord.name,
          details: {
            name: chord.intervals.toArray().join('')
          },
          order: -1
        };
        this.collectionItemsService.create(dialogResult, itemInfo).then(item => {
          this.snackBar.open('Chord added to collection');
        });
      }
    });
  }

  playChord(item: ChordType): void {
    this.soundService.play(item.notes, true);
  }

}
