import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {DataChannelName, DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-chords-form',
  templateUrl: './chords-form.component.html',
  styleUrls: ['./chords-form.component.scss', '../sidenav-base/sidenav-base.component.scss']
})
export class ChordsFormComponent extends SidenavBaseComponent implements OnInit, AfterViewInit {

  public showKey;

  constructor(
      formBuilder: FormBuilder,
      dataService: DataService
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscribeToChannel(DataChannelName.APP_CHORD_FORM, () => {
      this.showKey = this.form.get('key') ? true : false;
    });
  }

}
