import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CollectionItem, CollectionItemsService} from '../../../services/firestore/collectionitems/collectionitems.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../confirmdialog/confirmdialog.component';
import {AddDialogComponent, AddDialogModel} from '../adddialog/adddialog.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {LogService} from '../../../services/firestore/log/log.service';

@Component({
  selector: 'app-collectionitemsdialog',
  templateUrl: './collectionitemsdialog.component.html',
  styleUrls: ['./collectionitemsdialog.component.scss']
})
export class CollectionItemsDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<CollectionItem>();
  public columnsToDisplay = ['name', '_metadata.createdAt', 'actionmenu' ];

  message: string;
  collectionId: string;

  constructor(
      private collectionItemsService: CollectionItemsService,
      public dialogRef: MatDialogRef<CollectionItemsDialogComponent>,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private router: Router,
      private logService: LogService,
  @Inject(MAT_DIALOG_DATA) public data: CollectionItemsDialogModel)
  {
    this.message = data.message;
    this.collectionId = data.collectionId;
  }

  ngOnInit(): void {
    this.collectionItemsService.getAll(this.collectionId, items => {
      this.items.data = items;
    });
  }

  ngAfterViewInit(): void {
    this.items.sortingDataAccessor = (item, property) => {
      switch (property) {
        case '_metadata.createdAt': return item._metadata.createdAt;
        default: return item[property];
      }
    };
    this.items.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items.data, event.previousIndex, event.currentIndex);
    this.collectionItemsService.setItemsOrder(this.items.data);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

  showContents(item: CollectionItem): void {
    this.dialogRef.close(null);
    this.router.navigate(['scales/item', item.id]);
  }

  copyItem(item: CollectionItem): void {
    item.name = item.name + ' - COPY';
    this.collectionItemsService.create(this.collectionId, item).then(() => {
      this.snackBar.open('Item copied');
    });
  }

  canEdit(item: CollectionItem): boolean {
    return this.collectionItemsService.canEdit(item);
  }

  editItem(item: CollectionItem): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: new AddDialogModel(true, 'Edit Item', `Change the name and attributes of the item.`,
          [
            {type: 'input', value: item.name, controlName: 'name', label: 'Item Name', required: true },
          ]
      )
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionItemsService.update(item.id, dialogResult).then(() => {
          this.snackBar.open('Item updated');
        });
      }
    });
  }

  deleteItem(item: CollectionItem): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm Delete', `Are you sure you want to delete this item?`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionItemsService.delete(item.id).then(() => {
          this.snackBar.open('Item deleted');
        });
      }
    });
  }

}

export class CollectionItemsDialogModel {
  constructor(public message: string, public collectionId: string) {
  }
}
