import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {InterceptorService} from './services/interceptor/interceptor.service';
import {ChartsComponent} from './components/charts/charts.component';
import {SigninComponent} from './components/signin/signin.component';
import {TosComponent} from './components/tos/tos.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {AuthGuard} from './guards/auth.guard';
import {ProfileComponent} from './components/profile/profile.component';
import {ChordsComponent} from './components/chords/chords.component';
import {InstrumentsComponent} from './components/instruments/instruments.component';
import {CollectionsComponent} from './components/collections/collections.component';
import {ScalesComponent} from './components/scales/scales.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AnnotationsComponent} from './components/annotations/annotations.component';
import {BestComponent} from './components/best/best.component';
import {ProgressionsComponent} from './components/progressions/progressions.component';
import {ComposeComponent} from './components/compose/compose.component';
import {TuningsDialogComponent} from './components/dialogs/tuningsdialog/tuningsdialog.component';
import {AdminComponent} from './components/admin/admin.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {EasystartComponent} from './components/easystart/easystart.component';
import {ProComponent} from './components/pro/pro.component';
import {FretboardComponent} from './components/fretboard/fretboard.component';
import {GuestintroComponent} from './components/guestintro/guestintro.component';

const routes: Routes = [
    {
        path: '',
        component: SigninComponent
    },
    {
        path: 'tos',
        component: TosComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'admin',
        component: AdminComponent
    },
    {
        path: 'subscribe',
        component: SubscribeComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'annotations',
        component: AnnotationsComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'charts',
        component: ChartsComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'best/:instrument',
        component: BestComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'charts/:item',
        component: ChartsComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'fretboard/:info',
        component: FretboardComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'chords',
        component: ChordsComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'instruments',
        component: InstrumentsComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'scales',
        component: ScalesComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'progressions',
        component: ProgressionsComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'compose',
        component: ComposeComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'scales/item/:itemId',
        component: ScalesComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'collections',
        component: CollectionsComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'easystart',
        component: EasystartComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'guestintro',
        component: GuestintroComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'tunings',
        component: TuningsDialogComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
    {
        path: 'pro',
        component: ProComponent,
        canActivate: [ AuthGuard ],
        data: {
            reuseRoute: true
        }
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        enableTracing: false
    })],
    exports: [RouterModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
    ]
})
export class AppRoutingModule {}
