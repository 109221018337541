import {Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { findGuitarChord } from 'chord-fingering';
import {ChordType} from '../../gd/src/chord-module';
import * as tuning from '../../gd/src/tuning-module';
import * as music from '../../gd/src/music-module';
import { ChordBox } from 'vexchords';

@Component({
  selector: 'app-guitar-fingering',
  templateUrl: './guitar-fingering.component.html',
  styleUrls: ['./guitar-fingering.component.scss']
})
export class GuitarFingeringComponent implements OnInit, AfterViewInit, OnChanges {

  DIAGRAM_HEIGHT = 180;

  @ViewChild('guitar') guitar;

  @Input() item;
  @Input() tuningIndex;

  private tunings = tuning.tuningInfos;
  private keys = music.noteNames;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.showGuitarFingering(this.item);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showGuitarFingering(this.item);
  }

  private showGuitarFingering(chord: ChordType, first = true): void {
    if (!this.guitar) {
      return;
    }
    const chordSymbol = chord.rootKeyName + chord.abbreviations[0];
    const previous = this.guitar.nativeElement.firstChild;
    const currentTuning = this.tunings.find(thisTuning => thisTuning.index === this.tuningIndex);
    if (previous) {
      previous.remove();
    }
    /*
    while (previous.childNodes[1]) {
      previous.childNodes[1].remove();
    }
    */
    const chordItem = new ChordBox(this.guitar.nativeElement, {
      width: 120,
      height: this.DIAGRAM_HEIGHT,
      showTuning: true,
      numStrings: currentTuning.strings.length,
      numFrets: 9
    });
    const tuningStrings = currentTuning.strings.map(aString => aString.slice(0, -1));
    const guitarChord = findGuitarChord(chordSymbol, tuningStrings);
    if (guitarChord) {
      if (first) {
        chord.fingeringCount = guitarChord.fingerings.length;
        chord.currentFingering = 0;
      }
      const fingering = guitarChord.fingerings[chord.currentFingering];
      chordItem.draw({
        // array of [string, fret, label (optional)]
        chord: fingering.positionString.split('').reverse().map((position, index) => [index + 1, position]),
        // position: 5, // start render at fret 5
        // tslint:disable-next-line:max-line-length
        barres: fingering.barre ? [ { fret: fingering.barre.fret, fromString: fingering.barre.stringIndices[0] + 1,  toString: fingering.barre.stringIndices[1] + 1}] : [],
        tuning: tuningStrings
      });
    }
  }

  fingeringChanged(event, item: ChordType): void {
    item.currentFingering = event.value;
    this.showGuitarFingering(item, false);
  }

}
