import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import Vex from 'vexflow';
import {UtilsService} from '../../services/utils/utils.service';
import {fixSharpsAndFlats} from '../../services/best/best.service.utils';

@Component({
  selector: 'app-scale-stave',
  templateUrl: './scale-stave.component.html',
  styleUrls: ['./scale-stave.component.scss']
})
export class ScaleStaveComponent implements OnInit, AfterViewInit {

  DIAGRAM_HEIGHT = 180;

  @Input() item;
  @ViewChild('stave') stave;

  constructor(
      private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const firstNote = this.item.notes[0];
    this.item.notes.push(`${firstNote.slice(0, -1)}${parseInt(firstNote.slice(-1), 10) + 1}`);
    this.showScaleOnStave(this.item.notes.map(note => fixSharpsAndFlats(note)));
  }

  private showScaleOnStave(notes): void {
    const previous = this.stave.nativeElement.firstChild;
    if (previous) {
      previous.remove();
    }
    const scoreNotes = notes.map(note => {
      const unpitchedNote = note.slice(0, -1);
      const staveNote = new Vex.Flow.StaveNote({keys: [`${unpitchedNote}/${note.slice(-1)}`], duration: 'q'});
      if (unpitchedNote.length > 1) {
        staveNote.addAccidental(0, new Vex.Flow.Accidental(unpitchedNote.charAt(1)));
      }
      return staveNote;
    } );
    const voice = new Vex.Flow.Voice({
      num_beats: notes.length,
      beat_value: 4,
      resolution: Vex.Flow.RESOLUTION
    });
    voice.setStrict(false);
    voice.addTickables(scoreNotes);
    const renderer = new Vex.Flow.Renderer(this.stave.nativeElement, Vex.Flow.Renderer.Backends.SVG);
    renderer.resize(600, this.DIAGRAM_HEIGHT);
    const context = renderer.getContext();
    context.setFont('Arial', 10, '').setBackgroundFillStyle('#eed');
    const stave = new Vex.Flow.Stave(10, 40, 580, {key: 'C'});
    stave.addClef('treble');
    stave.setContext(context).draw();
    const formatter = new Vex.Flow.Formatter().joinVoices([voice]).format([voice], 580);
    voice.draw(context, stave);
  }

}
