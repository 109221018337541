import { Injectable } from '@angular/core';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  downloadPDFFromDOM(domElement, fileName: string): void {
      html2pdf().set({
          image: { type: 'png' }
      }).from(domElement).save(fileName);
  }

  downloadPNGFromDOM(domElement, fileName, callback = null): void {
      html2canvas(domElement).then(canvas => {
          canvas.toBlob(blob => {
              if (fileName) {
                  window.saveAs(blob, `${fileName}.png`);
              }
              if (callback) {
                  callback(blob);
              }
          }, 'image/png');
      });
  }

}
