import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';

export interface Progression {
  id: string;
  _metadata: Firestore;
  title: string;
  description: string;
  chords: Array<string>;
  leadSheetChords?: Array<string>;
}

@Injectable({
  providedIn: 'root'
})

export class ProgressionsService extends FirestoreService {

  constructor(
      public authService: AuthService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'progressions';
  }

  get(id: string): Promise<Progression | boolean> {
    return this.docFromCollection(id);
  }

  getAll(resultFunc: (array) => void): void {
    this.db.collection(this.collectionName)
        .onSnapshot(querySnapshot => resultFunc(querySnapshot.docs.map(doc =>  {
          const data = doc.data(); data.id = doc.id; return data; })));
  }

  create(data): Promise<string | null> {
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  update(id: string, data): Promise<boolean> {
    return this.updateDocument(id, data);
  }

  delete(id: string): Promise<boolean> {
    return this.deleteDocument(id);
  }

}
