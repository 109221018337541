import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from '@angular/material/tooltip';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {OverlayContainer, FullscreenOverlayContainer} from '@angular/cdk/overlay';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {environment} from '../environments/environment';

import {LoaderComponent} from './components/loader/loader.component';
import {SidenavService} from './services/sidenav/sidenav.service';
import {
    IdxTemplatePortalDirective,
    IdxPortalDirective,
    PortalService
} from './directives/idx-template-portal/idx-template-portal.modul';
import {IdxMatBottomSheetComponent} from './components/idx-mat-bottom-sheet/idx-mat-bottom-sheet.component';
import {BottomsheetService} from './components/idx-mat-bottom-sheet/idx-mat-bottom-sheet.service';
import {IdxMatIconComponent} from './components/idx-mat-icon/idx-mat-icon.component';
import {ToolsService} from './services/tools/tools.service';
import { SafeHtmlPipe } from './pipes/idx-safeHTML.pipe';
import {
    BestChartIconPipe,
    ChordNotesPipe,
    ChordOpenStringOverlapPipe, FillerNotesPipe,
    FirstRowAddedNotesPipe,
    FretClassPipe, GetNoteLabelPipe,
    GetSecondRowFillerNotesPipe,
    NeedSecondNoteRowPipe,
    NutClassPipe,
    SecondRowAddedNotesPipe, ShowLeftDotPipe, ShowMiddleDotPipe, ShowRightDotPipe,
    StringSequencePipe
} from './components/guitarchart/guitarchart.component';
import {SigninComponent} from './components/signin/signin.component';
import { TosComponent } from './components/tos/tos.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FormbaseComponent } from './components/formbase/formbase.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { ChordsComponent } from './components/chords/chords.component';
import { ScalesComponent } from './components/scales/scales.component';
import { InstrumentsComponent } from './components/instruments/instruments.component';
import {ConfirmDialogComponent} from './components/dialogs/confirmdialog/confirmdialog.component';
import { AddDialogComponent } from './components/dialogs/adddialog/adddialog.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import { CollectionDialogComponent } from './components/dialogs/collectiondialog/collectiondialog.component';
import { CollectionItemsDialogComponent } from './components/dialogs/collectionitemsdialog/collectionitemsdialog.component';
import {EDITABLE_CONFIG, EditableConfig, EditableModule} from '@ngneat/edit-in-place';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AnnotationsDialogComponent } from './components/dialogs/annotationsdialog/annotationsdialog.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { RecordAudioDialogComponent } from './components/dialogs/recordaudiodialog/recordaudiodialog.component';
import {NgxAudioPlayerModule} from 'ngx-audio-player';
import { RecordMIDIDialogComponent } from './components/dialogs/recordmididialog/recordmididialog.component';
import {CdkDetailRowDirective} from './directives/cdk-detail-row/cdk-detail-row.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatSliderModule} from '@angular/material/slider';
import { BestComponent } from './components/best/best.component';
import { ProgressionsComponent } from './components/progressions/progressions.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ComposeComponent } from './components/compose/compose.component';
import { IntroDialogComponent } from './components/dialogs/introdialog/introdialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {TuningsDialogComponent} from './components/dialogs/tuningsdialog/tuningsdialog.component';
import { AppsComponent } from './components/apps/apps.component';
import { ChartsFormComponent } from './components/sidenav-forms/charts-form/charts-form.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { KeyboardchartsFormComponent } from './components/sidenav-forms/keyboardcharts-form/keyboardcharts-form.component';
import { BestFormComponent } from './components/sidenav-forms/best-form/best-form.component';
import { ChordsFormComponent } from './components/sidenav-forms/chords-form/chords-form.component';
import { ScalesFormComponent } from './components/sidenav-forms/scales-form/scales-form.component';
import { InstrumentsFormComponent } from './components/sidenav-forms/instruments-form/instruments-form.component';
import { SidenavBaseComponent } from './components/sidenav-forms/sidenav-base/sidenav-base.component';
import {MatAutocomplete, MatAutocompleteModule} from '@angular/material/autocomplete';
import { ChordlistComponent } from './components/chordlist/chordlist.component';
import { GuitarFingeringComponent } from './components/guitar-fingering/guitar-fingering.component';
import { ChordStaveComponent } from './components/chord-stave/chord-stave.component';
import {ScalelistComponent} from './components/scalelist/scalelist.component';
import { NoteselectorComponent } from './components/noteselector/noteselector.component';
import { ScaleStaveComponent } from './components/scale-stave/scale-stave.component';
import { HelpComponent } from './components/help/help.component';
import { AdminComponent } from './components/admin/admin.component';
import { BestkeysandrootComponent } from './components/bestkeysandroot/bestkeysandroot.component';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import {CustomHttpInterceptor} from './http-interceptor';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import { SelectComponent } from './components/select/select.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { HistoryComponent } from './components/history/history.component';
import {RouteReuseStrategy} from '@angular/router';
import {RouteReuseService} from './services/routeReuseService/routeReuseService';
import { ReusePageBaseComponent } from './components/reusepagebase/reusepagebase.component';
import { EasystartComponent } from './components/easystart/easystart.component';
import { DragbinComponent } from './components/easystart/dragbin/dragbin.component';
import {PadNamePipe, PadTagPipe, ProComponent} from './components/pro/pro.component';
import { ProFormComponent } from './components/sidenav-forms/pro-form/pro-form.component';
import { GuitarchartComponent } from './components/guitarchart/guitarchart.component';
import {ChartsComponent} from './components/charts/charts.component';
import { RecordingComponent } from './components/recording/recording.component';
import { PitchdetectorComponent } from './components/pitchdetector/pitchdetector.component';
import { FretboardComponent } from './components/fretboard/fretboard.component';
import { ProgressionFormComponent } from './components/sidenav-forms/progression-form/progression-form.component';
import {CanvasWhiteboardModule} from 'ng2-canvas-whiteboard';
import {ContactUsDialogComponent} from './components/dialogs/contactusdialog/contactusdialog.component';
import { GuestintroComponent } from './components/guestintro/guestintro.component';

const materialModules = [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatCardModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    FlexLayoutModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatAutocompleteModule,
    CommonModule,
    MatTooltipModule,
    CdkScrollableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    EditableModule,
    BrowserModule,
    NgxAudioPlayerModule,
    DragDropModule,
    MatSliderModule,
    MatStepperModule,
    CdkStepperModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    CanvasWhiteboardModule
];

@NgModule({
    declarations: [
        AppComponent,
        IdxMatIconComponent,
        LoaderComponent,
        IdxMatBottomSheetComponent,
        IdxPortalDirective,
        IdxTemplatePortalDirective,
        SigninComponent,
        SafeHtmlPipe,
        ChartsComponent,
        GuitarchartComponent,
        TosComponent,
        PrivacyComponent,
        ProfileComponent,
        FormbaseComponent,
        CollectionsComponent,
        ChordsComponent,
        ScalesComponent,
        InstrumentsComponent,
        ConfirmDialogComponent,
        AddDialogComponent,
        CollectionDialogComponent,
        CollectionItemsDialogComponent,
        DashboardComponent,
        AnnotationsDialogComponent,
        AnnotationsComponent,
        RecordAudioDialogComponent,
        RecordMIDIDialogComponent,
        CdkDetailRowDirective,
        BestComponent,
        ProgressionsComponent,
        ComposeComponent,
        BestChartIconPipe,
        ChordOpenStringOverlapPipe,
        ChordNotesPipe,
        FirstRowAddedNotesPipe,
        SecondRowAddedNotesPipe,
        NeedSecondNoteRowPipe,
        GetSecondRowFillerNotesPipe,
        StringSequencePipe,
        NutClassPipe,
        FretClassPipe,
        GetNoteLabelPipe,
        FillerNotesPipe,
        ShowLeftDotPipe,
        ShowMiddleDotPipe,
        ShowRightDotPipe,
        PadNamePipe,
        PadTagPipe,
        IntroDialogComponent,
        TuningsDialogComponent,
        AppsComponent,
        ChartsFormComponent,
        KeyboardchartsFormComponent,
        BestFormComponent,
        ChordsFormComponent,
        ScalesFormComponent,
        InstrumentsFormComponent,
        SidenavBaseComponent,
        ChordlistComponent,
        ScalelistComponent,
        GuitarFingeringComponent,
        ChordStaveComponent,
        NoteselectorComponent,
        ScaleStaveComponent,
        HelpComponent,
        AdminComponent,
        BestkeysandrootComponent,
        SpinnerComponent,
        SubscribeComponent,
        SelectComponent,
        HistoryComponent,
        ReusePageBaseComponent,
        EasystartComponent,
        DragbinComponent,
        ProComponent,
        ProFormComponent,
        RecordingComponent,
        PitchdetectorComponent,
        FretboardComponent,
        ProgressionFormComponent,
        ContactUsDialogComponent,
        GuestintroComponent
    ],
    imports: materialModules,
    exports: materialModules,
    providers: [
        SidenavService,
        PortalService,
        ToolsService,
        Title,
        BottomsheetService,
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {showDelay: 1000}},
        {
            provide: EDITABLE_CONFIG,
            useValue: {
                openBindingEvent: 'click',
                closeBindingEvent: 'click',
            } as EditableConfig
        },
        {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReuseService
        }
    ],
    entryComponents: [IdxMatBottomSheetComponent, ConfirmDialogComponent],
    bootstrap: [AppComponent],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule {
    constructor(overlayContainer: OverlayContainer) {
        const overlayContainerEl = overlayContainer.getContainerElement();
        overlayContainerEl.classList.add('app-secondary-theme');
        overlayContainerEl.oncontextmenu = (event) => {
            event.target.dispatchEvent(new MouseEvent('click', {bubbles: true}));
            event.stopPropagation();
            event.preventDefault();
        };
    }
}
