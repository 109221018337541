import * as d3 from 'd3';
import * as music from './music-module';
import * as events from './events-module';

export function init(): void {
    d3.select('#scale-dropdown')
        .selectAll('div')
        .data(music.scaleFamily)
        .enter()
        .append('div')
        .attr('class', 'dropdown-content-item')
        .on('click', (_, x) => raiseScaleFamilyChangedEvent(x))
        .text(x => x.name);
}

function raiseScaleFamilyChangedEvent(scaleFamily: music.ScaleFamily): void{
    events.scaleFamilyChange.publish({
        scaleFamily
    });
}
