import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-idx-mat-bottom-sheet',
    templateUrl: './idx-mat-bottom-sheet.component.html',
    styleUrls: ['./idx-mat-bottom-sheet.component.scss']
})
export class IdxMatBottomSheetComponent {

    constructor(private bottomsheetRef: MatBottomSheetRef<IdxMatBottomSheetComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

    close(event: MouseEvent): void {
        this.bottomsheetRef.dismiss();
        event.preventDefault();
    }

}
