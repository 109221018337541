import * as d3 from 'd3';
import * as events from './events-module';
import * as music from './music-module';

let buttons: d3.Selection<music.Mode>;
let modes: d3.Selection<any>;

export function init(scaleFamily: music.ScaleFamily): void {
    const svg = d3.select('#modes')
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 160 225')
        .classed('svg-content', true);
    modes = svg
        .append('g')
        .attr('transform', 'translate(0, 0)');

    drawButtons(scaleFamily);

    events.modeChange.subscribe(update);
    events.scaleFamilyChange.subscribe(handleScaleFamilyChangedEvent);
}

function drawButtons(scaleFamily: music.ScaleFamily): void {
    const pad = 5;
    const buttonHeight = 25;

    // modes.selectAll('g').remove();
    const gs = modes.selectAll('g').data(scaleFamily.modes, index);

    // gs
    //    .exit()
    //    .remove();

    gs
        // .enter()
        // .append('g')
        .join('g')
        .attr('transform', (d, i) => 'translate(0, ' + (i * (buttonHeight + pad) + pad) + ')');

    buttons = gs
        .append('rect')
        .attr('x', pad)
        .attr('y', 0)
        .attr('strokeWidth', 2)
        .attr('width', 150)
        .attr('height', 25)
        .attr('class', 'mode-button')
        .on('click', (_, d) => events.modeChange.publish({ mode: d }));

    gs
        .append('text')
        .attr('x', pad + 10)
        .attr('y', 17)
        .text((x) => x.name)
        .attr('class', 'mode-text');

    const defaultMode = scaleFamily.modes.find(x => x.index === scaleFamily.defaultModeIndex);
    highlightActiveMode(defaultMode);
}

function update(modeChange: events.ModeChangedEvent): void {
    highlightActiveMode(modeChange.mode);
}

function highlightActiveMode(mode: music.Mode): void {
    const activeModes: Array<music.Mode> = [mode];
    buttons
        .data(activeModes, index)
        .attr('class', 'mode-button mode-button-selected')
        .exit()
        .attr('class', 'mode-button');
}

function handleScaleFamilyChangedEvent(scaleFamilyChangedEvent: events.ScaleFamilyChangeEvent): void {
    drawButtons(scaleFamilyChangedEvent.scaleFamily);
    drawButtons(scaleFamilyChangedEvent.scaleFamily);
}

function index(mode: music.Mode): string {
    return mode.index.toString();
}

