import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {DataChannelName, DataService} from '../../services/data/data.service';
import {RouteReuseService} from '../../services/routeReuseService/routeReuseService';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-reusepagebase',
  templateUrl: './reusepagebase.component.html',
  styleUrls: ['./reusepagebase.component.scss']
})
export class ReusePageBaseComponent implements OnInit, OnDestroy {

  private sideNavForm;
  private routerBasePath;
  private routerEvents;

  public dataService: DataService;
  public isActive;

  private router2;

  constructor(
      private injector: Injector,
  ) {
    this.dataService = injector.get(DataService);
    this.router2 = injector.get(Router);
    this.router2.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isActive = false;
        if (event.urlAfterRedirects.indexOf(this.routerBasePath) >= 0) {
          this.setLeftNav();
          this.isActive = true;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }

  private setLeftNav(): void {
    this.dataService.send(DataChannelName.LEFT_NAV_CONTENTS, this.sideNavForm);

  }

  setContext(url: string, sideNavForm): void {
    this.routerBasePath = url;
    this.isActive = true;
    this.sideNavForm = sideNavForm;
    this.setLeftNav();
  }

}
