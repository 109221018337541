import * as mod from './mod-module';
import {noteNames, scaleFamily, ScaleFamily} from './music-module';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {rotate} from '../../services/best/best.service.utils';

export interface ChordType {
    name: string;
    readonly abbreviations: string[];
    readonly intervals: mod.Mod<boolean>;
    attributes: Array<ChordAttributes>;
    fingeringCount?: number;
    currentFingering?: number;
    notes?: Array<string>;
    rootKeyName?: string;
}

export enum ChordAttributes {
    COMMON,
    FLAT5,
    SHARP5,
    SEVENTH,
    NINTH,
    ELEVENTH,
    THIRTEENTH,
    MAJOR,
    MINOR,
    AUGMENTED,
    DIMINISHED
}

export let chordTypes: ChordType[] = [
    { name: 'Major', abbreviations: ['', 'M', 'maj'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, false, false, true, false, false, false, false]) },
    { name: 'Minor', abbreviations: ['m', 'min', '-'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, true, false, false, false, true, false, false, false, false]) },
    { name: 'Suspended fourth', abbreviations: ['sus4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, false, true, false, true, false, false, false, false]) },
    { name: 'Dominant seventh', abbreviations: ['7'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, false, false, true, false, false, true, false]) },
    { name: 'Minor seventh', abbreviations: ['m7'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, true, false, false, false, true, false, false, true, false]) },
    { name: 'Major seventh', abbreviations: ['maj7'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, false, false, true, false, false, false, true]) },
    { name: 'Seventh, suspended fourth', abbreviations: ['7sus4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, false, true, false, true, false, false, true, false]) },
    { name: 'Major Seventh, suspended fourth', abbreviations: ['M7sus4', 'maj7sus4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, false, true, false, true, false, false, false, true]) },
    { name: 'Sixth', abbreviations: ['6', 'M6'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, false, false, true, false, true, false, false]) },
    { name: 'Minor Sixth', abbreviations: ['m6'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, true, false, false, false, true, false, true, false, false]) },
    { name: 'Sixth, Suspended Fourth', abbreviations: ['6sus4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, false, true, false, true, false, true, false, false]) },
    { name: 'Added Fourth', abbreviations: ['add4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, true, false, true, false, false, false, false]) },
    { name: 'Minor, Added Fourth', abbreviations: ['madd4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, true, false, true, false, true, false, false, false, false]) },
    { name: 'Sixth, Added Fourth', abbreviations: ['6add4'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, true, true, false, true, false, true, false, false]) },
    { name: 'Fifth, Power Chord', abbreviations: ['5'], attributes: [ChordAttributes.COMMON], intervals: new mod.Mod([true, false, false, false, false, false, false, true, false, false, false, false]) },
    { name: 'Diminished', abbreviations: ['dim', '°'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, false, true, false, false, false, false, false]) },
    { name: 'Diminished Seventh', abbreviations: ['dim7', '°7'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, false, true, false, false, true, false, false]) },
    { name: 'Flat Fifth', abbreviations: ['♭5'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, true, false, false, false, false, false]) },
    { name: 'Seventh, Flat Fifth', abbreviations: ['7♭5'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, true, false, false, false, true, false]) },
    { name: 'Minor Seventh, Flat Fifth', abbreviations: ['m7♭5'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, false, true, false, false, false, true, false]) },
    { name: 'Ninth, Flat Fifth', abbreviations: ['9♭5'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, true, false, false, false, true, false]) },
    { name: 'Minor Ninth, Flat Fifth', abbreviations: ['m9♭5'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, true, false, false, false, true, false]) },
    { name: 'Seventh, Flat Fifth, Sharp Ninth', abbreviations: ['7♭5(♯9)'], attributes: [], intervals: new mod.Mod([true, false, false, true, true, false, true, false, false, false, true, false]) },
    { name: 'Seventh, Sharp Eleventh', abbreviations: ['7♯11'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, true, true, false, false, true, false]) },
    { name: 'Major Seventh, Sharp Eleventh', abbreviations: ['maj7♯11', 'M7♯11'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, true, true, false, false, false, true]) },
    { name: 'Augmented', abbreviations: ['aug', '+'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, false, false, true, false, false, false]) },
    { name: 'Seventh, Sharp Fifth', abbreviations: ['7♯5'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, false, false, false, true, false, true, false]) },
    { name: 'Ninth, Sharp Fifth', abbreviations: ['9♯5', '+9'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, false, true, false, true, false]) },
    { name: 'Seventh, Sharp Fifth, Flat Ninth', abbreviations: ['+7♭9', '7♯5♭9'], attributes: [], intervals: new mod.Mod([true, true, false, false, true, false, false, false, true, false, true, false]) },
    { name: 'Seventh, Sharp Fifth, Sharp Ninth', abbreviations: ['+7♯9', '7♯5♯9'], attributes: [], intervals: new mod.Mod([true, false, false, true, true, false, false, false, true, false, true, false]) },
    { name: 'Minor, Flat Sixth', abbreviations: ['mi6', 'm♭6'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, false, false, true, true, false, false, false]) },
    { name: 'Minor, Major Seventh', abbreviations: ['m(maj7)', 'mM7'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, false, false, true, false, false, false, true]) },
    { name: 'Seventh, Sharp Ninth', abbreviations: ['7♯9'], attributes: [], intervals: new mod.Mod([true, false, false, true, true, false, false, true, false, false, true, false]) },
    { name: 'Seventh, Flat Ninth', abbreviations: ['7♭9'], attributes: [], intervals: new mod.Mod([true, true, false, false, true, false, false, true, false, false, true, false]) },
    { name: 'Suspended Second', abbreviations: ['sus2'], attributes: [], intervals: new mod.Mod([true, false, true, false, false, false, false, true, false, false, false, false]) },
    { name: 'Added Ninth', abbreviations: ['add9'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, false, false, false]) },
    { name: 'Minor, Added Ninth', abbreviations: ['m(add9)'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, false, true, false, false, false, false]) },
    { name: 'Suspended Fourth, Added Ninth', abbreviations: ['sus4(add9)'], attributes: [], intervals: new mod.Mod([true, false, true, false, false, true, false, true, false, false, false, false]) },
    { name: 'Ninth', abbreviations: ['9'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, false, true, false]) },
    { name: 'Major Ninth', abbreviations: ['maj9', 'M9'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, false, false, true]) },
    { name: 'Minor Ninth', abbreviations: ['m9'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, false, true, false, false, true, false]) },
    { name: 'Minor Ninth, Major Seventh', abbreviations: ['miMa9'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, false, true, false, false, false, true]) },
    { name: 'Minor Ninth, Suspended Fourth', abbreviations: ['9sus4', 'm9sus4'], attributes: [], intervals: new mod.Mod([true, false, true, false, false, true, false, true, false, false, true, false]) },
    { name: 'Sixth, Added Ninth', abbreviations: ['6/9', '6add9'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, true, false, false]) },
    { name: 'Minor Sixth, Added Ninth', abbreviations: ['m6/9', 'm6add9'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, false, true, false, true, false, false]) },
    { name: 'Eleventh', abbreviations: ['11'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, true, false, true, false, false, true, false]) },
    { name: 'Minor Eleventh', abbreviations: ['m11'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, true, false, true, false, false, true, false]) },
    { name: 'Seventh, Added Eleventh', abbreviations: ['7(add11)'], attributes: [], intervals: new mod.Mod([true, false, false, false, true, true, false, true, false, false, true, false]) },
    { name: 'Minor Seventh, Added Eleventh', abbreviations: ['m7(add11)'], attributes: [], intervals: new mod.Mod([true, false, false, true, false, true, false, true, false, false, true, false]) },
    { name: 'Thirteenth', abbreviations: ['13'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, true, true, false]) },
    { name: 'Major Thirteenth', abbreviations: ['maj13'], attributes: [], intervals: new mod.Mod([true, false, true, false, true, false, false, true, false, true, false, true]) },
    { name: 'Minor Thirteenth', abbreviations: ['m13'], attributes: [], intervals: new mod.Mod([true, false, true, true, false, false, false, true, false, true, true, false]) },
    { name: 'Thirteenth, Suspended Fourth', abbreviations: ['13sus4'], attributes: [], intervals: new mod.Mod([true, false, true, false, false, true, false, true, false, true, true, false]) },
];

function addedIntervalPermutations(scale: ScaleFamily, onlyOneAddedNote): Array<Array<number>> {
    const addedIntervals = [];
    scale.intervals.toArray().map((flag, index) => {
        if (!flag) {
            addedIntervals.push(index);
        }
    });
    const intervals = addedIntervals.map(interval => [interval]);
    if (onlyOneAddedNote) {
        return intervals;
    }
    // @ts-ignore
    const result = addedIntervals.flatMap(
        (v, i) => addedIntervals.slice(i + 1).map( w => [v,  w] )
    );
    return intervals.concat(result);
}

export interface ChartInfo {
    addedNotes: Array<number>;
    positions: Array<boolean>;
}

export function getChordsInScaleWithAddedNotes(chord: ChordType, scale: ScaleFamily, onlyOneAddedNote = false, modeIndex = 0): Array<ChartInfo> {
    const result: Array<ChartInfo> = [{ addedNotes: [], positions: getChordsInScale(chord, scale, [], modeIndex) }];
    addedIntervalPermutations(scale, onlyOneAddedNote).forEach(addedNotes => {
        const positions = getChordsInScale(chord, scale, addedNotes, modeIndex).map((position, index) => {
            return position && !result.find(chartInfo => chartInfo.positions[index] );
        })
        result.push({ addedNotes, positions});
    })
    return result.filter(chartInfo => chartInfo.positions.find(info => info));
}

function getChordsInScale(chord: ChordType, scale: ScaleFamily, addedNotes: Array<number> = [], modeIndex): Array<boolean> {
    const result = Array(scale.intervals.size);
    const savedItems = scale.intervals.items;
    scale.intervals.items = rotate(scale.intervals.toArray().map((interval, index) => interval || addedNotes.indexOf(index) >= 0), modeIndex, 0);
    scale.intervals.toArray().forEach((interval, index) => {
        if (interval) {
            // see if chord is available at this position in scale
            let allChordIntervalsAvailable = true;
            chord.intervals.toArray().forEach((chordInterval, chordIntervalIndex) => {
                if (chordInterval) {
                    if (!scale.intervals.itemAt(index + chordIntervalIndex)) {
                        allChordIntervalsAvailable = false;
                    }
                }
            });
            result[index] = allChordIntervalsAvailable;
        } else {
            result[index] = false;
        }
    });
    scale.intervals.items = savedItems;
    return result;  // map of scale positions where chord can be played
}

export function test(): void {
    // console.log(getChordsInScaleWithAddedNotes(chordTypes[0], scaleFamily[0]));
}
