import { Injectable } from '@angular/core';
import * as SoundFont from 'soundfont-player';
import musyngNames from 'soundfont-player/names/musyngkite.json';
import {InstrumentName, Player} from 'soundfont-player';
import {concatMap, delay} from 'rxjs/operators';
import {from, of, Subject} from 'rxjs';
import {UtilsService} from '../utils/utils.service';
import {fixSharpsAndFlats} from '../best/best.service.utils';
import {SpinnerService} from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  public soundListener = new Subject();

  private instrument: SoundFont.InstrumentName = 'acoustic_grand_piano';
  private instruments = musyngNames.map(name => {
      const description = name
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      return { name, description};
  });
  private players = {};

  constructor(
      private spinnerService: SpinnerService
  ) {
    this.addInstrument('acoustic_grand_piano');
    this.addInstrument('acoustic_guitar_nylon');
  }

  private async addInstrument(name: InstrumentName): Promise<Player> {
    if (!this.players[name]) {
      this.spinnerService.show('Loading instrument');
      this.players[name] = await SoundFont.instrument(new AudioContext(), name);
      this.spinnerService.hide();
    }
    return this.players[name];
  }

  async play(notes: Array<string>, arpeggiate = false, instrument: InstrumentName = null): Promise<void | Player> {
    await this.addInstrument(instrument);
    const player = this.players[instrument ? instrument : this.instrument];
    if (arpeggiate) {
      const source = from(notes).pipe(
          concatMap((value) => {
            return of(value).pipe(delay(500));
          })
      ).subscribe(note => player.play(fixSharpsAndFlats(note)));
    } else {
      notes.forEach(note => {
        player.play(fixSharpsAndFlats(note));
      });
    }
    return player;
  }

  stop(player: Player): void {
      player.stop();
  }

  getInstruments(): Array<any> {
      return this.instruments;
  }

  setInstrument(instrument: SoundFont.InstrumentName): void {
    this.instrument = instrument;
  }

}
