export const chartsHelp = `
<p>The Charts app allows you to see at a glance the fretboard fingering for families of scales and chords.</p>

You can choose how notes are displayed:<br>
    <ul>
        <li>Intervals</li>
        <li>Numbers</li>
        <li>Sharps</li>
        <li>Flats</li>
        <li>Academic Notation</li>
    </ul>
<br><br>
For challenging playability scores, using a barre fingering structure may be needed;
 and sometimes the tuning & chord combination may just not be fully playable.
`;

