import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {IntroDialogComponent, IntroDialogModel} from '../dialogs/introdialog/introdialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public tiles = [
    { title: 'Guitar Players', function: this.showIntroWizard, linkUrl: 'best/guitar', imageUrl: 'assets/images/1920px-The_Edge_playing_Gibson_Les_Paul_in_Manchester_on_Experience_and_Innocence_Tour_10-20-18.jpg', contents: 'The best chord charts ever. Find everything here you need to write songs and collaborate with outher musicians.' },
    { title: 'Piano Players', linkUrl: 'best/piano', imageUrl: 'assets/images/playing-piano.jpg', contents: 'A new way of being a musician. Write songs, collaborate with other musicians.' },
    { title: 'Ukelele Players', imageUrl: 'assets/images/Makes-a-Good-Ukulele-Player5-1.jpg', contents: 'Ukelele players are cool! Join the fun.' },
    { title: 'Songwriting Tips', imageUrl: 'assets/images/chordprogressionE.png', contents: 'Lots to learn. We can help.' },
// tslint:disable-next-line:max-line-length
//    { title: 'Find Students', imageUrl: 'assets/images/guitar-students-in-class-image-2.jpg', contents: 'You have skills. People want to learn' },
    { title: 'Find a Teacher', imageUrl: 'assets/images/bigstock-Teacher-Helping-Teen-Pupil-Wit-3916422.jpg', contents: 'Ready to up your game?' },
    { title: 'Resources', linkUrl: 'resources', imageUrl: 'assets/images/books-521812297.jpg', contents: 'Our recommendations for ways to learn more.' },
  ];

  constructor(
      private router: Router,
      private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  tileClicked(tile): void {
    if (tile.function) {
      tile.function(this);
    } else {
      this.router.navigate([tile.linkUrl]);
    }
  }

  showIntroWizard($this): void {
    const dialogRef = $this.dialog.open(IntroDialogComponent, {
      data: new IntroDialogModel()
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
      }
    });
  }

}
