import {Component, Injector, OnInit} from '@angular/core';
import {ValidatorFn, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {ReusePageBaseComponent} from '../reusepagebase/reusepagebase.component';
import {Router} from '@angular/router';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-formbase',
  templateUrl: './formbase.component.html',
  styleUrls: ['./formbase.component.scss']
})
export class FormbaseComponent extends ReusePageBaseComponent implements OnInit {

  public form: FormGroup;
  public title;
  protected titleBase;
  private titleUpdateFunc;
  protected titleControl = new FormControl('');

  private location: Location;
  private titleService: Title;

  constructor(
      injector: Injector
  ) {
    super(injector);
    this.location = injector.get(Location);
    this.titleService = injector.get(Title);
  }

  ngOnInit(): void {
  }

  setTitle(title: string, titleBase = null, updateFunc =  null): void {
    this.title = title;
    if (titleBase) {
      this.titleBase = titleBase;
    }
    if (updateFunc) {
      this.titleUpdateFunc = updateFunc;
    }
    this.titleService.setTitle(this.titleBase + ' ' + title);
  }

  titleUpdate(): void {
    this.setTitle(this.titleControl.value);
  }

  titleCancel(): void {
  }

  cancelClicked(): void {
    if (this.form.dirty) {

    } else {
      this.location.back();
    }
  }

  submit(value): void {
    this.location.back();
  }

}
