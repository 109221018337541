import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Collection} from '../../services/firestore/collections/collections.service';
import {HistoryService} from '../../services/firestore/history/history.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../dialogs/confirmdialog/confirmdialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  @Input() bookmarks = false;
  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<Collection>();
  public columnsToDisplay = ['_metadata.createdAt', 'name', 'actionmenu'];

  constructor(
      private historyService: HistoryService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private router: Router
  ) { }

  ngOnInit(): void {
    AuthService.loginObservable.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.historyService.getAll(this.bookmarks, items => {
          this.items.data = items;
          this.items.sortingDataAccessor = (item, property) => {
            switch (property) {
              case '_metadata.createdAt': return item._metadata.createdAt;
              default: return item[property];
            }
          };
          this.items.sort = this.sort;
        });
      }
    });
  }

  clearHistory(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm Delete', `Are you sure you want to remove all your ${this.bookmarks ? 'bookmarks' : 'history'}?`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.historyService.deleteAll(this.bookmarks).then(() => {
          this.snackBar.open(`${this.bookmarks ? 'Bookmarks' : 'History'} cleared`);
        });
      }
    });
  }

  gotoHere(item): void {
    this.router.navigate([item.url]);
  }

  deleteItem(item): void {
    this.historyService.delete(item.id).then(() => {
      this.snackBar.open(this.bookmarks ? 'Bookmark deleted' : 'History item deleted');
    });
  }

}
