import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {FormbaseComponent} from '../formbase/formbase.component';
import {SoundService} from '../../services/sound/sound.service';
import {Progression as Progressions, ProgressionsService} from '../../services/firestore/progressions/progressions.service';
import {LeftMenu} from '../../app.component';
import {DataChannelName, DataService} from '../../services/data/data.service';
import * as music from '../../gd/src/music-module';
import {FormBuilder} from '@angular/forms';
import { Note, Scale, Progression } from '@tonaljs/tonal';

@Component({
  selector: 'app-progressions',
  templateUrl: './progressions.component.html',
  styleUrls: ['./progressions.component.scss']
})
export class ProgressionsComponent extends FormbaseComponent implements OnInit {

  public items = new MatTableDataSource<Progressions>();
  public columnsToDisplay = ['name', 'chords', 'description', 'actionmenu'];
  private families = ['Major', 'Minor', 'Modulation'];
  private octaves = ['C2', 'C3', 'C4', 'C5'];
  private playSequences = ['At Once', 'Ascending', 'Descending'];

  constructor(
      private progressionsService: ProgressionsService,
      private formBuilder: FormBuilder,
      injector: Injector

  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('progressions', LeftMenu.PROGRESSIONS);
    const filterForm = {
      key: this.formBuilder.control({value: 6, disabled: false}),
      family: this.formBuilder.control({value: this.families[0], disabled: false}),
      octave: this.formBuilder.control({value: this.octaves[2], disabled: false}),
      loop: this.formBuilder.control({value: false, disabled: false}),
      playsequence: this.formBuilder.control({value: this.playSequences[0], disabled: false}),
    };
    this.form = this.formBuilder.group(filterForm);
    this.form.get('key').valueChanges.subscribe(value => {
      this.setLeadSheetChords();
    });
    this.dataService.send(DataChannelName.APP_PROGRESSION_FORM, {
      form: this.form,
      data: {
        keys: music.noteNames,
        families: this.families,
        octaves: this.octaves,
        playsequences: this.playSequences
      }
    });
    this.progressionsService.getAll(progressions => {
      this.items.data = progressions;
      this.setLeadSheetChords();
    });
  }

  private setLeadSheetChords(): void {
    console.log(this.items.data)
    this.items.data.forEach(item => {
      item.leadSheetChords = Progression.fromRomanNumerals(music.noteNames[this.form.get('key').value], item.chords);
    });
  }

  playProgression(item: any): void {

  }

}
