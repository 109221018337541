import { Injectable } from '@angular/core';
import {Mode, scaleFamily, ScaleFamily} from '../../gd/src/music-module';
import {convertNoteStringByOffset, convertNoteStringToNoteNumber, modulo, normalizeNoteString, rotate} from '../best/best.service.utils';
import {flatNotes, flatOctave, intervalNames, keySignatures, SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import * as music from '../../gd/src/music-module';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  deepEqual(object1, object2): boolean {
    function isObject(object): boolean {
      return object != null && typeof object === 'object';
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
          areObjects && !this.deepEqual(val1, val2) ||
          !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }

  getInstanceName(): string {
    switch (window.location.host.split('.')[0]) {
      case 'app':
        return 'Production';
      case 'qa':
        return 'QA';
    }
  }

  getScaleIntervals(root, type, scale): Array<number> {
    const info = this.getModeAndFamily(type, scale);
    const modeOffset = info.mode.index;
    const intervals = info.family.intervals;
    const rootNoteNumber = convertNoteStringToNoteNumber(root);
    const notes = [];
    intervals.items.forEach((noteFlag, index) => {
      if (intervals.itemAt(index + modeOffset).valueOf()) {
        notes.push(rootNoteNumber + index);
      }
    });
    return notes;
  }

  getNotesInChord(intervals: Array<boolean>, rootIndex: number): Array<number> {
    const returnValue = [];
    intervals.forEach((intervalFlag, index) => {
      if (intervalFlag) {
        returnValue.push(rootIndex + index);
      }
    });
    return returnValue;
  }

  getModeAndFamily(name: string | number, scaleName?: string): {mode: Mode, family: ScaleFamily } | null {
    if (scaleName) {
      const family = scaleFamily.find(scale => scale.name === scaleName);
      const mode = family.modes.find(familyMode => familyMode.name === name || familyMode.modeNumber === name);
      return {mode, family};
    }
    let returnValue = null;
    scaleFamily.forEach(family => {
      const mode = family.modes.find(familyMode => familyMode.name === name);
      if (mode) {
        returnValue = { mode, family};
      }
    });
    return returnValue;
  }

  convertNoteToBaseOctave(note): number {
    // note is 1 to 88, return 1 to 12
    return (note - 1) % SEMITONES_IN_OCTAVE + 1;
  }

  getScaleNotes(root: string, type: string | number, scale: ScaleFamily): Array<number> {
    const modeIndex = scale.modes.find(familyMode => familyMode.name === type || familyMode.modeNumber === type).index;
    const intervals = rotate(scale.intervals.toArray(), modeIndex, 0);
    root = convertNoteStringToNoteNumber(root);
    const notes = [ ];
    intervals.forEach((interval, index) => {
      if (interval) {
        notes.push(this.convertNoteToBaseOctave(root + index));
      }
    });
    return notes;
  }

  sort(fieldName: string, descending: boolean = false): (a, b) => number {
    return (a, b) => {
      const aField = fieldName.split('.').reduce((o, i) => o [i], a);
      const bField = fieldName.split('.').reduce((o, i) => o [i], b);
      if (aField < bField) {
        return descending ? 1 : -1;
      }
      if (aField > bField) {
        return descending ? -1 : 1;
      }
      return 0;
    };
  }

  noteIndexToNoteName(index: number, format, offset = 0, scaleIndex = -1, key = 'C', mode = null): string {
    const isMinorKey = scaleIndex >= 0 && [1, 2, 6, 7, 9].indexOf(scaleIndex) >= 0;
    const keyForMode = 'C';
    let testKey = convertNoteStringToNoteNumber(key);
    if (scaleIndex >= 0) {
      testKey = convertNoteStringToNoteNumber(convertNoteStringByOffset(key, -mode.index).slice(0, -1)) % 12;
    }
    index %= 12;
    const numberIntervalIndex = modulo(index - offset, 12);
    switch (format) {
      case 'hybrid':
        return sharpOctave[index] + (sharpOctave[index] === flatOctave[index] ? '' : flatOctave[index]);
      case 'numbers':
        return flatNotes[numberIntervalIndex];
      case 'academic':
        return keySignatures.find(signature => convertNoteStringToNoteNumber(signature[isMinorKey ? 'minorKey' : 'majorKey']) % 12 === testKey).useSharps ? sharpOctave[index] : flatOctave[index]; // TODO: Use Jon's tables
      case 'intervals':
        return intervalNames[numberIntervalIndex];
      case 'sharps':
        return sharpOctave[index];
      case 'flats':
        return flatOctave[index];
    }
  }

  chordOpenStringOverlap(chart, tuning): number {
    let missingInChord = 0;
    let missingInTuning = 0;
    tuning.forEach(stringNote => {
      if (!chart.notes.find(chordNote => normalizeNoteString(chordNote).slice(0, -1) === stringNote)) {
        missingInChord++;
      }
    });
    chart.notes.forEach(chordNote => {
      if (!tuning.find(stringNote => stringNote === normalizeNoteString(chordNote).slice(0, -1))) {
        missingInTuning++;
      }
    })
    return chart.scale ? -1 : Math.max(missingInChord, missingInTuning);
  }

}
