import {chordTypes, getChordsInScaleWithAddedNotes} from '../../gd/src/chord-module';
import {flatOctave, SEMITONES_IN_OCTAVE, sharpOctave} from '../../data/charts';
import * as parser from 'note-parser';
import {TuningInfo} from '../../gd/src/tuning-module';
import {scaleFamily, ScaleFamily} from '../../gd/src/music-module';

export const getTop5NotesByScale = (scale: ScaleFamily) => {
    const scores = Array(12).fill(0);
    chordTypes.forEach(chordType => {
        const chordsInScale = getChordsInScaleWithAddedNotes(chordType, scale, true);
        chordsInScale.forEach(chord => {
            chord.positions.forEach((position, index) => {
                if (position) {
                    chordType.intervals.toArray().map((intervalPresentInChord, intervalIndex) => {
                        if (intervalPresentInChord) {
                            scores[modulo(index + intervalIndex, SEMITONES_IN_OCTAVE)]++;
                        }
                    });
                }
            });
        });
    });
    scale.score = scores;
    const noteScores = scores.map((score, index) => ({score, index})).sort((a, b) => {
        if (a.score < b.score) {
            return(1);
        }
        if (a.score > b.score) {
            return(-1);
        }
        return(0);
    }).slice(0, 5);
    return noteScores.map(noteScore => noteScore.index).sort((a, b) => {
        if (a > b) {
            return(1);
        }
        if (a < b) {
            return(-1);
        }
        return(0);
    });
};

export const normalizeNoteString = (note: string, useFlats = false): string => {
    return convertNoteNumberToString(convertNoteStringToNoteNumber(note), useFlats);
}

export const convertNoteNumberToString = (note: number, useFlats = false) => {
    const octave = Math.floor((note + 8) / SEMITONES_IN_OCTAVE);
    const noteIndex = modulo(note - 1, SEMITONES_IN_OCTAVE);
    return (useFlats ? flatOctave : sharpOctave)[noteIndex] + octave.toString();
};

// takes a note letter with or without a sharp/flat b/# or an octave number and converts to a 1 - 88 note number
// eg: A# = 2, B1 = 3, Db4 = 41
export const convertNoteStringToNoteNumber = (note: string) => {
    if (note.length === 4) {
        note = note.slice(0, 2);  // get rid of sharp/flat jammer style
    }
    const noteInfo = parser.parse(fixSharpsAndFlats(note));
    return noteInfo.midi ? noteInfo.midi - 20 : noteInfo.chroma + 4;
};

export const convertNoteStringByOffset = (note: string, offset: number) => {
    return convertNoteNumberToString(convertNoteStringToNoteNumber(note) + offset);
};

export const fixSharpsAndFlatsReverse = (note: string): string => {
    return note.replace('b', '♭').replace('#', '♯');
};

export const fixSharpsAndFlats = (note: string): string => {
    return note.replace('♭', 'b').replace('♯', '#');
};

export const modulo = (n: number, m: number): number => {
    return ((n % m) + m) % m;
};

export const removeOctave = (notes: Array<string>): Array<string> => {
    return notes.map(note => note.replace(/\d+$/, ''));
};

// tslint:disable-next-line:max-line-length
export const getBestTunings = (initialTuning: TuningInfo, bassNote: string, top5ScaleNotes: Array<string>, looseMatch: boolean, iterate = true, func = (tuning) => {}): Array<TuningInfo> => {
    top5ScaleNotes = removeOctave(top5ScaleNotes);
    const result: Array<TuningInfo> = [];
    const bassNotes = [5, 7].map(offset => convertNoteStringByOffset(bassNote, offset).slice(0, -1));
    (iterate ? iterateTunings : (tuning, iterator) => iterator(tuning))(initialTuning, tuning => {
        const tuningNotes = tuning.strings.map(tuningString => tuningString.slice(0, -1));
        const uniqueTuningNoteCount = [...new Set(tuningNotes)].length;
        if (uniqueTuningNoteCount >= 3 && uniqueTuningNoteCount <= 5) {
            let matchFlag = false;
            if (looseMatch) {
                matchFlag = (bassNote === tuningNotes[0] || bassNotes.find(bNote => bNote === tuningNotes[0])) ? true : false;
            } else {
                matchFlag = (bassNote === tuningNotes[0] && bassNotes.find(bNote => bNote === tuningNotes[1])) ||
                (bassNote === tuningNotes[1] && bassNotes.find(bNote => bNote === tuningNotes[0])) ? true : false;
            }
            if (matchFlag) {
                const allStringsInTop5ScaleNotes = tuningNotes.every((el) => {
                    return top5ScaleNotes.indexOf(el) !== -1;
                });
                if (allStringsInTop5ScaleNotes) {
                    result.push(tuning);
                }
            }
        }
    });
    func(result);
    return result;
};

export const tuningStringToArray = (tuningString: string): Array<string> => {
    console.log('tuningStringToArray', tuningString)
    const strings = [];
    for (let workStrings = tuningString.split(' ')[0]; workStrings.length > 0;) {
        const stringLength = workStrings[1] === '♭' || workStrings[1] === '♯' ? 2 : 1;
        strings.push(workStrings.slice(0, stringLength));
        workStrings = workStrings.slice(stringLength);
    }
    return strings;
};


export const deepCopy = (item): any => {
    return JSON.parse(JSON.stringify(item));
};

export const rotate = (array, n, offset) => {
    return array.slice(n, array.length).concat(array.slice(0, n).map(item => offset ? item + offset : item));
};

export const iterateTunings = (initialTuning: TuningInfo, iterator: (tuning: TuningInfo) => void): void => {
    const lowDelta = -initialTuning.tuningLimit.downSemitones;
    const tuningScanner = (tuning: TuningInfo, index: number) => {
        const newTuning = deepCopy(tuning);
        if (index === initialTuning.strings.length) {
            iterator(newTuning);
            return;
        }
        // tslint:disable-next-line:max-line-length
        const highDelta = initialTuning.tuningLimit.noUpStrings.find(upString => upString === initialTuning.strings.length - index) ? initialTuning.tuningLimit.upSemitones - 1 : initialTuning.tuningLimit.upSemitones;
        for (let offset = lowDelta; offset <= highDelta; offset++) {
            newTuning.strings[index] = convertNoteStringByOffset(initialTuning.strings[index], offset);
            tuningScanner(newTuning, index + 1);
        }
    };
    tuningScanner(initialTuning, 0);
};

export const getTuningCount = (initialTuning: TuningInfo) => {
    let count = 1;
    for (let i = 1; i <= initialTuning.strings.length; i++) {
        // tslint:disable-next-line:max-line-length
        const highDelta = initialTuning.tuningLimit.noUpStrings.find(upString => upString === initialTuning.strings.length - i) ? initialTuning.tuningLimit.upSemitones - 1 : initialTuning.tuningLimit.upSemitones;
        count *= highDelta + initialTuning.tuningLimit.downSemitones + 1;
    }
    return count;
};


