import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from './loader.service';
import {LoaderState} from './loader';
import {ProgressBarMode} from '@angular/material/progress-bar';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit, OnDestroy {

    show = true;
    mode: ProgressBarMode = 'indeterminate'; // indeterminate/query
    private subscription: Subscription;

    constructor(private loaderService: LoaderService) { }

    ngOnInit(): void {
        this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
            this.show = state.show;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
