import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { PitchDetector } from 'pitchy';
import {convertNoteNumberToString} from '../../services/best/best.service.utils';

@Component({
  selector: 'app-pitchdetector',
  templateUrl: './pitchdetector.component.html',
  styleUrls: ['./pitchdetector.component.scss']
})
export class PitchdetectorComponent implements OnInit, OnDestroy, AfterViewInit  {

  @Output() pitchDetectedEvent: EventEmitter<any> = new EventEmitter<any>();

  private analyzer;
  public info;
  public listening = false;

  private sameCount = 0;
  private oldValue;
  private stream;

  SMOOTH_COUNT = 5;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  private updatePitch(analyserNode, detector, input, sampleRate): void {
    analyserNode.getFloatTimeDomainData(input);
    const [pitch, clarity] = detector.findPitch(input, sampleRate);
    if (clarity >= 0.95) {
      this.info = {note: this.getNote(pitch), accuracy: Math.round(clarity * 100), frequency: Math.round(pitch * 10) / 10};
      if (this.listening) {
        if (this.info.frequency === this.oldValue) {
          ++this.sameCount;
        } else {
          this.sameCount = 0;
          this.oldValue = this.info.frequency;
        }
        if (this.sameCount === this.SMOOTH_COUNT) {
          this.pitchDetectedEvent.emit(this.info);
        }
      }
    }
    window.setTimeout(
        () => this.updatePitch(analyserNode, detector, input, sampleRate),
        100
    );
  }

  getNote(frequency): string {
    const A4 = 440;
    const C0 = Math.round(A4 * Math.pow(2, -4.75)); // 16
    const semiTonesAway = Math.round(12 * Math.log2(frequency / C0));
    return convertNoteNumberToString(semiTonesAway - 8);
  }

  toggleListen(): void {
    if (this.listening) {
      this.stream.getTracks()[0].stop();
    } else {
      this.sameCount = 0;
      const audioContext = new window.AudioContext();
      const analyserNode = audioContext.createAnalyser();
      navigator.mediaDevices.getUserMedia({ audio: {noiseSuppression: true} }).then((stream) => {
        this.stream = stream;
        const sourceNode = audioContext.createMediaStreamSource(stream);
        sourceNode.connect(analyserNode);
        const detector = PitchDetector.forFloat32Array(analyserNode.fftSize);
        const input = new Float32Array(detector.inputLength);
        this.updatePitch(analyserNode, detector, input, audioContext.sampleRate);
      });
    }
    this.listening = !this.listening;
  }

  ngOnDestroy(): void {
    this.stream?.getTracks()[0].stop();
  }

}
