import { Injectable } from '@angular/core';
import {MatSidenav, MatDrawer} from '@angular/material/sidenav';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    private sidenav: MatSidenav|MatDrawer;
    private categories: any;
    private category: string;
    private listingCategory: string;
    private _data: any|any[]|null;
    private current: any;
    private currentCount = false;
    public get data() {
        return this._data;
    };
    atCategory: any;
    categoryOptions: any[];
    breadcrumbs: any[];

    public optionsSubject = new Subject();

    public setSidenav(sidenav: MatSidenav|MatDrawer): void {
        this.sidenav = sidenav;
    }

    public open(data?: any|any[]|null): Promise<any> {
        return this.toggle(data);
    }

    public close(): Promise<any> {
        return this.toggle(null);
    }

    public toggle(data?: any|any[]|null): Promise<any> {
        this._data = data;
        return this.sidenav.toggle();
    }

    public setOptions(categories: any, category: string, listingCategory: string, breadcrumbs?: string[]): void {
        const $this = this;
        $this.categories = categories;
        $this.category = category;
        $this.listingCategory = listingCategory;
        $this.atCategory = $this.categories[$this.category][$this.listingCategory];
        $this.categoryOptions = $this.getKeys($this.atCategory);
        $this.updateOptions($this, breadcrumbs);
    }

    private updateOptions($this, breadcrumbs): void {
        $this.breadcrumbs =
            breadcrumbs && breadcrumbs.length ?
                breadcrumbs : Array.isArray($this.breadcrumbs) ? $this.breadcrumbs : [$this.listingCategory];
        $this.optionsSubject.next({
            categories: $this.categories,
            category: $this.category,
            listingCategory: $this.listingCategory,
            atCategory: $this.atCategory,
            categoryOptions: $this.categoryOptions,
            breadcrumbs: $this.breadcrumbs,
        });
    }

    public getOptions(): any {
        const $this = this;
        return {
            categories: $this.categories,
            category: $this.category,
            listingCategory: $this.listingCategory,
            atCategory: $this.atCategory,
            categoryOptions: $this.categoryOptions,
            breadcrumbs: $this.breadcrumbs,
        };
    }

    public getNextOptions(option): void {
        const $this = this;
        $this.listingCategory = option;
        $this.atCategory = $this.atCategory[$this.listingCategory];
        $this.categoryOptions = $this.getKeys($this.atCategory, true);
        $this.breadcrumbs.push(option);
        $this.updateOptions($this, $this.breadcrumbs);
    }

    public getBreadcrumbIndex(index: number): void {
        const $this = this;
        if (index === $this.breadcrumbs.length) {
            return;
        }
        const next = $this.breadcrumbs[index];
        $this.breadcrumbs = $this.breadcrumbs.splice(0, index);
        $this.atCategory = $this.categories[$this.category];
        $this.breadcrumbs.forEach((item) => $this.atCategory = $this.atCategory[item], $this);
        $this.getNextOptions(next);
    }

    public hasNextCategory(next): boolean {
        if (next === this.current && next && this.current) {
            return this.currentCount;
        } else if (next && typeof next === 'object') {
            this.current = next;
            // tslint:disable-next-line:max-line-length
            this.currentCount = ((Object.keys(next).length) + ((next.callback) ? -1 : 0) + ((next.disabled) ? -1 : 0) + ((next.data) ? -1 : 0)) > 0;
            return this.currentCount;
        }
        return false;
    }

    public getKeys(obj: any, sort?: boolean): any[] {
        const $obj: any[] = obj instanceof Array ? obj : Object.keys(obj);
        return sort ? this.sort($obj) : $obj;
    }

    public sort(arr): [] {
        return arr.sort(($a, $b) => {
            const a = $a.toString().toLowerCase().replace(/\s/, '');
            const b = $b.toString().toLowerCase().replace(/\s/, '');
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return a > b ? 1 : a === b ? 0 : -1;
        });
    }

    constructor() {}
}
