import { Injectable } from '@angular/core';
import firebase from 'firebase';
import {AuthService} from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CloudfunctionsService {

  constructor(
      private authService: AuthService
  ) { }

  getBestKeysAndModesForTuning(tuning): Promise<any> {
    const func = firebase.functions().httpsCallable('keysandmodes');
    return func({ tuning });
  }

  sendContactUsEmail(message: string, imageBlob: any): Promise<any> {
    const func = firebase.functions().httpsCallable('contactus-sendContactUs');
    return func({ message, imageBlob, url: window.location.href });
  }

}
