import { Injectable } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

export enum DataChannelName {
  LEFT_NAV_CONTENTS = 'leftnavcontents',
  APP_CHART_FORM = 'appchartform',
  APP_KEYBOARD_FORM = 'appkeyboardform',
  APP_CHORD_FORM = 'appchordform',
  APP_INSTRUMENT_FORM = 'appinstrumentform',
  APP_SCALE_FORM = 'appscaleform',
  LEFT_FORM_CHANGE = 'leftformchange',
  APP_PRO_FORM = 'pro',
  APP_PROGRESSION_FORM = 'progressionform',
  APP_COMPOSE_FORM = 'composeform'
}

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private sources = {};

  constructor() {
  }

  private makeChannel(name: string): void {
    if (!this.sources[name]) {
      this.sources[name] = new BehaviorSubject(null);
    }
  }

  subscribe(channelName: DataChannelName, func): Subscription  {
    this.makeChannel(channelName);
    return this.sources[channelName].subscribe(func);
  }

  unsubscribe(channelName: DataChannelName): void {
    return this.sources[channelName].unsubscribe();
  }

  send(channelName: DataChannelName, value: any): void {
    this.makeChannel(channelName);
    this.sources[channelName].next(value);
  }

}

