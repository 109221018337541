import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CryptoService} from '../../services/crypto/crypto.service';

@Component({
  selector: 'app-fretboard',
  templateUrl: './fretboard.component.html',
  styleUrls: ['./fretboard.component.scss']
})
export class FretboardComponent implements OnInit {

  public playInstrument;
  public tuning;
  public strings;
  public root;
  public type;
  public currentDots;
  public scaleIndex;
  public chart;

  constructor(
      private route: ActivatedRoute,
      private cryptoService: CryptoService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.info) {
        const decodedInfo = this.cryptoService.decodeJSON(params.info);
        this.playInstrument = decodedInfo.playInstrument;
        this.tuning = decodedInfo.tuning;
        this.strings = decodedInfo.strings;
        this.root = decodedInfo.root;
        this.type = decodedInfo.type;
        this.currentDots = decodedInfo.currentDots;
        this.scaleIndex = decodedInfo.scaleIndex;
        this.chart = decodedInfo.chart;
      }
    });
  }

}
