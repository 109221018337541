import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Collection, CollectionsService} from '../../services/firestore/collections/collections.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../dialogs/confirmdialog/confirmdialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AddDialogComponent, AddDialogModel} from '../dialogs/adddialog/adddialog.component';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {AuthService} from '../../services/auth/auth.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {CollectionItemsDialogComponent, CollectionItemsDialogModel} from '../dialogs/collectionitemsdialog/collectionitemsdialog.component';
import {AnnotationsDialogComponent} from '../dialogs/annotationsdialog/annotationsdialog.component';
import {ParentType} from '../../services/firestore/annotations/annotations.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  public items = new MatTableDataSource<Collection>();
  public columnsToDisplay = ['name', /*'_metadata.createdAt',*/ 'owner', /*'private',*/ 'actionmenu' ];

  constructor(
      private collectionsService: CollectionsService,
      private profileService: ProfileService,
      private authService: AuthService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.collectionsService.getMergedCollections(items => {
      this.items.data = items;
    });
  }

  ngAfterViewInit(): void {
    this.items.sortingDataAccessor = (item, property) => {
      switch (property) {
        case '_metadata.createdAt': return item._metadata.createdAt;
        default: return item[property];
      }
    };
    this.items.sort = this.sort;
  }

  canEdit(collection): boolean {
    return this.collectionsService.canEdit(collection);
  }

  showContents(collection): void {
    const dialogRef = this.dialog.open(CollectionItemsDialogComponent, {
      data: new CollectionItemsDialogModel(`Choose an item to view, update or delete.`, collection.id)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
      }
    });
  }

  addCollection(): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: new AddDialogModel(false, 'Add New Collection', `Enter the name and attributes of the collection you wish to create.`,
          [
            {type: 'input', value: '', controlName: 'name', label: 'Collection Name', required: true },
            {type: 'checkbox', value: false, controlName: 'private', label: 'Private', required: false }
          ]
      )
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionsService.create(dialogResult).then(() => {
          this.snackBar.open('Collection added');
        });
      }
    });
  }

  copyCollection(collection: Collection): void {
    collection.name = collection.name + ' - COPY';
    this.collectionsService.create(collection).then(() => {
      this.snackBar.open('Collection copied');
    });
  }

  editCollection(collection: Collection): void {
    const dialogRef = this.dialog.open(AddDialogComponent, {
      data: new AddDialogModel(true, 'Add New Collection', `Enter the name and attributes of the collection you wish to create.`,
          [
            {type: 'input', value: collection.name, controlName: 'name', label: 'Collection Name', required: true },
            {type: 'checkbox', value: collection.private, controlName: 'private', label: 'Private', required: false }
          ]
      )
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionsService.update(collection.id, dialogResult).then(() => {
          this.snackBar.open('Collection updated');
        });
      }
    });
  }

  deleteCollection(collection: Collection): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('Confirm Delete', `Are you sure you want to delete this collection?`)
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.collectionsService.delete(collection.id).then(() => {
          this.snackBar.open('Collection deleted');
        });
      }
    });
  }

  showAnnotations(collection: Collection): void {
    const dialog = this.dialog.open(AnnotationsDialogComponent);
    dialog.componentInstance.data = { modal: true, message: collection.name, parentType: ParentType.Collection };
  }

}
