import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';

@Pipe({ name: 'safeHTML'})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private dom: DomSanitizer) {}
    transform(value): string {
        return this.dom.sanitize(SecurityContext.HTML, value);
    }
}
