import {Component, ElementRef, Injector, OnInit, Pipe, PipeTransform, QueryList, ViewChildren} from '@angular/core';
import {chartDictionary, SEMITONES_IN_OCTAVE} from '../../data/charts';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {FormbaseComponent} from '../formbase/formbase.component';
import {chordTypes, getChordsInScaleWithAddedNotes} from '../../gd/src/chord-module';
import * as music from '../../gd/src/music-module';
import {Mode, noteNames} from '../../gd/src/music-module';
import * as tuning from '../../gd/src/tuning-module';
import {tuningInfos} from '../../gd/src/tuning-module';
import {Pianokeys} from 'custom-piano-keys';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SoundService} from '../../services/sound/sound.service';
import {UtilsService} from '../../services/utils/utils.service';
import * as mod from '../../gd/src/mod-module';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {LogContext, LogService, LogType} from '../../services/firestore/log/log.service';
import {CryptoService} from '../../services/crypto/crypto.service';
import {MatTabGroup} from '@angular/material/tabs';
import {DataChannelName} from '../../services/data/data.service';
import {LeftMenu} from '../../app.component';
import {
  convertNoteStringByOffset,
  deepCopy, modulo,
  tuningStringToArray
} from '../../services/best/best.service.utils';
import {DownloadService} from '../../services/download/download.service';
import {HistoryService} from '../../services/firestore/history/history.service';
import {ScaleFamily} from '../../gd/src/music-module';
import {FullscreenService} from '../../services/fullscreen/fullscreen.service';
import {GuitarchartComponent} from '../guitarchart/guitarchart.component';
import {pairwise, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent extends FormbaseComponent implements OnInit {

  @ViewChildren('keyboard') keyboards: QueryList<ElementRef>;
  @ViewChildren('tabgroup') tabGroup: MatTabGroup;

  public renderingMode = {
      leftHanded: false,
      format: 'academic',
      notedisplay: 'scale',
      frets: 24,
      instrument: 'G'
  };

  protected tuning = chartDictionary[0].tuning;
  protected strings;

  public charts;
  public allCharts;

  public form;
  private bookmarkWatcherUnsubscribe;
  private item;
  private keyboardsRendered = false;
  private pianokeys;
  private previousChordList;
  private previousScale;
  private currentDots;

  private firstTime = true;
  private currentPage = 0;
  public pageSize = 25;
  public isBookmarked = false;

  public keys = music.noteNames;
  public scales = [{ name: 'All', index: -1 }, ...music.scaleFamily];
  public modes;
  public key;
  public tunings = deepCopy(tuning.tuningInfos);
  public chords = [{ name: 'All', abbreviations: [chordTypes.length], intervals: new mod.Mod([]) }, ...chordTypes];
  public capo = 0;
  private customStrings;
  public chartMode = false;
  private previousMode;

  protected capos = [
    { index: 0, name: 'None' },
    { index: 1, name: 'Fret 1' },
    { index: 2, name: 'Fret 2' },
    { index: 3, name: 'Fret 3' },
    { index: 4, name: 'Fret 4' },
    { index: 5, name: 'Fret 5' },
    { index: 6, name: 'Fret 6' },
    { index: 7, name: 'Fret 7' },
    { index: 8, name: 'Fret 8' },
    { index: 9, name: 'Fret 9' },
    { index: 10, name: 'Fret 10' },
    { index: 11, name: 'Fret 11' },
    { index: 12, name: 'Fret 12' }
  ];

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private snackBar: MatSnackBar,
      private soundService: SoundService,
      private utilsService: UtilsService,
      private profileService: ProfileService,
      private logService: LogService,
      private cryptoService: CryptoService,
      private downloadService: DownloadService,
      private historyService: HistoryService,
      private fullscreenService: FullscreenService,
      injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.setContext('charts', LeftMenu.CHARTS);
    this.setTitle('', 'Chart', () => {});
    this.buildForm();
    this.route.params.subscribe(params => {
      this.item = params.item;
      if (this.item === 'keyboard') {
        this.item = '';
        this.renderingMode.instrument = 'P';
      }
      const args = this.item ? this.cryptoService.decodeJSON(this.item) : {focusNote: 'C', rootKey: 'C', mode: 'Ionian', tuning: 'EADGBE', chord: 'Major'};
      this.customStrings = args.strings;
      if (!args.chord) {
        args.chord = 'Major';
      }
      this.form.get('key').setValue(music.noteNames.map(note => note.name).indexOf(args.rootKey), { emitEvent: false });
      const modeFamily = this.utilsService.getModeAndFamily(args.mode, args.scale);
      this.form.get('mode').setValue(modeFamily.mode.index, { emitEvent: false });
      this.previousMode = modeFamily.mode.index;
      this.previousScale = modeFamily.family.index;
      this.form.get('scale').setValue(this.previousScale, { emitEvent: false });
      let currentTuning = this.tunings.find(aTuning => aTuning.tuning === args.tuning)
      if (currentTuning) {
        this.currentDots = currentTuning.dots;
      } else {
        const dots = this.getDotsFromTuning(args.tuning);
        currentTuning = {strings: args.strings, tuning: args.tuning, description: 'Custom Tuning', dots};
        this.tunings.unshift(currentTuning);
        this.currentDots = dots;
      }
      const chordIndex = chordTypes.map(chord => chord.name).indexOf(args.chord);
      this.previousChordList = [chordIndex];
      this.form.get('chord').setValue(this.previousChordList, {emitEvent: false});
      this.form.get('capo').setValue(0, {emitEvent: false});
      this.clearCharts();
      this.buildCharts(args, chordIndex, modeFamily.family.index);
      this.form.get('tuning').setValue(currentTuning, {emitEvent: false});
      this.allCharts = this.sortCharts(this.allCharts);
      this.setPageSlice();
      this.setBookmarkWatcher();
      this.renderKeyboards();
    });
  }

  getDotsFromTuning(thisTuning: string): any {
    return tuningInfos.find(info => info.strings.length === tuningStringToArray(thisTuning).length).dots;
  }

  private setBookmarkWatcher(): void {
    if (this.bookmarkWatcherUnsubscribe) {
      this.bookmarkWatcherUnsubscribe();
    }
    this.bookmarkWatcherUnsubscribe = this.historyService.isBookmarked(this.makeHistoryState().name, result => {
      this.isBookmarked = result;
    });
  }

  private getFormState(): string {
    return this.cryptoService.decodeJSON({
      ...this.form.values
    });
  }

  private setFormState(stateString: string): void {
    const state = this.cryptoService.decodeJSON(stateString);
    Object.keys(state).forEach(key =>  {
      this.form.get(key).setValue(state[key]);
    });
  }

  public getScaleMode(scaleIndex): Mode {
    const mode = this.form.get('mode').value;
    return music.scaleFamily[scaleIndex].modes.find(thisScaleMode => thisScaleMode.index === mode);
  }

  addBookmark(): void {
    this.addHistoryOrBookmark(true);
  }

  removeBookmark(): void {
    const historyState = this.makeHistoryState();
    this.historyService.delete(this.makeHistoryState().name);
  }

  private addHistoryOrBookmark(bookmark: boolean): void {
    const historyState = this.makeHistoryState();
    this.historyService.create({isBookmark: bookmark, name: historyState.name, url: `charts/${historyState.url}`});
  }

  private makeHistoryState(): {name, url} {
    const key = this.form.get('key').value;
    const scaleMode = this.getScaleMode(this.form.get('scale').value);
    const currentTuning = this.tuning.join('');
    const params = this.cryptoService.encodeJSON( {focusNote: convertNoteStringByOffset(music.noteNames[key].name, scaleMode.index).slice(0, -1), rootKey: music.noteNames[key].name, mode: scaleMode.name ? scaleMode.name : scaleMode.modeNumber, scale: this.getCurrentScale().name, tuning: currentTuning});
    return {name: `Chart: ${music.noteNames[this.form.get('key').value].name} ${this.getCurrentScale().name} ${this.getScaleMode(this.form.get('scale').value).name} ${currentTuning}`,
      url: params};
  }

  getCurrentScale(): ScaleFamily {
    const scaleIndex = this.form.get('scale').value;
    return music.scaleFamily[scaleIndex >= 0 ? scaleIndex : 0];
  }

  private buildChartsFromForm(): void {
    this.closeSideMenu();
    const key = this.form.get('key').value;
    const scales = [this.form.get('scale').value];
    const mode = this.form.get('mode').value;
    const chords = this.form.get('chord').value.filter(value => value !== -1);
    this.clearCharts();
    this.addHistoryOrBookmark(false);
    this.setBookmarkWatcher();
    this.logService.write(LogType.Page, LogContext.Charts, { key, scales, mode, chords })
    switch (this.chartMode/*this.form.get('chartmode').value*/) {
      case false:
        scales.map(scaleIndex => {
          chords.map(chordIndex => {
            const scaleMode = this.getScaleMode(scaleIndex);
            this.buildCharts({
              rootKey: music.noteNames[key].name,
              mode: scaleMode.name,
              tuning: this.tuning.join(''),
              chord: chordTypes[chordIndex].name,
              scale: this.getCurrentScale().name,
              focusNote: music.noteNames[key].name// convertNoteStringByOffset(music.noteNames[key].name, scaleMode.index).slice(0, -1)
            }, chordIndex, scaleIndex);
          });
        });
        this.allCharts = this.sortCharts(this.allCharts);
        break;
      case true:
        this.allCharts = [];
        const rootIndex = this.form.get('rootkey').value;
        chordTypes.forEach(chord => {
          this.allCharts.push(
              {
                root: this.keys[rootIndex].name,
                type: chord.name,
                notes: this.getNoteNamesInScale(chord.intervals.toArray(), rootIndex),
                intervals: this.utilsService.getNotesInChord(chord.intervals.toArray(), rootIndex),
                addedNotes: [],
                scale: false
              }
          );

        });
        break;
    }
    this.setPageSlice();
    this.renderKeyboards();
  }

  private setTopNavMessage(): void {
      // this.topnavService.setTopMessage(this.renderingMode.instrument === 'G' ? 'Fretboard GPS' : 'Keyboard GPS');
  }

  private buildCharts(args: any, chordIndex: number, scaleIndex: number): void {
    console.log('BUILDCHARTS', args, chordIndex, scaleIndex)
    const charts = [ ];
    const scaleChart = {
        root: args.focusNote,
        type: args.mode,
        notes: [],
        intervals: this.utilsService.getScaleIntervals(args.focusNote, args.mode, args.scale).map(interval => interval - 1),
        addedNotes: [],
        scale: true,
        chordIndex,
        scaleIndex,
      };
    this.setTopNavMessage();
    this.tuning = tuningStringToArray(args.tuning);
    this.setTitle(`${scaleChart.root} ${scaleChart.type} ${this.tuning.join('')}`);
    const chordTypeName = args.chord;
    const chordInfo = chordTypes.find(chordType => chordType.name === chordTypeName);
    const rootIndex = noteNames.find(note => note.name === args.rootKey).index;
    const modeFamily = this.utilsService.getModeAndFamily(args.mode, args.scale);
    this.key = music.noteNames[this.form.get('key').value].name;
    this.modes = modeFamily.family.modes;
    if (modeFamily) {
      getChordsInScaleWithAddedNotes(chordInfo, modeFamily.family, false, modeFamily.mode.index).map(chartInfo => {
        chartInfo.positions.forEach((position, index) => {
          if (position) {
            charts.push(
                {
                  root: this.utilsService.noteIndexToNoteName(rootIndex + index, 'hybrid'),
                  type: chordTypeName,
                  notes: this.getNoteNamesInScale(chordInfo.intervals.toArray(), rootIndex + index),
                  intervals: this.utilsService.getNotesInChord(chordInfo.intervals.toArray(), rootIndex + index),
                  addedNotes: this.getNoteNamesFromIndexes(chartInfo.addedNotes, modulo(rootIndex - modeFamily.mode.index, SEMITONES_IN_OCTAVE)),
                  scale: false,
                  chordIndex,
                  scaleIndex
                }
            );
          }
        });
      });
    }
    if (this.allCharts.length === 0) {
      charts.unshift(scaleChart);
    }
    this.allCharts = this.allCharts.concat(charts);
    if (this.firstTime) {
      this.firstTime = false;
      this.updateForm();
    }
    this.setPageSlice();
    this.setStrings();
  }

  private updateForm(): void {
    this.dataService.send(DataChannelName.APP_CHART_FORM, {
      form: this.form,
      data: {
        keys: this.keys,
        scales: this.scales,
        modes: this.modes,
        chords: this.chords,
        tunings: this.tunings, // this.tunings.map(aTuning => `${aTuning.tuning} ${aTuning.description}`),
        instrument: this.renderingMode.instrument,
        capos: this.capos,
        strings: this.customStrings
      }
    });
  }

  private sortCharts(charts): Array<any> {
    switch (this.form.get('sort').value) {
      case 'B':
        return charts.sort((a, b) => {
          let aCount = a.scale ? 0 : this.utilsService.chordOpenStringOverlap(a, this.tuning);
          let bCount = b.scale ? 0 : this.utilsService.chordOpenStringOverlap(b, this.tuning);
          if (aCount < 0) {
            aCount = 999;
          }
          if (bCount < 0) {
            bCount = 999;
          }
          return aCount > bCount ? 1 : aCount === bCount ? 0 : -1;
        });
      case 'C':
        return charts.sort((a, b) => {
          const aCount = a.chordIndex;
          const bCount = b.chordIndex;
          return aCount > bCount ? 1 : aCount === bCount ? 0 : -1;
        });
      case 'S':
        return charts.sort((a, b) => {
          const aCount = a.scaleIndex;
          const bCount = b.scaleIndex;
          return aCount > bCount ? 1 : aCount === bCount ? 0 : -1;
        });
    }
  }

  private setModesForScale(): void {
    this.modes = music.scaleFamily[this.form.get('scale').value].modes;
  }

  private buildForm(): void {
    const searchForm = {
      instrument: this.formBuilder.control({value: this.renderingMode.instrument, disabled: false}),
      orientation: this.formBuilder.control({value: this.renderingMode.leftHanded ? 'L' : 'R', disabled: false}),
      frets: this.formBuilder.control({value: this.renderingMode.frets.toString(), disabled: false}),
      format: this.formBuilder.control({value: this.renderingMode.format, disabled: false}),
      notedisplay: this.formBuilder.control({value: 'scale', disabled: false}),
      key: this.formBuilder.control({value: 0, disabled: false}),
      rootkey: this.formBuilder.control({value: 0, disabled: false}),
      scale: this.formBuilder.control({value: 0, disabled: false}),
      mode: this.formBuilder.control({value: 0, disabled: false}),
      tuning: this.formBuilder.control({value: 0, disabled: false}),
      capo: this.formBuilder.control({value: 0, disabled: false}),
      chord: this.formBuilder.control({value: 0, disabled: false}),
      sort: this.formBuilder.control({value: 'B', disabled: false}),
      playInstrument: this.formBuilder.control({ value: 3, disabled: false}),
      findchord: this.formBuilder.control(''),
      chartmode: this.formBuilder.control(false)
    };
    this.form = this.formBuilder.group(searchForm);
    this.form.get('orientation').valueChanges.subscribe((nv) => {
      this.renderingMode.leftHanded = nv === 'L';
      this.renderingMode = deepCopy(this.renderingMode);
      this.setStrings();
      setTimeout(() => {
        this.triggerChartUpdate();
      }, 100);
    });
    this.form.get('tuning').valueChanges.subscribe((nv) => {
      const thisTuning = nv.tuning;
      this.customStrings = nv.strings;
      this.tuning = tuningStringToArray(thisTuning);
      this.currentDots = this.getDotsFromTuning(thisTuning);
      this.setStrings();
      this.buildChartsFromForm();
    });
    this.form.get('instrument').valueChanges.subscribe((nv) => {
      this.renderingMode.instrument = nv;
      this.setTopNavMessage();
      this.triggerChartUpdate();
      this.renderKeyboards();
    });
    this.form.get('frets').valueChanges.subscribe((nv) => {
      this.renderingMode.frets = parseInt(nv, 10);
      this.renderingMode = deepCopy(this.renderingMode);
      this.triggerChartUpdate();
    });
    this.form.get('capo').valueChanges.subscribe((value) => {
      this.capo = value;
      this.buildChartsFromForm();
    });
    /*
    this.form.get('chartmode').valueChanges.subscribe((value) => {
      this.buildChartsFromForm();
    });
     */
    this.form.get('playInstrument').valueChanges.subscribe((value) => {
      this.buildChartsFromForm();
    });
    this.form.get('format').valueChanges.subscribe((nv) => {
      this.renderingMode.format = nv;
      this.renderingMode = deepCopy(this.renderingMode);
      this.triggerChartUpdate();
    });
    this.form.get('notedisplay').valueChanges.subscribe((nv) => {
      this.renderingMode.notedisplay = nv;
      this.renderingMode = deepCopy(this.renderingMode);
      this.triggerChartUpdate();
    });
    this.form.get('key').valueChanges.subscribe((value) => {
      this.buildChartsFromForm();
    });
    this.form.get('rootkey').valueChanges.subscribe((value) => {
      this.buildChartsFromForm();
    });
    this.form.get('scale').valueChanges.subscribe((value) => {
      this.setModesForScale();
      const scaleFormElement = this.form.get('scale');
      this.previousScale = scaleFormElement.value;
      this.form.get('mode').setValue(0, {emitEvent: false});
      this.buildChartsFromForm();
      this.updateForm();
    });
    this.form.get('mode').valueChanges.subscribe((value) => {
      if (this.form.get('chartmode').value) {
        const scale = music.scaleFamily[this.form.get('scale').value];
        console.log('******', this.previousMode, value, this.form.get('key').value, scale);
        const newKeyIndex = modulo(noteNames[this.form.get('key').value].index + value - this.previousMode, SEMITONES_IN_OCTAVE);
        const newKey = noteNames.map(note => note.index).indexOf(newKeyIndex);
        console.log('NEWKEY', newKey, newKeyIndex)
        this.form.get('key').setValue(newKey);
        this.previousMode = value;
      }
      this.buildChartsFromForm();
    } );
    this.form.get('chord').valueChanges.subscribe((value) => {
        const previousAllChords = this.previousChordList.find(chordIndex => chordIndex === -1);
        const thisAll = value.find(chordIndex => chordIndex === -1);
        const chordFormElement = this.form.get('chord');
        if (previousAllChords ? !thisAll : thisAll) {
          if (thisAll) {
            chordFormElement.setValue([...Array(chordTypes.length + 1)].map((_, i) => i - 1), { emitEvent: false });
          } else {
            chordFormElement.setValue([], { emitEvent: false });
          }
        }
        this.previousChordList = chordFormElement.value;
        this.buildChartsFromForm();
    });
    this.form.get('sort').valueChanges.subscribe((value) => {
      this.buildChartsFromForm();
    });
  }

  private triggerChartUpdate(): void {
    this.charts = this.charts.map(chart => Object.assign({}, chart));
    this.closeSideMenu();
  }

  closeSideMenu(): void {
    this.dataService.send(DataChannelName.LEFT_FORM_CHANGE, 'charts');
  }

  pageEvent(event): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.setPageSlice();
  }

  private setPageSlice(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.allCharts.slice(start, end);
    this.charts = part;
}

  clearCharts(): void {
    this.allCharts = this.charts = [];
    this.currentPage = 0;
  }

  renderKeyboards(): void {
    setTimeout(() => {
      this.keyboardsRendered = true;
      const elements = [...document.getElementsByTagName('custom-piano-keys')];
      elements.forEach(element => element.parentNode.removeChild(element));
      this.keyboards.toArray().forEach((div, index) => {
        this.pianokeys = document.createElement('custom-piano-keys') as Pianokeys;
        let markedKeys = this.charts[index].intervals.map(interval => interval - 2);
        if (markedKeys.find(key => key <= 0)) {
          markedKeys = markedKeys.map(key => key + 12);
        }
        this.pianokeys.setAttribute('marked-keys', markedKeys.join(' '));
        this.pianokeys.setAttribute('oct-count', '2');
        this.pianokeys.setAttribute('height', '200');
        div.nativeElement.appendChild(this.pianokeys);
      });
    }, 100);
  }

  getNoteNamesInScale(intervals: Array<boolean>, rootIndex: number): Array<string> {
    const returnValue = [];
    intervals.forEach((intervalFlag, index) => {
      if (intervalFlag) {
        returnValue.push(this.utilsService.noteIndexToNoteName(rootIndex + index, 'hybrid'));
      }
    });
    return returnValue;
  }

  getNoteNamesFromIndexes(noteIntervals: Array<number>, rootIndex: number): Array<string> {
    return noteIntervals.map(noteInterval => this.utilsService.noteIndexToNoteName(noteInterval + rootIndex, 'hybrid'));
  }

  setStrings(): void {
    const sequence = this.stringSequence();
    this.strings = sequence.map((_, index) => {
      const stringNumber = this.renderingMode.leftHanded ? index + 1 : sequence.length - index;
      return {
        number: stringNumber,
        note: sequence[index]
      };
    });
  }

  stringSequence(obeyHandedness = true): Array<string> {
    const pitchedTuning = tuningInfos.find(tuningInfo => tuningInfo.tuning === this.tuning.join(''));
    if (!pitchedTuning) {
      const tuningStrings = this.customStrings;
      return (this.renderingMode.leftHanded && obeyHandedness) ? tuningStrings.reverse() : tuningStrings;
    }
    return (this.renderingMode.leftHanded && obeyHandedness) ? pitchedTuning.strings.reverse() : pitchedTuning.strings;
  }

  zoomClicked(chart): void {
    const index = this.charts.indexOf(chart);
    this.logService.write(LogType.Play, LogContext.Zoom, {chart});

    this.fullscreenService.open(this.keyboards.toArray()[index].nativeElement);
  }

}

