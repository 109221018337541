import * as d3 from 'd3';
import * as events from './events-module';
import * as music from './music-module';

interface NoteCircleState {
    noteSegments: d3.Selection<Segment>;
    noteText: d3.Selection<Segment>;
    intervalSegments: d3.Selection<Segment>;
    intervalText: d3.Selection<Segment>;
    intervalNotes: d3.Selection<Segment>;
    chordText: d3.Selection<Segment>;
    chordSegments: d3.Selection<Segment>;
    chordNotes: d3.Selection<Segment>;
}

export class NoteCircle {
    indexer: (x: Segment) => string = (x) => x.index + '';

    constructor(svg: d3.Selection<any>, noteIndexes: number[], label: string) {
        let state = this.draw(svg, rotate(noteIndexes, 3), label);
        let setCToNoonSubscriptionIndex = -1;

        events.scaleChange.subscribe(scaleChnaged => {
            this.update(scaleChnaged, state);

            setCToNoonSubscriptionIndex = events.setCToNoon.resubscribe(setCToNoonEvent => {
                const offset = setCToNoonEvent.isC ? 3 : 0;
                svg.selectAll('*').remove();
                state = this.draw(svg, rotate(noteIndexes, offset), label);
                this.update(scaleChnaged, state);
            }, setCToNoonSubscriptionIndex);
        });
    }

    draw(svg: d3.Selection<any>, noteIndexes: number[], label: string): NoteCircleState {
        const pad = 50;

        const chordRadius = 240;
        const noteRadius = 200;
        const degreeRadius = 135;
        const innerRadius = 90;

        const cof = svg
            .append('svg')
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', '0 0 500 500')
            .classed('svg-content', true)
            .append('g')
            .attr('transform', 'translate(' + (noteRadius + pad) + ', ' + (noteRadius + pad) + ')');

        cof.append('text')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', 0)
            .text(label);

        const segments = generateSegments(noteIndexes);

        const noteArc = d3.arc<Segment>()
            .innerRadius(degreeRadius)
            .outerRadius(noteRadius);

        const degreeArc = d3.arc<Segment>()
            .innerRadius(innerRadius)
            .outerRadius(degreeRadius);

        const chordArc = d3.arc<Segment>()
            .innerRadius(noteRadius)
            .outerRadius(chordRadius);

        const noteSegments = cof.append('g').selectAll('path')
            .data(segments, this.indexer)
            .enter()
            .append('path')
            .attr('d', noteArc)
            .attr('class', 'note-segment')
            .on('click', handleNoteClick);

        const noteText = cof.append('g').selectAll('text')
            .data(segments)
            .enter()
            .append('text')
            .attr('x', (x) => noteArc.centroid(x)[0])
            .attr('y', (x) => noteArc.centroid(x)[1] + 11)
            .text('')
            .attr('class', 'note-segment-text');

        const intervalSegments = cof.append('g').selectAll('path')
            .data(segments, this.indexer)
            .enter()
            .append('path')
            .attr('d', degreeArc)
            .attr('class', 'interval-segment')
            .on('click', handleIntervalClick);

        const intervalNotes = cof.append('g').selectAll('circle')
            .data(segments, this.indexer)
            .enter()
            .append('circle')
            .style('pointer-events', 'none')
            .attr('r', 25)
            .attr('cx', (x) => degreeArc.centroid(x)[0])
            .attr('cy', (x) => degreeArc.centroid(x)[1])
            .attr('class', 'interval-note');

        const intervalText = cof.append('g').selectAll('text')
            .data(segments, this.indexer)
            .enter()
            .append('text')
            .attr('x', (x) => degreeArc.centroid(x)[0])
            .attr('y', (x) => degreeArc.centroid(x)[1] + 8)
            .text('')
            .attr('class', 'degree-segment-text');

        const chordSegments = cof.append('g').selectAll('path')
            .data(segments, this.indexer)
            .enter()
            .append('path')
            .attr('d', chordArc)
            .attr('class', 'chord-segment')
            .on('click', handleChordClick);

        const chordNotes = cof.append('g').selectAll('circle')
            .data(segments, this.indexer)
            .enter()
            .append('circle')
            .style('pointer-events', 'none')
            .attr('r', 28)
            .attr('cx', (x) => chordArc.centroid(x)[0])
            .attr('cy', (x) => chordArc.centroid(x)[1])
            .attr('class', 'chord-segment-note');

        const chordText = cof.append('g').selectAll('text')
            .data(segments, this.indexer)
            .enter()
            .append('text')
            .attr('x', (x) => chordArc.centroid(x)[0])
            .attr('y', (x) => chordArc.centroid(x)[1] + 8)
            .text('')
            .attr('class', 'degree-segment-text');

        return {
            noteSegments,
            noteText,
            intervalSegments,
            intervalNotes,
            intervalText,
            chordSegments,
            chordNotes,
            chordText
        };
    }

    update(scaleChnaged: events.ScaleChangedEvent, state: NoteCircleState): void {
        const data: Segment[] = scaleChnaged.nodes.map(node => ({
                startAngle: 0,
                endAngle: 0,
                scaleNote: {},
                index: node.scaleNote.note.index,
                node
            } as Segment));

        state.noteSegments
            .data(data, this.indexer)
            .attr('class', (d, i) => 'note-segment ' +
                (d.node.scaleNote.isScaleNote ? ((i === 0) ? 'note-segment-tonic' : 'note-segment-scale') : ''));

        state.noteText
            .data(data, this.indexer)
            .text(d => d.node.scaleNote.note.label);

        state.intervalSegments
            .data(data, this.indexer)
            .attr('class', d => d.node.scaleNote.isScaleNote ? 'degree-segment-selected' : 'interval-segment');

        state.intervalText
            .data(data, this.indexer)
            .text(d => d.node.intervalName);

        state.intervalNotes
            .data(data, this.indexer)
            .attr('class', d => d.node.toggle ? 'interval-note-selected' : 'interval-note')
            .style('fill', d => d.node.toggle ? '#' + d.node.chordInterval.colour.toString(16) : 'none')
            .style('stroke-width', d => d.node.midiToggle ? '20px' : '2px')
            .style('stroke', d => d.node.midiToggle ? 'OrangeRed' : d.node.toggle ? 'black' : 'none');

        state.chordText
            .data(data, this.indexer)
            .text(d => d.node.scaleNote.chord.romanNumeral + '');

        state.chordSegments
            .data(data, this.indexer)
            .attr('class', d => d.node.scaleNote.isScaleNote ? getChordSegmentClass(d.node.scaleNote.chord!) : 'chord-segment');

        state.chordNotes
            .data(data, this.indexer)
            .attr('class', d => d.node.isChordRoot ? getChordSegmentClass(d.node.scaleNote.chord!) : 'chord-segment-note');
    }
}

function getChordSegmentClass(chord: music.Chord): string {
    if (chord.type === music.ChordType.Diminished) { return 'chord-segment-dim'; }
    if (chord.type === music.ChordType.Augmented) { return 'chord-segment-aug'; }
    if (chord.type === music.ChordType.Minor) { return 'chord-segment-minor'; }
    if (chord.type === music.ChordType.Major) { return 'chord-segment-major'; }
    throw new Error('Unexpected ChordType');
}

function generateSegments(fifths: number[]): Segment[] {
    const count = fifths.length;
    const items: Array<Segment> = [];
    const angle = (Math.PI * (2 / count));
    for (let i = 0; i < count; i++) {
        const itemAngle = (angle * i) - (angle / 2);
        items.push({
            startAngle: itemAngle,
            endAngle: itemAngle + angle,
            index: fifths[i],
            node: music.nullNode
        });
    }
    return items;
}

function handleNoteClick(d, segment: Segment, i: number): void {
    events.tonicChange.publish({
        noteSpec: replaceDoubleSharpsAndFlatsWithEquivalentNote(segment.node.scaleNote.note)
    });
}

function replaceDoubleSharpsAndFlatsWithEquivalentNote(noteSpec: music.NoteSpec): music.NoteSpec {
    if (Math.abs(noteSpec.offset) > 1) {
        const naturalId = noteSpec.natural.id;
        const newNaturalId = (noteSpec.offset > 0)
            ? naturalId + 1 % 7
            : naturalId === 0 ? 6 : naturalId - 1;
        const newNatural = music.naturals.filter(x => x.id === newNaturalId)[0];
        return music.createNoteSpec(newNatural.index, noteSpec.index);
    }
    return noteSpec;
}

function handleChordClick(d, segment: Segment, i: number): void {
    events.chordChange.publish({ chordIndex: segment.node.scaleNote.note.index });
}

function handleIntervalClick(d, segment: Segment, i: number): void {
    events.toggle.publish({ index: segment.node.scaleNote.note.index });
}

function rotate(array: number[], offset: number): number[] {
    const newArray: number[] = [];
    for (const item of array) {
        newArray.push((item + offset) % 12);
    }
    return newArray;
}

interface Segment {
    readonly startAngle: number;
    readonly endAngle: number;
    readonly index: number;
    readonly node: music.Node;
}

