import { Component, OnInit } from '@angular/core';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {FormBuilder} from '@angular/forms';
import {DataChannelName, DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-scales-form',
  templateUrl: './scales-form.component.html',
  styleUrls: ['./scales-form.component.scss', '../sidenav-base/sidenav-base.component.scss']
})
export class ScalesFormComponent extends SidenavBaseComponent implements OnInit {

  constructor(
      formBuilder: FormBuilder,
      dataService: DataService
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
    this.subscribeToChannel(DataChannelName.APP_SCALE_FORM);
  }

}
