import {Component, Injector, OnInit} from '@angular/core';
import {FormbaseComponent} from '../formbase/formbase.component';
import {Location} from '@angular/common';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {Title} from '@angular/platform-browser';
import {SoundService} from '../../services/sound/sound.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends FormbaseComponent implements OnInit {

  public instruments;
  private player;
  private playerTimeout;
  private playingInstrument;

  constructor(
      private thisLocation: Location,
      private profileService: ProfileService,
      private formBuilder: FormBuilder,
      private soundService: SoundService,
      injector: Injector
  ) {
    super(injector);
    this.instruments = soundService.getInstruments();
  }

  ngOnInit(): void {
    this.setTitle('', 'Profile', () => {});
    this.profileService.get().then(profile => {
      this.form = this.formBuilder.group({
        displayName: new FormControl(profile.displayName, Validators.required),
        instrument: new FormControl(profile.instrument),
        frets: new FormControl(profile.frets),
        handedness: new FormControl(profile.handedness),
        format: new FormControl(profile.format),
        playbackInstrument: new FormControl(profile.playbackInstrument),
      });
    });
  }

  submit(values): void {
    this.profileService.set(values).then(() => {
      this.thisLocation.back();
    });
  }

  instrumentEntered(event, instrument): void {
    if (instrument === this.playingInstrument) {
      return;
    }
    if (this.player) {
      clearTimeout(this.playerTimeout);
      this.soundService.stop(this.player);
      this.player = undefined;
      this.playingInstrument = undefined;
    }
    this.soundService.setInstrument(instrument.name);
    this.playerTimeout = setTimeout(() => {
      this.playingInstrument = instrument;
      this.soundService.play(['C4']).then(player => {
        this.player = player;
      });
    }, 1000);
  }
}
