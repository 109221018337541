import { Injectable } from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Firestore, FirestoreService } from '../firestore';
import {ProfileService} from '../profile/profile.service';

export enum ParentType {
  None = 'none',
  Collection = 'collection',
  Chart = 'chart',
  Chord = 'chord',
  Scale = 'scale',
  Instrument = 'instrument'
}

export enum AnnotationType {
  Text = 'text',
  Audio = 'audio'
}

export interface Annotation {
  id: string;
  _metadata: Firestore;
  parentId: string;
  parentType: ParentType;
  private: boolean;
  type: AnnotationType;
  contents: any;
}

@Injectable({
  providedIn: 'root'
})

export class AnnotationsService extends FirestoreService {

  constructor(
      public authService: AuthService,
      private profileService: ProfileService
  ) {
    super(authService);
    this.collectionName = 'annotations';
  }

  get(id: string): Promise<Annotation | boolean> {
    return this.docFromCollection(id);
  }

  getAll(resultFunc: (array) => void, context: string = null, type: ParentType): void {
    let query = this.db.collection(this.collectionName).where('_metadata.ownerId', '==', this.authService.getUserInfo().uid);
    if (context) {
      query = query.where('parentId', '==', context);
    }
    if (type !== ParentType.None) {
      query = query.where('parentType', '==', type);
    }
    query.onSnapshot(querySnapshot => this.addOwners(this.profileService, resultFunc)(querySnapshot.docs.map(doc =>  {
      const data = doc.data(); data.id = doc.id; return data; })));
  }

  getPublic(resultFunc, context: string = null, type: ParentType): void {
    // tslint:disable-next-line:max-line-length
    let query = this.db.collection(this.collectionName).where('_metadata.ownerId', '!=', this.authService.getUserInfo().uid).where('private', '==', false);
    if (context) {
      query = query.where('parentId', '==', context);
    }
    if (type !== ParentType.None) {
      query = query.where('parentType', '==', type);
    }
    query.onSnapshot(querySnapshot => this.addOwners(this.profileService, resultFunc)(querySnapshot.docs.map(doc =>  {
      const data = doc.data(); data.id = doc.id; return data; })));
  }

  getMergedCollections(resultFunc, context: string = null, type: ParentType): void {
    let myCollections = [];
    let publicCollections = [];
    function mergeCollections(): void {
      resultFunc([...myCollections, ...publicCollections]);
    }
    this.getAll(items => {
      myCollections = items;
      mergeCollections();
    }, context, type);
    this.getPublic(items => {
      publicCollections = items;
      mergeCollections();
    }, context, type);
  }

  create(data): Promise<string | null> {
    return this.addDocument(this.authService.getUserInfo().uid, data);
  }

  update(id: string, data): Promise<boolean> {
    return this.updateDocument(id, data);
  }

  delete(id: string): Promise<boolean> {
    return this.deleteDocument(id);
  }

  canEdit(annotation: Annotation): boolean {
    return annotation._metadata.ownerId === this.authService.getUserId();
  }

}
