import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {NavigationEnd, Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import {SidenavService} from './services/sidenav/sidenav.service';
import {ToolsService} from './services/tools/tools.service';
import {RouterOutlet} from '@angular/router/router';
import {appRoutingAnimations} from './app-routing.animations';
import {BottomsheetService} from './components/idx-mat-bottom-sheet/idx-mat-bottom-sheet.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IdxSystemNoticeService} from './services/idx-system-notice/idx-system-notice.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {map} from 'rxjs/operators';
import {IDCConstantsService} from './services/constants/constants.service';
import {AuthService} from './services/auth/auth.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {PresenceService} from './services/firestore/presence/presence.service';
import {LogContext, LogService, LogType} from './services/firestore/log/log.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DataChannelName, DataService} from './services/data/data.service';
import {SpinnerService} from './services/spinner/spinner.service';
import {chartsHelp} from './help/charts';
import {ChargebeeService} from './services/chargebee/chargebee.service';
import {ProfileService, Role} from './services/firestore/profile/profile.service';
import {SoundService} from './services/sound/sound.service';
import {CanvasWhiteboardComponent, CanvasWhiteboardOptions, CanvasWhiteboardShapeService, SmileyShape} from 'ng2-canvas-whiteboard';
import {ConfirmDialogComponent, ConfirmDialogModel} from './components/dialogs/confirmdialog/confirmdialog.component';
import {ContactUsDialogComponent, ContactUsDialogModel} from './components/dialogs/contactusdialog/contactusdialog.component';
import {CloudfunctionsService} from './services/firestore/cloudfunctions/cloudfunctions.service';
import {DownloadService} from './services/download/download.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RemoteConfigService} from './services/firestore/remoteconfig/remoteconfig.service';

// import {chordChartsAnimations} from "./chord-charts/chord-charts.animations";
export enum LeftMenu {
    CHARTS = 'Fretboard GPS',
    KEYBOARDCHARTS = 'Keyboard GPS',
    BEST = 'Best Chord Charts',
    CHORDS = 'Chord GPS',
    SCALES = 'Scale GPS',
    INSTRUMENTS = 'Tuning GPS',
    PRO = 'PRO Instrument',
    PROGRESSIONS = 'Progressions'
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    viewProviders: [CanvasWhiteboardComponent],
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        appRoutingAnimations,
        trigger('menuAnimation', [
            state('opened', style({ opacity: 1, width: '100%' })),
            state('closed', style({ opacity: 0, width: '0px' })),
            transition('* => *', [
                animate(500)
            ])
        ]),
        trigger('appAnimation', [
            state('opened', style({ opacity: 1, height: '100%' })),
            state('closed', style({ opacity: 1, height: '24px' })),
            transition('* => *', [
                animate(500)
            ])
        ])
    ]
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('sidenav') public sidenav: MatSidenav|null = null;
    @ViewChild('guardWarningTemplate') guardWarningTemplate: TemplateRef<ElementRef>;
    categories: any;
    category: string;
    listingCategory: string;
    atCategory: any;
    categoryOptions: any[];
    breadcrumbs: any[];
    private sidenavOpenStartSubscription: Subscription;
    public newCollectionForm: FormGroup;
    public IDC: IDCConstantsService;

    public fullscreen = false;
    public mobile: boolean;
    public mobileObserver: Observable<boolean>;
    public mobileObservableSubscription: Subscription;
    public isLoggedIn;
    public userInfo = {};
    public navitemstate = 'opened';
    public rightnavitemstate = 'closed';
    public rightNavLocked = false;
    public leftNavLocked = true;
    public appstate = 'opened';
    public ready = false;
    public leftMenu;
    public topMessage;
    public showLoading = false;
    public spinnerMessage;
    public isAdmin;
    public drawEnabled = false;
    private message;

    public canvasOptions: CanvasWhiteboardOptions = {
        drawButtonEnabled: true,
        drawButtonClass: 'drawButtonClass',
        drawButtonText: 'Cancel',
        drawingEnabled: true,
        clearButtonEnabled: true,
        clearButtonClass: 'clearButtonClass',
        clearButtonText: 'Clear',
        undoButtonText: 'Undo',
        undoButtonEnabled: true,
        redoButtonText: 'Redo',
        redoButtonEnabled: true,
        colorPickerEnabled: true,
        fillColorPickerText: 'Fill',
        strokeColorPickerText: 'Stroke',
        strokeColor: 'rgba(255,0,0)',
        startingColor: 'rgba(0,0,0,0)',
        saveDataButtonEnabled: true,
        saveDataButtonText: 'Send',
        lineWidth: 3,
        shouldDownloadDrawing: false
    };

    LeftMenu = LeftMenu;

    private forwardFunctions: any[] = [];

    private topTitle = {
        '/scales': 'Scale GPS',
        '/best/guitar': 'Best Chord Charts',
        '/charts': 'Fretboard GPS',
        '/charts/keyboard': 'Keyboard GPS',
        '/instruments': 'Tuning GPS',
        '/chords': 'Chord GPS',
        '/pro': 'PRO Instrument'
    };

    constructor(private changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
                private sidenavService: SidenavService,
                private router: Router,
                @Inject(DOCUMENT) private document: Document,
                public bottomsheetService: BottomsheetService,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                public bottomsheet: MatBottomSheet,
                public breakpointObserver: BreakpointObserver,
                private authService: AuthService,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private presenceService: PresenceService,
                private logService: LogService,
                private dataService: DataService,
                private spinnerService: SpinnerService,
                private chargebeeService: ChargebeeService,
                private profileService: ProfileService,
                private soundService: SoundService,
                private cloudFunctionsService: CloudfunctionsService,
                private canvasWhiteboardShapeService: CanvasWhiteboardShapeService,
                private downloadService: DownloadService,
                private remoteConfigService: RemoteConfigService,
                public dialog: MatDialog) {
        const $this = this;

        this.canvasWhiteboardShapeService.unregisterShapes([SmileyShape]);

        AuthService.loginObservable.subscribe(value => {
            this.isLoggedIn = value;
            $this.userInfo = this.authService.getUserInfo();
            if (value) {
                this.profileService.get().then(profile => {
                    this.isAdmin = profile.role === Role.ADMIN;
                });
            }
        });
        dataService.subscribe(DataChannelName.LEFT_NAV_CONTENTS, value => {
            this.leftMenu = value;
        });
        this.spinnerService.visibility.subscribe(visible => {
            this.showLoading = visible;
            this.spinnerMessage = this.spinnerService.message;
        })

        $this.IDC = new IDCConstantsService(changeDetectorRef, media);

        // Observe screen size changes
        $this.mobileObserver = breakpointObserver.observe([
            Breakpoints.HandsetPortrait
        ]).pipe(map(res => res.matches));

        $this.mobileObservableSubscription = $this.mobileObserver.subscribe((isMobile: boolean) => {
            $this.mobile = isMobile;
        });
        // Form setup: new collection
        $this.newCollectionForm = this.formBuilder.group({
            newCollectionName: this.formBuilder.control(''),
            collectionID: this.formBuilder.control(''),
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.fullscreen = event.urlAfterRedirects.includes('fretboard') || event.urlAfterRedirects.includes('guestintro');
                this.presenceService.clear();
                setTimeout(() => {
                    this.topMessage = this.topTitle[event.urlAfterRedirects];
                }, 100);
            }
        });

        this.dataService.subscribe(DataChannelName.LEFT_FORM_CHANGE, data => {
            if (data) {
                if (!this.leftNavLocked) {
                    this.navitemstate = 'closed';
                }
            }
        });
    }

    /*
    getTopMessage(): string {
        return this.topMessage;
    }
     */

    getLeftMenu(): string {
        return this.leftMenu;
    }

    getHelpContext(): string {
        switch (this.leftMenu) {
            case LeftMenu.CHARTS:
                return chartsHelp;
        }
        return '';
    }

    openBottomSheet(template: string): void {
        const $this = this;
        $this.bottomsheetService.openBottomSheet(template, $this.bottomsheet);
    }

    closeBottomSheet(): void {
        const $this = this;
        $this.newCollectionForm.reset();
        $this.bottomsheet.dismiss();
    }

    navChange(navItem): Promise<boolean | void> {
        const $this = this;
        this.logService.write(LogType.Page, LogContext.TopMenu, navItem);
        return this.presenceService.clear().then(_ => {
            $this.router.navigateByUrl(navItem);
        });
    }

    ngOnInit(): void {
        [
            { name: 'chords', path: 'assets/images/icons/Chord_C7.svg'},
            { name: 'scales', path: 'assets/images/icons/scale.svg'},
            { name: 'instruments', path: 'assets/images/icons/instruments.svg'},
            { name: 'progressions', path: 'assets/images/icons/progressions.svg'},
            { name: 'midi', path: 'assets/images/icons/midi.svg'},
            { name: 'composer', path: 'assets/images/icons/composer.svg'},
            { name: 'tunings', path: 'assets/images/icons/noun_Epiphone Guitar Headstock_154938.svg'}
        ].forEach(icon => {
            this.matIconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
            );
        });
        setTimeout(() => {
            this.ready = true;
        }, 2000);
    }

    ngAfterViewInit(): void {
        const $this = this;
        $this.sidenavService.setSidenav($this.sidenav);
        // $this.sidenavOpenStartSubscription = $this.sidenav.openedStart.subscribe($this.updateNav($this));
        // $this.sidenav.closedStart.subscribe(() => {
        //    for (let i = 0, l = $this.forwardFunctions.length; i < l; i++) {
        //        $this.forwardFunctions[i] = null;
        //        delete $this.forwardFunctions[i];
        //    }
        // });
        // $this.sidenavService.optionsSubject.subscribe($this.updateNav($this));
    }

    updateNav($this): () => void {
        return () => {
            const options = $this.sidenavService.getOptions();
            $this.categories = options.categories;
            $this.category = options.category;
            $this.listingCategory = options.listingCategory;
            $this.atCategory = options.atCategory;
            $this.categoryOptions = options.categoryOptions;
            $this.breadcrumbs = options.breadcrumbs;
        };
    }

    prepareRoute(outlet: RouterOutlet): boolean {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    ngOnDestroy(): void {
        const $this = this;
        $this.sidenavOpenStartSubscription.unsubscribe();
    }

    logout(): void {
        this.profileService.logOut().then(() => {
            setTimeout(() => {
                this.navChange('/');
            }, 250);
        });
    }

    toggleExpanded(): void {
        this.navitemstate = this.navitemstate === 'opened' ? 'closed' : 'opened';
    }

    toggleRightExpanded(): void {
        this.rightnavitemstate = this.rightnavitemstate === 'opened' ? 'closed' : 'opened';
    }

    toggleRightLock(): void {
        this.rightNavLocked = !this.rightNavLocked;
    }

    toggleLeftLock(): void {
        this.leftNavLocked = !this.leftNavLocked;
    }

    toggleApps(): void {
        this.appstate = this.appstate === 'opened' ? 'closed' : 'opened';
    }

    logoClicked(): void {
        window.location.href = 'https://musicianly.com';
    }

    account(): void {
        this.chargebeeService.startPortal();
    }

    gotListenNote(event): void {
        console.log('GOT LISTEN NOTE', event)
        this.soundService.soundListener.next(event);
    }

    contactUs(): void {
        const dialogRef = this.dialog.open(ContactUsDialogComponent, {
            data: new ContactUsDialogModel()
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
                if (dialogResult.annotate) {
                    this.message = dialogResult.message;
                    this.drawEnabled = true;
                    setTimeout(() => {
                        const elements = document.getElementsByClassName('canvas_whiteboard_button-draw');
                        elements[0].addEventListener('click', () => {
                            this.drawEnabled = false;
                        });
                    }, 100);
                } else {
                    this.cloudFunctionsService.sendContactUsEmail(dialogResult.message, undefined).then(() => {
                        this.snackBar.open('Support email sent');
                    });
                }
            }
        });
    }

    whiteboardClear(): void {
    }

    whiteboardSave(): void {
        const elements = document.getElementsByClassName('canvas_whiteboard_button-save') as HTMLCollectionOf<HTMLButtonElement>;
        elements[0].disabled = true;
        elements[0].innerText = ' Saving... ';
        this.downloadService.downloadPNGFromDOM(document.getElementsByClassName('app-container')[0], '', (blob) => {
            this.drawEnabled = false;
            const blobToBase64 = data => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                return new Promise(resolve => {
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                });
            };
            blobToBase64(blob).then(result => {
                this.cloudFunctionsService.sendContactUsEmail(this.message, result).then(() => {
                    this.snackBar.open('Support email sent');
                }).catch(() => {
                    this.snackBar.open('Failed to send support email');
                });
            });
        });
    }

}

