import * as d3 from 'd3';
import * as events from './events-module';

let buttons: d3.Selection<number>;
let toggle = 0;

export function init(): void {

    const radius = 10;
    const pad = 2;

    const svg = d3.select('#intervals')
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 160 40')
        .classed('svg-content', true);
    const intervals = svg
        .append('g')
        .attr('transform', 'translate(0, 0)');

    const gs = intervals.selectAll('g')
        .data([0, 1, 2, 3, 4, 5, 6], (i) => i.toString())
        .enter()
        .append('g')
        .attr('transform', (d, i) => 'translate(' + (i * (radius * 2 + pad) + pad) + ', 0)');

    buttons = gs
        .append('circle')
        .attr('cx', radius)
        .attr('cy', radius)
        .attr('r', radius)
        .attr('strokeWidth', 2)
        .attr('class', 'mode-button')
        .on('click', onClick);

    gs
        .append('text')
        .attr('x', radius)
        .attr('y', radius + 5)
        .attr('text-anchor', 'middle')
        .text((x) => x + 1);

    events.chordIntervalChange.subscribe(update);
}

function onClick(d, x: number): void {
    // tslint:disable-next-line:no-bitwise
    const updatedToggle = toggle ^ (2 ** x);
    // tslint:disable-next-line:no-bitwise
    const chordIntervals = [0, 1, 2, 3, 4, 5, 6].filter(interval => (2 ** interval & updatedToggle) === 2 ** interval);
    events.chordIntervalChange.publish({ chordIntervals });
}

export function update(event: events.ChordIntervalChangeEvent): void {
    toggle = 0;
    event.chordIntervals.forEach(x => toggle = toggle + 2 ** x);
    buttons
        .data(event.chordIntervals, (m) => m.toString())
        .attr('class', 'mode-button mode-button-selected')
        .exit()
        .attr('class', 'mode-button');
}

interface Button {
    readonly id: number;
    selected: boolean;
}
