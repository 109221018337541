import { Component, OnInit } from '@angular/core';
import {ProfileService} from '../../services/firestore/profile/profile.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-guestintro',
  templateUrl: './guestintro.component.html',
  styleUrls: ['./guestintro.component.scss']
})
export class GuestintroComponent implements OnInit {

  public userName;

  constructor(
      private profileService: ProfileService,
      private router: Router
  ) { }

  ngOnInit(): void {
    console.log('GUSETINTRO NGINIT')
    this.profileService.get().then(profile => {
      console.log('PROFILE', profile)
      this.userName = profile.displayName;
    });
  }

  logout(): void {
    this.profileService.logOut().then(() => {
      setTimeout(() => {
        this.router.navigateByUrl('/');
      }, 250);
    });
  }

}
