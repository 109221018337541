import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  public plans = [
    { title: 'Free', color: 'red', prices: [{title: '$0/month', planCode: ''}], description: 'A solid set of initial stuff to get you started.'},
    { title: 'Plus', color: 'green', prices: [{title: '$9.97/month', planCode: ''}, {title: '$99.97/year', planCode: ''}], description: 'A lot more.'},
    { title: 'Premium', color: 'blue', prices: [{title: '$12.97/month', planCode: ''}, {title: '$119.97/year', planCode: ''}], description: 'The works.'},
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  planClicked(): void {

  }

}
