import { Component, OnInit } from '@angular/core';

import {AuthService} from '../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProfileService, Role} from '../../services/firestore/profile/profile.service';
import {environment} from '../../../environments/environment';
import {RemoteConfigService} from '../../services/firestore/remoteconfig/remoteconfig.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public isLoggedIn = this.authService.isLoggedIn();

  private marketingId;
  public info = environment;

  constructor(
      private authService: AuthService,
      private router: Router,
      private route: ActivatedRoute,
      private profileService: ProfileService,
      private remoteConfigService: RemoteConfigService
  ) { }

  ngOnInit(): void {
    this.marketingId = this.route.snapshot.queryParamMap.get('id');
    this.remoteConfigService.activate().then(() => {
      AuthService.loginObservable.subscribe(value => {
        console.log('SIGNING AUTH', value)
        this.isLoggedIn = value;
        if (this.isLoggedIn) {
          this.profileService.get().then(profile => {
            console.log('profile role', profile.role)
            if (profile.role) {
              if (!this.marketingId) {
                this.router.navigateByUrl(this.authService.getInitialPath());
              } else {
                this.profileService.set({marketingId: this.marketingId}).then(_ => {
                  this.router.navigateByUrl(this.authService.getInitialPath());
                });
              }
            } else {
              this.router.navigateByUrl('guestintro');
            }
          });
        } else {
          this.authService.showSignin('#firebaseui-auth-container');
        }
      });
    });
  }

}
