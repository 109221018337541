import { Component, OnInit } from '@angular/core';
import {ParentType} from '../../services/firestore/annotations/annotations.service';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss']
})
export class AnnotationsComponent implements OnInit {

  public data = {
    modal: false, parentId: null, parentType: ParentType.None
  };

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
