import { Injectable } from '@angular/core';
import {ChargeBee} from 'chargebee-typescript';
import {ChargebeeApiService} from './chargebee.service.api';
import {ProfileService} from '../firestore/profile/profile.service';

const chargebee = new ChargeBee();

@Injectable({
  providedIn: 'root'
})

export class ChargebeeService {

  constructor(
      private chargebeeApiService: ChargebeeApiService,
      private profileService: ProfileService
  ) {
    chargebee.configure({site : 'musicianly-test',
      api_key : 'test_3NDjGSrI02wyz94XIGAypt85LIKTnLpl'}
    );
  }

  async startPortal(): Promise<any> {
    const profile = await this.profileService.get();
    return this.chargebeeApiService.setPortalSession(profile.chargebeeCustomer.id, window.location.href).then(result => {
      window.location.href = result.access_url;
    });
  }

}
