import {
    AfterViewInit,
    Directive,
    Injectable,
    Input,
    OnInit, Output,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

@Directive({
    selector: '[appIdxPortal]'
})

export class IdxPortalDirective implements OnInit {
    @Input() outlet: string;
    @Output() inlet: string = this.outlet;

    constructor(private portalService: PortalService, public templateRef: TemplateRef<any>) {}

    ngOnInit(): void {
        this.portalService.registerInlet(this);
    }

}

@Directive({
    selector: '[appIdxPortalOutlet]'
})
export class IdxTemplatePortalDirective implements OnInit, AfterViewInit {
    @Input() appPortalOutlet: string;
    @Output() outlet: string = this.appPortalOutlet;

    constructor(private portalService: PortalService, public viewContainerRef: ViewContainerRef) {}

    ngOnInit(): void {
        this.portalService.registerOutlet(this);
    }

    ngAfterViewInit(): void {
        this.portalService.initializePortal(this.appPortalOutlet);
    }

}
@Injectable({
    providedIn: 'root'
})
export class PortalService {
    outlets = new Map<string, IdxTemplatePortalDirective>();
    inlets = new Map<string, IdxPortalDirective>();

    registerOutlet(outlet: IdxTemplatePortalDirective): void {
        this.outlets[outlet.outlet] = outlet;
    }

    registerInlet(inlet: IdxPortalDirective): void {
        this.inlets[inlet.inlet] = inlet;
    }

    initializePortal(portal: string): void {
        const inlet: IdxPortalDirective = this.inlets[portal];
        const outlet: IdxTemplatePortalDirective = this.outlets[portal];
        outlet.viewContainerRef.clear();
        outlet.viewContainerRef.createEmbeddedView(inlet.templateRef);
    }
}
