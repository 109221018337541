import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnChanges {

  @Input() context;

  public htmlContent;

  constructor() { }

  ngOnInit(): void {
    this.setContent();
  }

  private setContent(): void {
    this.htmlContent = this.context;
  }

  ngOnChanges(): void {
    this.setContent();
  }

}
