import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {DataChannelName, DataService} from '../../../services/data/data.service';
import {TuningsDialogComponent, TuningsDialogModel} from '../../dialogs/tuningsdialog/tuningsdialog.component';
import {MatDialog} from '@angular/material/dialog';
import {tuningInfos} from '../../../gd/src/tuning-module';
import {instrumentNames} from '../../../services/sound/sound.service.instruments'

@Component({
  selector: 'app-charts-form',
  templateUrl: './charts-form.component.html',
  styleUrls: ['./charts-form.component.scss', '../sidenav-base/sidenav-base.component.scss']
})
export class ChartsFormComponent extends SidenavBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public rootKeyMode = false;
  public playInstruments = instrumentNames();
  private originalChords;

  constructor(
      private dialog: MatDialog,
      formBuilder: FormBuilder,
      dataService: DataService,
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscribeToChannel(DataChannelName.APP_CHART_FORM, () => {
      this.originalChords = this.data.chords;
      this.form.get('findchord').valueChanges.subscribe(value => {
        if (value) {
          this.data.chords = this.originalChords.filter(chord => chord.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
        } else {
          this.data.chords = this.originalChords;
        }
      });
    });
  }

  rootModeChanged(event): void {
    this.rootKeyMode = event.checked;
    this.form.get('chartmode').setValue(event.checked);
  }

  chordleft(event): void {
    event.stopPropagation();
    const currentIndex = this.form.get('chord').value[0];
    this.form.get('chord').setValue([currentIndex === 0 ? this.data.chords.length - 2 : currentIndex - 1]);
  }

  chordright(event): void {
    event.stopPropagation();
    const currentIndex = this.form.get('chord').value[0];
    this.form.get('chord').setValue([currentIndex >= this.data.chords.length - 1 ? 0 : currentIndex + 1]);
  }

  chooseCustomTuning(event): void {
    event.stopPropagation();
    const thisTuning = this.form.get('tuning').value;
    let tuningInfo = tuningInfos.find(info => info.tuning === thisTuning);
    if (!tuningInfo) {
      tuningInfo = {tuning: thisTuning, strings: this.data.strings, description: this.form.get('tuning').value.description, parentIndex: 0, index: -1, dots: undefined};
    }
    const dialogRef = this.dialog.open(TuningsDialogComponent, {
      data: new TuningsDialogModel(`${thisTuning.tuning} ${thisTuning.description}`, tuningInfo, 'selectonly')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const newTuning = dialogResult;
        console.log('DIALOGRESULT', dialogResult)
        if (this.data.tunings[0].description.indexOf('Custom') >= 0) {
          this.data.tunings[0] = newTuning;
        } else {
          if (!tuningInfos.find(info => info.tuning === newTuning.tuning)) {
            newTuning.description = 'Custom Tuning';
          }
          this.data.tunings.unshift(newTuning);
        }
        this.form.get('tuning').setValue(newTuning);
      }
    });
  }

}
