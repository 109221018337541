import * as d3 from 'd3';
import * as music from './music-module';
import * as events from './events-module';

export const guitarDots: Array<[number, number]> = [
    [3, 0], // [fret, position]
    [5, 0],
    [7, 0],
    [9, 0],
    [12, -1],
    [12, 1],
    [15, 0]
];

// Viola/violin for beginners.
const violaDots: Array<[number, number]> = [
    [2, 0],   // 1st finger
    [4, 0],   // 2nd finger
    [5, 0],   // 3rd finger
    [7, 0],   // 4th finger
    [12, -1],
    [12, 1]
];

export interface TuningInfo {
    readonly index: number;
    tuning: string;
    readonly dots: Array<[number, number]>;
    readonly description: string;
    strings: Array<string>;
    readonly parentIndex: number;
    readonly tuningLimit?: TuningLimit;
    bestCount?: number;
    tableName?: string;
}

export interface Tuning {
    readonly index: number;
    readonly tuning: string;
    readonly dots: Array<[number, number]>;
    readonly description: string;
    readonly notes: Array<number>;
}

const preferredTunings = [
    0, 7, 8, 13
];

export const bestCombinations = [
    { tuning: 'DADGAD',
      chordType: 'major',
      bestCombinations: [
          { key: 'D', mode: 'Ionian'},
          { key: 'A', mode: 'Dorian'},
          { key: 'B♭', mode: 'Phrygian' },
          { key: 'G', mode: 'Mixolydian' },
          { key: 'F', mode: 'Aeolian' },
          { key: 'C', mode: 'Aeolian' }
      ]
    }
];

export interface Instrument {
    readonly index: number;
    readonly stringCount: number;
    readonly description: string;
    readonly tunings: Array<number>;
}

export const instruments: Array<Instrument> = [
    { index: 0, stringCount: 6, description: '4 String', tunings: [14, 15, 21, 22] },
    { index: 0, stringCount: 6, description: '5 String', tunings: [16, 17, 20] },
    { index: 0, stringCount: 6, description: '6 String', tunings: [0, 1, 4, 5, 7, 10, 12, 13, 18] },
    { index: 0, stringCount: 6, description: '7 String', tunings: [7, 19] },
    { index: 0, stringCount: 6, description: '8 String', tunings: [8] },
];

export const relatedTunings: Array<Array<number>> = [
    [0, 7, 8],
    [14, 16, 17],
    [20, 21]
];

export interface TuningLimit {
    readonly downSemitones: number;
    readonly upSemitones: number;
    noUpStrings: Array<number>;
}

const standardLimit: TuningLimit = {
    downSemitones: 5,
    upSemitones: 2,
    noUpStrings: [3],
}

export const tuningInfos: Array<TuningInfo> = [

    { index: 0, tuning: 'EADGBE', strings: ['E2', 'A2', 'D3', 'G3', 'B3', 'E4'], dots: guitarDots, description: 'Guitar Standard', parentIndex: -1, tuningLimit: standardLimit, tableName: 'best6string' },
    { index: 1, tuning: 'EADGCF', strings: ['E2', 'A2', 'D3', 'G3', 'C4', 'F4'], dots: guitarDots, description: 'All Fourths', parentIndex: 0, tableName: 'best6string' },
    { index: 4, tuning: 'DADGBE', strings: ['D2', 'A2', 'D3', 'G3', 'B3', 'E4'], dots: guitarDots, description: 'Guitar Drop D', parentIndex: 0, tableName: 'best6string' },
    { index: 5, tuning: 'DADGAD', strings: ['D2', 'A2', 'D3', 'G3', 'A3', 'D4'], dots: guitarDots, description: 'Celtic', parentIndex: 0, tableName: 'best6string' },
    { index: 10, tuning: 'DGDGBD', strings: ['D2', 'G2', 'D3', 'G3', 'B3', 'D4'], dots: guitarDots, description: 'Guitar Open G', parentIndex: 0, tableName: 'best6string' },
    { index: 12, tuning: 'E♭A♭D♭G♭B♭E♭', strings: ['E♭2', 'A♭2', 'D♭3', 'G♭3', 'B♭3', 'E♭4'], dots: guitarDots, description: 'Guitar E♭ (Hendrix)', parentIndex: 0, tableName: 'best6string' },
    { index: 7, tuning: 'BEADGBE', strings: ['B1', 'E2', 'A2', 'D3', 'G3', 'B3', 'E4'], dots: guitarDots, description: 'Guitar 7 string', parentIndex: -1, tuningLimit: standardLimit, tableName: 'best7string' },
    { index: 8, tuning: 'F♯BEADGBE', strings: ['F♯3', 'B3', 'E3', 'A4', 'D4', 'G4', 'B5', 'E5'], dots: guitarDots, description: 'Guitar 8 string', parentIndex: -1, tuningLimit: standardLimit, tableName: 'best8string' },
    { index: 13, tuning: 'BEADF♯B', strings: ['B1', 'E2', 'A2', 'D3', 'F♯3', 'B3'], dots: guitarDots, description: 'Guitar Baritone', parentIndex: -1, tuningLimit: standardLimit, tableName: 'bestbaritone' },

    { index: 14, tuning: 'EADG', strings: ['E1', 'A1', 'D2', 'G2'], dots: guitarDots, description: 'Bass Standard', parentIndex: -1 },
    { index: 15, tuning: 'DADG', strings: ['D1', 'A1', 'D2', 'G2'], dots: guitarDots, description: 'Bass Drop D', parentIndex: 14 },
    { index: 16, tuning: 'EADGC', strings: ['E1', 'A1', 'D2', 'G2', 'C3'], dots: guitarDots, description: 'Bass 5 Strings Standard High', parentIndex: -1 },
    { index: 17, tuning: 'BEADG', strings: ['B0', 'E1', 'A1', 'D2', 'G2'], dots: guitarDots, description: 'Bass 5 Strings Standard Low', parentIndex: -1 },
    { index: 18, tuning: 'BEADGC', strings: ['B0', 'E1', 'A1', 'D2', 'G2', 'C3'], dots: guitarDots, description: 'Bass 6 Strings Standard', parentIndex: -1 },
    { index: 19, tuning: 'BEADGCF', strings: ['B0', 'E1', 'A1', 'D2', 'G2', 'C3', 'F3'], dots: guitarDots, description: 'Bass 7 Strings Standard', parentIndex: -1 },

    { index: 20, tuning: 'GDGBD', strings: ['G4', 'D3', 'G3', 'B3', 'D4'], dots: guitarDots, description: 'Banjo 5 String Bluegrass', parentIndex: -1 },
    { index: 21, tuning: 'DGBD', strings: ['D4', 'G4', 'B4', 'D5'], dots: guitarDots, description: 'Cavaquinho', parentIndex: -1},
    { index: 22, tuning: 'GCEA', strings: ['G4', 'C4', 'E4', 'A4'], dots: guitarDots, description: 'Ukulele C', parentIndex: -1 },
//    { index: 23, tuning: 'CGDA', dots: violaDots, description: 'Cello' },
//    { index: 24, tuning: 'GDAE', dots: violaDots, description: 'Violin' },
//    { index: 25, tuning: 'CGDA', dots: violaDots, description: 'Viola' },
];

export let tunings: Array<Tuning> = [];

export function parseTuning(tuning: string): Array<number> {
    const tokens: Array<string> = [];
    const result: Array<number> = [];

    let tokenIndex = 0;
    let lastWasChar = false;

    for (let i = 0; i < tuning.length; i++) {
        const noteChar = tuning.charAt(i);
        if ('ABCDEFG'.indexOf(noteChar) >= 0) {
            tokens[tokenIndex] = noteChar;
            tokenIndex++;
            lastWasChar = true;
        }
        else if ('♯♭'.indexOf(noteChar) >= 0 && lastWasChar) {
            tokens[tokenIndex - 1] = tokens[tokenIndex - 1] + noteChar;
            lastWasChar = false;
        }
        else {
            throw new Error('Invalid tuning char');
        }
    }

    for (const token of tokens){
        const noteName = music.noteNames.filter(x => x.name === token);
        if (noteName.length !== 1) {
            throw new Error('Invalid token');
        }
        result.push(noteName[0].index);
    }

    return result;
}

export function init(): void {

    let index = 0;
    for (const info of tuningInfos) {
        const tuning: Tuning = {
            index,
            tuning: info.tuning,
            dots: info.dots,
            description: info.description,
            notes: parseTuning(info.tuning)
        };
        tunings.push(tuning);
        index++;
    }

    d3.select('#tuning-dropdown')
        .selectAll('div')
        .data(tunings)
        .enter()
        .append('div')
        .attr('class', 'dropdown-content-item')
        .on('click', (d, x) => raiseTuningChangedEvent(x))
        .text(x => x.tuning + '   ' + x.description);

    raiseTuningChangedEvent(tunings[0]);
}

function raiseTuningChangedEvent(tuning: Tuning): void{
    events.tuningChange.publish({
        index: tuning.index
    });
}

