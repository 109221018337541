import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SidenavBaseComponent} from '../sidenav-base/sidenav-base.component';
import {FormBuilder} from '@angular/forms';
import {DataChannelName, DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-progression-form',
  templateUrl: './progression-form.component.html',
  styleUrls: ['./progression-form.component.scss']
})
export class ProgressionFormComponent extends SidenavBaseComponent implements OnInit, AfterViewInit {

  constructor(
      formBuilder: FormBuilder,
      dataService: DataService,
  ) {
    super(formBuilder, dataService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.subscribeToChannel(DataChannelName.APP_PROGRESSION_FORM);
  }

}
