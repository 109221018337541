import {Injectable} from '@angular/core';
import firebase from 'firebase';
import {SpinnerService} from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})

export class ChargebeeApiService {

    constructor(
        private spinnerService: SpinnerService
    ) {}

    setPortalSession(customerId, redirectUrl): Promise<any> {
        this.spinnerService.show();
        return firebase.functions().httpsCallable('chargebee-portalSession')({ customerId, redirectUrl}).then(result => {
            this.spinnerService.hide();
            return result.data;
        });
    }

}

